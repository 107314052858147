import { observer } from 'mobx-react';
import { useStore } from 'app/context';
import { AkumaPrimeSchema, AgtAkumaPrimeKwVM, AkumaPrimeType } from 'app/stores/ui/agt.akumaprime.ui.store';
import { AkumaPrimeHorizontalBarChart } from './AkumaPrimeBarChart';
import { useEffect, useState } from 'react';
import { AkumaPrimeListeAgt } from './AkumaPrimeListe';
import { runInAction } from 'mobx';
import { SelectInput } from 'app/components/common/Select';
import { CheckboxControl } from 'app/components/common/CheckboxControl';
import { AkumaPrimeSankeyChart } from './AkumaPrimeSankeyChart';

export const AkumaPrimeOverview = observer(() => {
	const { berichteUiiStore } = useStore();

	return (
		<>
			{!berichteUiiStore.selectedAgt && <AkumaPrimeBnrOverview />}
			{berichteUiiStore.selectedAgt && <AkumaPrimeAgtOverview />}
		</>
	);
});

export const AkumaPrimeAgtOverview = observer(() => {
	const { agtAkumaPrimeUiStore, berichteUiiStore } = useStore();

	const schemaEinordnung = AkumaPrimeSchema.filter((s) => s.type === AkumaPrimeType.einordnung);
	const schemaTerminierung = AkumaPrimeSchema.filter((s) => s.type === AkumaPrimeType.terminierung);

	const [agtItem, setAgtItem] = useState<AgtAkumaPrimeKwVM>();

	useEffect(() => {
		agtAkumaPrimeUiStore.load().then((res) => {
			let items = agtAkumaPrimeUiStore.currentKWItems;

			if (berichteUiiStore.selectedAgt) {
				const agtId = berichteUiiStore.selectedAgt.agtId;
				setAgtItem(items.find((d) => d.agtId === agtId));
			} else {
				setAgtItem(undefined);
			}
		});
	}, [agtAkumaPrimeUiStore, berichteUiiStore.selectedAgt, berichteUiiStore.menu]);

	return (
		<>
			<AkumaPrimeOptions />
			<div className="table-container">
				{!agtItem && (
					<>
						<div className="pad-1rem content">
							<h3 className="title is-5">Keine Daten</h3>
							<p>Keine AKUMA Prime teilnahme.</p>
						</div>
					</>
				)}

				{agtItem && (
					<div className="pad-top-1rem pad-bottom-3rem">
						<>
							<div className="pad-1rem border-bottom">
								<h2 className="title is-3">Einordnung</h2>
								<AkumaPrimeSankeyChart type={AkumaPrimeType.einordnung} />
							</div>
							<div className="pad-bottom-1rem">
								<AkumaPrimeListeAgt
									schema={schemaEinordnung}
									quoteProps={'einordnung.quote'}
									data={[agtItem]}
									primeType={AkumaPrimeType.einordnung}
									selectedAgt={berichteUiiStore.selectedAgt}
									showKw={false}
								/>
							</div>
						</>

						<>
							<div className="pad-1rem border-bottom">
								<h2 className="title is-3">Terminierung</h2>
								<AkumaPrimeSankeyChart type={AkumaPrimeType.terminierung} />
							</div>
							<div className="pad-bottom-1rem">
								<AkumaPrimeListeAgt
									schema={schemaTerminierung}
									quoteProps={'einordnung.quote'}
									data={[agtItem]}
									primeType={AkumaPrimeType.terminierung}
									selectedAgt={berichteUiiStore.selectedAgt}
									showKw={false}
								/>
							</div>
						</>
					</div>
				)}
			</div>
		</>
	);
});

export const AkumaPrimeOptions = observer(() => {
	const { agtAkumaPrimeUiStore, session } = useStore();

	const onMinChange = (name: string, opt: any) => {
		runInAction(() => {
			agtAkumaPrimeUiStore.minKwCurrent = parseInt(opt.value);
		});
	};

	const onMaxChange = (name: string, opt: any) => {
		runInAction(() => {
			agtAkumaPrimeUiStore.maxKwCurrent = parseInt(opt.value);
		});
	};

	const toggleShowGraphs = () => {
		runInAction(() => {
			agtAkumaPrimeUiStore.showGraphs = !agtAkumaPrimeUiStore.showGraphs;
		});
	};

	return (
		<>
			<div className="grid is-col-auto align-center justify-start pad-1rem zeitraum-select">
				<strong>Zeitraum: </strong>
				<div>
					<SelectInput name="kwMin" emptyText="Start KW" value={agtAkumaPrimeUiStore.minKw} onChange={onMinChange} opts={agtAkumaPrimeUiStore.kwsOpts} isClearable={false} />
				</div>
				<span>bis</span>
				<div>
					<SelectInput name="kwMax" emptyText="End KW" value={agtAkumaPrimeUiStore.maxKw} onChange={onMaxChange} opts={agtAkumaPrimeUiStore.kwsOpts} isClearable={false} />
				</div>
				{session.isAdmin && <CheckboxControl label="Balken anzeigen" name="tracking" value={agtAkumaPrimeUiStore.showGraphs} onChange={toggleShowGraphs} />}
			</div>
		</>
	);
});

export const AkumaPrimeBnrOverview = observer(() => {
	const { agtAkumaPrimeUiStore, bnrAkumaPrimeUiStore, agenturListUiStore } = useStore();
	const [data, setData] = useState<AgtAkumaPrimeKwVM[]>([]);

	useEffect(() => {
		agtAkumaPrimeUiStore.load().then((res) => {
			let items = agtAkumaPrimeUiStore.currentKWItems;
			items = agenturListUiStore.applyFilterOnAgt(items) as AgtAkumaPrimeKwVM[];
			runInAction(() => {
				bnrAkumaPrimeUiStore.items = items;
				bnrAkumaPrimeUiStore.calc();
			});
			setData(items);
		});
	}, [agtAkumaPrimeUiStore, agtAkumaPrimeUiStore.currentKWItems, agenturListUiStore.filterHasChanged, agenturListUiStore, bnrAkumaPrimeUiStore]);

	return (
		<>
			<AkumaPrimeOptions />
			{data.length === 0 ? (
				<>
					<div className="pad-1rem content">
						<h3 className="title is-5">Keine Daten</h3>
						<p>Keine AKUMA Prime teilnahme.</p>
					</div>
				</>
			) : (
				<div className="table-container">
					<div className="pad-1rem">
						<h2 className="title is-3">Einordnung</h2>
						<AkumaPrimeSankeyChart type={AkumaPrimeType.einordnung} />
					</div>
					{agtAkumaPrimeUiStore.showGraphs && (
						<div className="pad-1rem border-bottom">
							<AkumaPrimeHorizontalBarChart data={data} primeType={AkumaPrimeType.einordnung} schema={AkumaPrimeSchema.filter((s) => s.type === AkumaPrimeType.einordnung)} />
						</div>
					)}

					<div className="pad-1rem page-break-avoid">
						<h2 className="title is-3">Terminierung</h2>
						<AkumaPrimeSankeyChart type={AkumaPrimeType.terminierung} />
					</div>
					{agtAkumaPrimeUiStore.showGraphs && (
						<div className="pad-1rem border-bottom">
							<AkumaPrimeHorizontalBarChart data={data} primeType={AkumaPrimeType.terminierung} schema={AkumaPrimeSchema.filter((s) => s.type === AkumaPrimeType.terminierung)} />
						</div>
					)}
				</div>
			)}
		</>
	);
});

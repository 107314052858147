import { IAdminUploadOptions } from 'app/components/admin/AdminFileUpload';
import { computed } from 'mobx';
import { BaseEntityStore } from './core/base.entity.store';
import FileSaver from 'file-saver';
import moment from 'moment';
import { extractDateFromFileName } from './ui/admin.file.upload.ui.store';

export enum ImportDataType {
	aup = 'aup',
	aupvorjahr = 'aupvorjahr',
	demodata = 'demodata',
	digitialisierungsfaktor = 'digitialisierungsfaktor',
	da = 'da',
	nps = 'nps',
	aladin = 'aladin',
	aladin2 = 'aladin2',
	leadnow = 'leadnow',
	googleprofile = 'googleprofile',
	agenturcluster = 'agenturcluster',
	digitalreport = 'digitalreport',
	crawler = 'crawler',
	vblzugang = 'vblzugang',
	akumaprime = 'akumaprime',
	akumaplaner = 'akumaplaner',
	multisagentur = 'multisagentur',
	multisgp = 'multisgp',
	facebook = 'facebook',
	instagram = 'instagram',
	amisnow = 'amisnow',
	kvm = 'kvm',
	sts = 'sts',
	sts2024 = 'sts2024',
}

export enum ImportJobStatus {
	done = 'done',
	inProgress = 'inProgress',
	ignore = 'ignore',
	new = 'new',
}

export class AupFile {
	constructor(csvString: string) {
		const s = csvString.split(';');
		this.fileName = s[0];
		this.fileSize = Number(s[1]);
		this.fileDate = new Date(Number(s[2]));
		this.datenStand = extractDateFromFileName(this.fileName);
	}
	fileName: string;
	fileSize: number;
	fileDate: Date;
	datenStand?;
	isMostCurrent: boolean = false;
	// datenStand?: DatenStandModel
}

class ImportFileResult {
	constructor(key: string, resultString: string) {
		this.key = key;
		if (key === 'successCount') {
			// debugger;
		}
		this.result = resultString;
		if (resultString && resultString.length > 0) {
			if (resultString.indexOf('{') === 0) {
				try {
					const r = JSON.parse(resultString);
					this.result = r.result;
					this.duration = r.duration;
					this._hasErrors = r.hasErrors;
				} catch (ex) {
					console.warn("could parse json for import files result: " + ex);
					console.warn("content: " + resultString);
				}
			}
		}
	}
	key: string;
	result: string = '';
	duration: number = -1;
	_hasErrors: boolean = false;

	@computed
	get hasErrors() {
		if (this._hasErrors) {
			return true;
		}
		if (this.result && typeof this.result === 'string' && this.result.toLowerCase().indexOf('exception') > 0) {
			return true;
		}
		return false;
	}
}

export class ImportJobModel {
	constructor(opts: any) {
		this.created = opts.created;
		this.dataType = opts.dataType;
		this.duration = opts.duration;
		this.id = opts.id;
		this.mailSent = opts.mailSent;
		this.message = opts.message;
		this.status = opts.status;
		if (opts.meta) {
			this.meta = JSON.parse(opts.meta);
		}
		if (opts.message) {

			this.message = JSON.parse(opts.message);
			if (this.message.error && this.message.error.length) {
				// console.log(this.message.error);
				this.hasError2 = true;
			}

			if (this.dataType === 'aup') {
				const keys = Object.keys(this.message);
				for (const key of keys) {
					const r = this.message[key];
					const fileResult = new ImportFileResult(key, r);
					this.results.push(fileResult);
				}
			}
			if (this.dataType === 'aladin') {
				const keys = Object.keys(this.message);
				for (const key of keys) {
					const r = this.message[key];
					const fileResult = new ImportFileResult(key, r);
					this.results.push(fileResult);
				}
			}
			if (this.dataType !== 'vblzugang' && this.dataType !== 'nps') {
			}
		}
	}
	created: Date;
	dataType: ImportDataType;
	duration: number;
	id: string;
	mailSent: boolean;
	status: ImportJobStatus;
	message: any;
	// hasError: boolean = false;
	meta: any;
	results: ImportFileResult[] = [];
	@computed
	get hasError() {
		return this.results.filter((r) => r.hasErrors).length > 0;
	}

	hasError2 = false;

	@computed
	get needsBM() {
		const bmDataTypes = [ImportDataType.googleprofile, ImportDataType.multisgp, ImportDataType.facebook, ImportDataType.instagram];
		if (bmDataTypes.includes(this.dataType)) {
			return true;
		}
		return false;
	}
	getBmFromFileName(d: Date) {
		return moment(d).format('YYYYMM');

	}


	serialize() {
		return {
			dataPath: this.dataType,
			dataType: this.dataType,
			dataMeta: JSON.stringify(this.meta),
			created: this.created,
		};
	}
}

export class AdminImportsStore extends BaseEntityStore<ImportJobModel> {
	apiPath = 'api/import-data';
	idProp: string = 'id';
	createInstances(opts: any): ImportJobModel {
		return new ImportJobModel(opts);
	}

	async triggerImportJob(job: ImportJobModel) {
		return this.save(job);
	}

	async rerun(jobId: string) {
		await this.callRefreshTokenIfNecessary();
		const header = this.getAuthHeader();
		await this.axios({
			url: 'api/import-data/rerun/' + jobId,
			method: 'GET',
			headers: header.headers,
		});
	}

	async deleteJob(jobId: string) {
		await this.callRefreshTokenIfNecessary();
		const header = this.getAuthHeader();
		await this.axios({
			url: 'api/import-data/deleteJob/' + jobId,
			method: 'GET',
			headers: header.headers,
		});
	}

	async getAupKwFiles(kw: string) {
		await this.callRefreshTokenIfNecessary();
		const header = this.getAuthHeader();
		const res = await this.axios({
			url: 'api/import-data/documents/aup/' + kw,
			method: 'GET',
			headers: header.headers,
		});

		return res.data.map((f: string) => new AupFile(f));
	}


	async deleteUploadFile(file: string) {
		await this.callRefreshTokenIfNecessary();
		const header = this.getAuthHeader();
		const res = await this.axios({
			url: 'api/import-data/deleteupload/' + file,
			method: 'DELETE',
			headers: header.headers,
		});

	}


	async triggerFixDurcheinander() {
		await this.callRefreshTokenIfNecessary();
		const header = this.getAuthHeader();
		await this.axios({
			url: 'api/import-data/import/fixVtnrDurcheinander',
			method: 'POST',
			headers: header.headers,
			data: {},
		});
	}

	async getAupVorjahrFiles(vorjahr: string) {
		await this.callRefreshTokenIfNecessary();
		const header = this.getAuthHeader();
		const res = await this.axios({
			url: 'api/import-data/documents/aupvorjahr/' + vorjahr,
			method: 'GET',
			headers: header.headers,
		});

		return res.data.map((f: string) => new AupFile(f));
	}

	async getAmisNowFiles() {
		await this.callRefreshTokenIfNecessary();
		const header = this.getAuthHeader();
		const res = await this.axios({
			url: 'api/import-data/documents/' + ImportDataType.amisnow,
			method: 'GET',
			headers: header.headers,
		});

		// only parse actual aladin files
		const filtered = res.data.filter((f: string) => f.toLowerCase().includes('amis'));
		return filtered.map((f: string) => new AupFile(f));
	}
	async getAladin2Files() {
		await this.callRefreshTokenIfNecessary();
		const header = this.getAuthHeader();
		const res = await this.axios({
			url: 'api/import-data/documents/' + ImportDataType.aladin2,
			method: 'GET',
			headers: header.headers,
		});

		// only parse actual aladin files
		const filtered = res.data.filter((f: string) => f.toLowerCase().includes('aladin'));
		return filtered.map((f: string) => new AupFile(f));
	}

	async getAladinFiles() {
		await this.callRefreshTokenIfNecessary();
		const header = this.getAuthHeader();
		const res = await this.axios({
			url: 'api/import-data/documents/' + ImportDataType.aladin,
			method: 'GET',
			headers: header.headers,
		});

		// only parse actual aladin files
		const filtered = res.data.filter((f: string) => f.toLowerCase().includes('aladin'));
		return filtered.map((f: string) => new AupFile(f));
	}

	async getLeadNowFiles() {
		await this.callRefreshTokenIfNecessary();
		const header = this.getAuthHeader();
		const res = await this.axios({
			url: 'api/import-data/documents/' + ImportDataType.leadnow,
			method: 'GET',
			headers: header.headers,
		});

		// only parse actual aladin files
		const filtered = res.data.filter((f: string) => f.toLowerCase().includes('lead'));
		return filtered.map((f: string) => new AupFile(f));
	}
	async getGoogleProfileFiles() {
		await this.callRefreshTokenIfNecessary();
		const header = this.getAuthHeader();
		const res = await this.axios({
			url: 'api/import-data/documents/' + ImportDataType.googleprofile,
			method: 'GET',
			headers: header.headers,
		});

		// only parse actual aladin files
		const filtered = res.data.filter((f: string) => f.toLowerCase().includes('goog') || f.toLowerCase().includes('gup'));
		return filtered.map((f: string) => new AupFile(f));
	}
	async getFacebookFiles() {
		await this.callRefreshTokenIfNecessary();
		const header = this.getAuthHeader();
		const res = await this.axios({
			url: 'api/import-data/documents/' + ImportDataType.facebook,
			method: 'GET',
			headers: header.headers,
		});
		const filtered = res.data.filter((f: string) => f.toLowerCase().includes('face'));
		return filtered.map((f: string) => new AupFile(f));
	}

	async getInstagramFiles() {
		await this.callRefreshTokenIfNecessary();
		const header = this.getAuthHeader();
		const res = await this.axios({
			url: 'api/import-data/documents/' + ImportDataType.instagram,
			method: 'GET',
			headers: header.headers,
		});
		const filtered = res.data.filter((f: string) => f.toLowerCase().includes('inst'));
		return filtered.map((f: string) => new AupFile(f));
	}

	async getMultisAgenturFiles() {
		await this.callRefreshTokenIfNecessary();
		const header = this.getAuthHeader();
		const res = await this.axios({
			url: 'api/import-data/documents/' + ImportDataType.multisagentur,
			method: 'GET',
			headers: header.headers,
		});

		const filtered = res.data;
		return filtered.map((f: string) => new AupFile(f));
	}

	async getKvmFiles() {
		await this.callRefreshTokenIfNecessary();
		const header = this.getAuthHeader();
		const res = await this.axios({
			url: 'api/import-data/documents/' + ImportDataType.kvm,
			method: 'GET',
			headers: header.headers,
		});

		const filtered = res.data;
		return filtered.map((f: string) => new AupFile(f));
	}

	async getStsFiles() {
		await this.callRefreshTokenIfNecessary();
		const header = this.getAuthHeader();
		const res = await this.axios({
			url: 'api/import-data/documents/' + ImportDataType.sts,
			method: 'GET',
			headers: header.headers,
		});

		const filtered = res.data;
		return filtered.map((f: string) => new AupFile(f));
	}

	async getSts2024Files() {
		await this.callRefreshTokenIfNecessary();
		const header = this.getAuthHeader();
		const res = await this.axios({
			url: 'api/import-data/documents/' + ImportDataType.sts2024,
			method: 'GET',
			headers: header.headers,
		});

		const filtered = res.data;
		return filtered.map((f: string) => new AupFile(f));
	}


	async getMultisGPFiles() {
		await this.callRefreshTokenIfNecessary();
		const header = this.getAuthHeader();
		const res = await this.axios({
			url: 'api/import-data/documents/' + ImportDataType.multisgp,
			method: 'GET',
			headers: header.headers,
		});

		const filtered = res.data;
		return filtered.map((f: string) => new AupFile(f));
	}

	async getAgenturClusterFiles() {
		await this.callRefreshTokenIfNecessary();
		const header = this.getAuthHeader();
		const res = await this.axios({
			url: 'api/import-data/documents/' + ImportDataType.agenturcluster,
			method: 'GET',
			headers: header.headers,
		});

		return res.data.map((f: string) => new AupFile(f));
	}

	async getDigitalreportFiles() {
		await this.callRefreshTokenIfNecessary();
		const header = this.getAuthHeader();
		const res = await this.axios({
			url: 'api/import-data/documents/' + ImportDataType.digitalreport,
			method: 'GET',
			headers: header.headers,
		});

		// only parse actual aladin files
		const filtered = res.data;
		return filtered.map((f: string) => new AupFile(f));
	}

	async getAkumaPrimeFiles() {
		await this.callRefreshTokenIfNecessary();
		const header = this.getAuthHeader();
		const res = await this.axios({
			url: 'api/import-data/documents/' + ImportDataType.akumaprime,
			method: 'GET',
			headers: header.headers,
		});

		return res.data.map((f: string) => new AupFile(f));
	}

	async getAkumaPlanerFiles() {
		await this.callRefreshTokenIfNecessary();
		const header = this.getAuthHeader();
		const res = await this.axios({
			url: 'api/import-data/documents/' + ImportDataType.akumaplaner,
			method: 'GET',
			headers: header.headers,
		});

		return res.data.map((f: string) => new AupFile(f));
	}

	async uploadFile(file: any, opts: IAdminUploadOptions, onProgres: (p: number) => void) {
		let url = 'api/uploads/import/' + opts.dataType;
		await this.callRefreshTokenIfNecessary();
		if (opts.kw) {
			url += `?kw=${opts.kw}`;
		}

		let data = new FormData();
		if (opts.fileName) {
			const renamedFile = new File([file], opts.fileName, { type: file.type });
			data.append('file', renamedFile);
		} else {
			data.append('file', file);
		}

		const header = this.getAuthHeader();
		header.headers['Content-Type'] = 'multipart/form-data';

		return await this.axios({
			onUploadProgress: (evt: any) => {
				onProgres(evt.loaded);
			},
			headers: header.headers,
			url,
			method: 'POST',
			data: data,
		});
	}


	async renameFile(dataType: string, orgFilename: string, newFilename: string) {
		let url = 'api/uploads/rename/' + dataType;
		await this.callRefreshTokenIfNecessary();

		let data = new FormData();
		data.set('orgFilename', orgFilename);
		data.set('newFilename', newFilename);

		const header = this.getAuthHeader();
		header.headers['Content-Type'] = 'multipart/form-data';

		return await this.axios({
			headers: header.headers,
			url,
			method: 'POST',
			data: data,
		});
	}


	async crawlOne(bensl: string, kw: string, forceSend: string) {
		await this.callRefreshTokenIfNecessary();
		const header = this.getAuthHeader();
		await this.axios({
			url: 'api/admin/crawl/' + bensl + '/' + kw + '?forceSend=' + forceSend,
			method: 'GET',
			headers: header.headers,
		});
	}

	async downloadJobFile(jobType: string, file: string) {
		const url = 'api/import-data/download/' + jobType + '/' + file;
		await this.callRefreshTokenIfNecessary();
		const header = this.getAuthHeader();
		const res = await this.axios({
			headers: header.headers,
			url,
			method: 'GET',
			responseType: 'blob', // Important
		});
		const fileName = file;
		var blob = new Blob([res.data], { type: res.data.type });
		FileSaver.saveAs(blob, fileName);
	}
}

import { Einheit } from 'app/models/core/einheit.model';
import { BnrGpPosModel } from '../bnr.gp.pos.model';
import { PosUnDef } from '../gp';
const posDef: BnrGpPosModel[] = [];




posDef.push(
	new BnrGpPosModel({
		year: 2025,
		posId: PosUnDef,
		group: 'ausMaxi',
		posText: 'Hardwareadministration im Verantwortungsbereich',
		planKey: 'steigerung_kundenvertrauen_i',
		einheit: Einheit.quali,
		posDesc: `Nachhaltiges und wirtschaftliches Handeln bei administrativen Tätigkeiten im Bereich Hardware
im Verantwortungsbereich. Planung und Durch-
führung von praktikablen Arbeitsmustern,
insbesondere bei der Bestellung und dem
Abbau von Hardware.`,
	}),
);

posDef.push(
	new BnrGpPosModel({
		year: 2025,
		posId: PosUnDef,
		group: 'ausMaxi',
		posText: 'Vertreteraktivierung in den Wachstumsdimensionen',
		planKey: 'vertreteraktivierung',
		einheit: Einheit.quali,
		posDesc: `Multiplikator:in und Ansprechpartner:in für Führungskräfte innerhalb der VD für den
strategischen Einsatz aller digitalen und analogen
Instrumente der Wachstumsdimensionen.
Verantwortung für die Nutzung und Etablierung
der Instrumente durch gezielte Einsteuerung von
Neuerungen und Best Practices.
Austausch über laufendes Feedback an
betreuenden Fachabteilungen.`,
	}),
);


posDef.push(
	new BnrGpPosModel({
		year: 2025,
		posId: PosUnDef,
		group: 'ausMaxi',
		posText: 'Agenturprozesse und Agenturadministratio',
		planKey: 'agtprozesse',
		einheit: Einheit.quali,
		posDesc: `Multiplikator:in und Ansprechpartner:in im Verant- wortungsbereich für die Gestaltung effizienter
Agenturabläufe und -prozesse, Verantwortung
für die Etablierung von neuen Anwendungen
durch gezielte Einsteuerung von Neuerungen
und Best-Practices.`,
	}),
);



export const EdpPositionen = posDef;

import React from 'react';
import { DocumentSharedState, DocumentVM } from 'app/stores/ui/document.ui.store';
import { observer } from 'mobx-react';
import { SharedUser } from '../shared/SharedUser';

interface IDocumentShareState {
	doc: DocumentVM;
}
export const DocumentShareState = observer((props: IDocumentShareState) => {
	const { doc } = props;

	if (doc.sharedState === DocumentSharedState.sharedByMe) {
		return (
			<span className="tag is-small is-purple">
				MIT{' '}
				{doc.doc.sharedWithCount > 3 ? (
					<>{doc.doc.sharedWithCount}</>
				) : (
					<span className="comma-list">

						{doc.sharedWith.map((s) => (
							<span className="comma-list-item" key={s.bnrId}>
								<SharedUser bnrId={s.bnrId} showName={false} />
							</span>
						))}

					</span>
				)}
			</span>
		);
	}

	if (doc.sharedState === DocumentSharedState.sharedForMe) {
		return (
			<span className="tag is-small is-blue">
				VON
				<SharedUser bnrId={doc.doc.bnrId} showName={false} />
			</span>
		);
	}

	return <span className="tag is-small is-inverted">PRIVAT</span>;
});

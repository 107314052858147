import { AladinModel } from 'app/stores/aladin.model';
import { GebietModel } from 'app/stores/gebiete.store';
import { formatAblauf, formatGp } from 'app/utils';
import { action, computed, observable } from 'mobx';
import moment from 'moment';
import { UserModel } from './core/user.model';
import { ProvSysModel, ProvSysModels } from './gp/agt.provsys.def';
import { CustomExpertiseModel } from 'app/stores/kv.agt.custom.expertise.store';
import { VertkatModel, Vertkats } from './core/vertkat.model';
import { allGps } from './gp/gp';
import { SteartModel } from './core/steart.model';

const getDate = (val: any) => {
	if (val) {
		return new Date(val);
	}
};

export class AgtExpertisenModel {
	constructor(opts: any) {
		this.id = opts.id;
		this.type = opts.type;
		this.text = opts.text;
		this.ablauf = opts.ablauf;
	}

	id: string;
	type: 'aladin' | 'custom';

	text: string;
	ablauf?: Date;

	@computed
	get endAblauf() {
		return formatAblauf(this.ablauf);
	}

	@computed
	get ablaufInDays() {
		if (!this.ablauf) {
			return 999;
		}
		let mom = moment(this.ablauf);
		let today = moment();
		return mom.diff(today, 'days');
	}

	@computed
	get ablaufIn3Months() {
		return this.ablaufInDays > 84 && this.ablaufInDays < 96;
	}

	@computed
	get ablaufIn2Months() {
		return this.ablaufInDays > 54 && this.ablaufInDays < 66;
	}

	@computed
	get ablaufIn1Month() {
		return this.ablaufInDays > 24 && this.ablaufInDays < 36;
	}

	@computed
	get displayInWochenmail() {
		return this.ablaufIn3Months || this.ablaufIn2Months || this.ablaufIn1Month;
	}

	static fromAladin() { }
}

export enum AgtType {
	ada = 'ada',
	agt = 'agt',
	sonstiges = 'sonstiges',
}

export class AgenturModel {
	constructor(opts: any) {
		// makeObservable(this);
		this.agtId = opts.agtId;
		this.agtRefId = opts.agtRefId;
		this.apsf = opts.apsf;
		this.apsl = opts.gsId;
		this.inspId = opts.inspId;
		this.gsId = opts.gsId;
		this.vdId = opts.vdId;
		this.name = opts.name;
		this.pro3paket1 = opts.pro3paket1;
		this.vtnrAnr = opts.vtnrAnr;
		this.vtnrBegVtnr = getDate(opts.vtnrBegVtnr);
		this.vtnrEndVtnr = getDate(opts.vtnrEndVtnr);
		this.vtnrBegin = getDate(opts.vtnrBegin);
		this.vtnrEnd = getDate(opts.vtnrEnd);
		this.vtnrBgzn = opts.vtnrBgzn;
		this.vtnrBgznvgb = opts.vtnrBgznvgb;
		this.vtnrEmail = opts.vtnrEmail;
		this.vtnrGpincBez = opts.vtnrGpincBez;
		this.vtnrGpincId = opts.vtnrGpincId;
		this.vtnrInspnr = opts.vtnrInspnr;
		this.vtnrLebend = opts.vtnrLebend;
		this.vtnrOrganr = opts.vtnrOrganr;
		this.vtnrOrt = opts.vtnrOrt;
		this.vtnrPlz = opts.vtnrPlz;
		this.vtnrProtab = opts.vtnrProtab;
		this.vtnrProvsys = opts.vtnrProvsys;
		this.vtnrOrt = opts.vtnrOrt;
		this.vtnrStr = opts.vtnrStr;
		this.vtnrTelFest = opts.vtnrTelFest;
		this.vtnrTelMobil = opts.vtnrTelMobil;
		this.vtnrTitle = opts.vtnrTitle;
		this.vtnrVertkat = opts.vtnrVertkat;
		this.vertKat = Vertkats.find((v) => v.id === this.vtnrVertkat);
		this.vtnrVname = opts.vtnrVname;
		this.vtnrZname = opts.vtnrZname;
		this.vtnrVtnr = opts.vtnrVtnr;
		// aladin
		this.aladinEmail = opts.email;
		this.provStufenAP = opts.provStufenAP;
		this.provStufenFP = opts.provStufenFP;

		if (!this.vtnrGpincId && this.provStufenAP && this.provStufenAP.toLowerCase().indexOf('spezialvertrieb') >= 0) {
			this.vtnrGpincId = 1190;
		}

		if (!this.vtnrGpincId) {
			//console.warn(this.agtId + ' setting to gp to 1190 because no gpid..')
			this.vtnrGpincId = 1190;
		}

		this.provApFonds = opts.provApFonds;
		this.provApLeben = opts.provApLeben;
		this.fachagenturFirmen = opts.fachagenturFirmen;
		this.fachagenturFirmenEndeDatum = opts.fachagenturFirmenEndeDatum;
		this.fachagenturKranken = opts.fachagenturKranken;
		this.fachagenturKrankenEndeDatum = opts.fachagenturKrankenEndeDatum;
		this.fachagenturBaufi = opts.fachagenturBaufi;
		this.fachagenturBaufiEndeDatum = opts.fachagenturBaufiEndeDatum;
		this.experteFonds = opts.experteFonds;
		this.experteFondsRezertifizierungsDatum = opts.experteFondsRezertifizierungsDatum;
		this.schadenregArt = opts.schadenregArt;
		this.schadenregHoehe = opts.schadenregHoehe;
		this.schadenregSchulungsRezertifizierungDatum = opts.schadenregSchulungsRezertifizierungDatum;
		this.schadenregSchulungEmailErinnerung = opts.schadenregSchulungEmailErinnerung;
		this.a34i = opts.a34i;

		this.visTeilnahme = opts.visTeilnahme;

		this.kwk = opts.kwk;
		this.faVermoegen = opts.faVermoegen;
		this.zertAusbildung = opts.zertAusbildung;
		this.kompAv = opts.kompAv;
		this.kompAb = opts.kompAb;

		this.bnrIds = opts.bnrIds;
		this.comment = opts.comment;

		// Fields for Multis
		this.mietmodell = opts.mietmodell;
		this._steartStringId = opts.steart;
		// this.steart = opts.steart; // wird im AgenturVMModel gesetz
		this.anzgeraete = opts.anzgeraete;
		this.leitungstyp = opts.leitungstyp;
		this.betankungsart = opts.betankungsart;
		this.vtnrType = opts.vtnrType;
		this.multi_gpk = opts.multi_gpk;
		this.buerotyp = opts.buerotyp;
		this.buerogemenischaft = opts.buerogemenischaft;
		this.nsl_leitagt = opts.nsl_leitagt;
		this.ip_prozess = opts.ip_prozess;

		this.setGebiet(opts);
		if (opts.lat) {
			this.lat = opts.lat;
		}
		if (opts.lng) {
			this.lng = opts.lng;
		}
		this.thCount = opts.thCount;	// needed for AgenturExtendedVM because of inheritance
		if (opts.ft && opts.ft.length > 0) {
			this.ft = opts.ft;
		}

	}
	@observable
	agtId: number;

	@observable
	agtRefId: number;

	apsf: number;
	apsl: number;
	gsId: string;
	vdId: string;
	inspId: number;
	name: string;
	pro3paket1: boolean;

	@computed
	get shouldBeTemp() {
		return this.agtId < 10000;
	}

	bnrIds: number[] = [];

	@observable
	users: UserModel[] = [];
	vertKat?: VertkatModel;

	// aladin2
	visTeilnahme?: string;
	kwk?: number;
	faVermoegen?: number;
	zertAusbildung?: number;
	kompAv?: number;
	kompAb?: number;

	// aladin1
	aladinEmail?: string;
	provStufenAP?: string;
	provStufenFP?: string;

	provApFonds?: number;
	provApLeben?: number;
	fachagenturFirmen?: number;
	fachagenturFirmenEndeDatum?: Date;
	fachagenturKranken?: number;
	fachagenturKrankenEndeDatum?: Date;
	fachagenturBaufi?: number;
	fachagenturBaufiEndeDatum?: Date;
	experteFonds?: number;
	experteFondsRezertifizierungsDatum?: Date;
	schadenregArt?: number;
	schadenregHoehe?: number;
	schadenregSchulungsRezertifizierungDatum?: Date;
	schadenregSchulungEmailErinnerung?: Date;
	a34i?: number;

	// geo
	lat?: number = 0;
	lng?: number = 0;

	// multis
	mietmodell?: string;
	_steartStringId?: string;
	steart?: SteartModel;
	anzgeraete?: string;
	leitungstyp?: string;
	betankungsart?: string;
	vtnrType?: string;
	multi_gpk?: string;
	buerotyp?: string;
	buerogemenischaft?: string;
	nsl_leitagt?: string;
	ip_prozess?: string;

	thCount?: number;
	ft: string[] = [];

	bnrIdCanSee(bnrId: number) {
		const u = this.users.find((u) => u.bnrId === bnrId);
		return !!u;
	}

	@computed
	get isPro3() {
		return this.vtnrGpincId === 1130;
	}

	@computed
	get GP() {
		let gpId = this.vtnrGpincId;
		if (!gpId) {
			gpId = 1190;
		}
		const res = allGps.find((gp) => gp.valueOf() === gpId);
		return res;
	}

	@computed
	get aladin() {
		return new AladinModel(this);
	}

	@computed
	get agtType() {
		if (this.steart && this.steart._name) {
			// needed for AgenturExtendedVM because of inheritance
			return AgtType.ada;
		}
		if (this._steartStringId && this._steartStringId.length > 0) {
			return AgtType.ada;
		}
		if (!!this.GP) {
			return AgtType.agt;
		}
		return AgtType.sonstiges;
	}


	@computed
	get showApLeben() {
		const gruendung = moment(this.vtnrBegin);
		if (gruendung.isAfter(moment('2016-01-01'))) {
			return false;
		}
		return true;
	}

	@observable
	gebiet: GebietModel = new GebietModel({});
	@action
	setGebiet(gebiet: GebietModel) {
		this.gebiet = gebiet;
	}
	vtnrAnr: number;
	vtnrBegin?: Date;
	vtnrEnd?: Date;
	vtnrBegVtnr?: Date;
	vtnrEndVtnr?: Date;
	//users: [{userId: "360168bb-aa2a-4460-8274-8517f414063b", bensl: "kahuc", firstName: "Matthias",…},…]
	vtnrBgzn: number;
	vtnrBgznvgb: number;
	@computed
	get email() {
		if (this.aladin && this.aladin.email) {
			return this.aladin.email;
		}
		return this.vtnrEmail;
	}
	vtnrEmail: string;
	@observable
	vtnrGpincBez: string;
	@observable
	vtnrGpincId: number;
	vtnrInspnr: number;
	vtnrLebend: number;
	vtnrOrganr: number;
	vtnrOrt: string;
	vtnrPlz: number;
	vtnrProtab: string;
	vtnrProvsys: number;
	vtnrStr: string;
	vtnrTelFest: string;
	vtnrTelMobil: string;
	vtnrTitle: string;
	vtnrVname: string;
	vtnrZname: string;
	vtnrVtnr: string;
	vtnrVertkat: number;
	comment: string = '';

	@computed
	get supportsErrungenschaften() {
		return this.vtnrGpincId > 0;
	}

	@computed
	get gpShort() {
		let shortText = formatGp(this.vtnrGpincId);
		if (!shortText && this.vertKat && this.vertKat.id === 19) {
			shortText = 'AGP';
		}
		if (!shortText && this.aladin.isSpezialvertrieb) {
			return 'SV';
		}
		return shortText;
	}

	@computed
	get provsys(): ProvSysModel {
		const p = ProvSysModels.find((p) => p.provId === this.vtnrProvsys);
		if (p) {
			return p;
		}
		return new ProvSysModel({ provId: 0, name: 'Unbekannt' });
	}

	@computed
	get apProvsysId() {
		const provisionsmodel = this.provsys.provId;
		const ort = this.vtnrOrt;
		if (provisionsmodel === 6) {
			return 'pro3';
		}

		if (provisionsmodel === 3) {
			return 'umstieg';
		}

		if (provisionsmodel === 4) {
			if (ort.toLowerCase().indexOf('berlin') >= 0) {
				return 'strategieB';
			}
			if (ort.toLowerCase().indexOf('leipzig') >= 0) {
				return 'strategieL';
			}
			return 'strategie';
		}

		if (provisionsmodel === 2) {
			// Ausgleichsmodell 		2	Woran können wir unterscheiden ob dv oder nicht
			// Ausgleichsmodell DV		2	Woran können wir unterscheiden ob dv oder nicht
			return 'ausgleich';
		}

		return provisionsmodel + ' not supported';
	}

	@computed
	get schadenregBetrag() {
		let h = this.aladin.schadenregHoehe;
		if (!h) {
			return 'k.A.';
		}
		switch (h) {
			case 0:
				return 'Keine';
			case 1:
				return 'Bis 500 €';
			case 2:
				return 'Bis 3000 €';
			default:
				return 'Unbekannte Schadensregulierungsart ' + h;
		}
	}

	@computed
	get schadenregBis3000() {
		return this.aladin.schadenregHoehe === 2;
	}

	@computed
	get supportsDigiFaktor() {
		const supportedVertkats = [10, 11, 15, 16];
		if (this.vertKat) {
			return supportedVertkats.includes(this.vertKat.id!);
		}
		return true;
	}

	@computed
	get fullText() {
		let s = '';
		s += ` ${this.name} `;
		s += ` ${this.agtId} `;
		s += ` ${formatGp(this.vtnrGpincId)} `;
		return s.toLowerCase();
	}
}

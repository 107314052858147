import { Button } from 'app/components/common/Button';
import { Icon } from 'app/components/common/Icon';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { BaseTreeStore, AgtBaseTreeStore } from 'app/stores/core/base.tree.store';
import { AllianzLogo } from 'app/containers/PrintDocumentContainer';
import { TreeNodeModel } from 'app/models/core/tree.node.model';
import { normalizeFilename } from 'app/utils';

interface IBerichtePanelHeaderProps {
	panelAreaRef: React.MutableRefObject<HTMLDivElement | null>;
	treeStore: AgtBaseTreeStore | BaseTreeStore;
	title?: string;
	extras?: JSX.Element;
}

export const TreePanelHeader = observer((props: IBerichtePanelHeaderProps) => {
	const location = useLocation<any>();
	const treeStore = props.treeStore;

	const history = useHistory();
	const node = treeStore.menu.current;
	const nodeTitle = node ? node.name : '';
	const title = props.title ? props.title : nodeTitle;

	const prevBericht = () => {
		const node = treeStore.menu.findPreviousNode(treeStore.menu.current);
		if (!node) {
			return;
		}
		history.push(treeStore.getPath(node, location as any));
	};
	const nextBericht = () => {
		const node = treeStore.menu.findNextNode(treeStore.menu.current);
		if (!node) {
			return;
		}
		history.push(treeStore.getPath(node, location as any));
	};

	if (!node) return null;

	const downloadPdf = node.downloadPdf || <DownloadPdf {...props} />;

	return (
		<>
			<PrintHeader title={title} node={node} />
			<div className="panel-header">
				<div className="header-left">
					<div className="breadcrumb">
						{node &&
							node.parents &&
							node.parents.map((p) => (
								<span key={p.name}>
									{p.name} {'>'}{' '}
								</span>
							))}
					</div>
					<div className="subtitle is-4">{node && title}</div>
					{node.subTitle && <div className="subtitle is-5">{node.subTitle}</div>}
					<div className="details">
						{node.datenStand && (
							<span className="date">
								<strong>{node.datenStand}</strong>
							</span>
						)}{' '}
						{downloadPdf}
					</div>
				</div>
				<div className="header-right">
					<div className="button-group panel-nav">
						<Button className="button is-inverted panel-nav-button" onClick={prevBericht}>
							<Icon iconClass="chevron-left" />
						</Button>
						<Button className="button is-inverted panel-nav-button" onClick={nextBericht}>
							<Icon iconClass="chevron-right" />
						</Button>
					</div>
				</div>
				<div className="header-extras">
					<div className="extras">{props.extras && <>{props.extras}</>}</div>
				</div>
				{treeStore.showFilter && <div className="panel-filters">{node.filterComponent ? <>{node.filterComponent}</> : <>{treeStore.filterComponent}</>}</div>}
			</div>
		</>
	);
});

interface IPrintHeader {
	title: string;
	node: TreeNodeModel;
}
const PrintHeader = observer((props: IPrintHeader) => {
	const { session, agenturUiStore } = useStore();
	const { title, node } = props;

	const user = session.currentUser;
	const agt = agenturUiStore.current;

	return (
		<div className="print-header">
			<div className="grid is-col-auto align-center">
				<div className="left pad-1rem title is-3">
					{node &&
						node.parents &&
						node.parents.map((p) => (
							<span key={p.name}>
								{p.name} {'>'}{' '}
							</span>
						))}
					{title}
				</div>
				<div className="text-right pad-1rem">
					<AllianzLogo />
				</div>
			</div>

			<div className="pad-bottom-1rem">
				<table className="table">
					<thead>
						<tr>
							<th>Erstellt durch</th>
							{agt && <th>Agentur</th>}
							<th>Stand</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>{user?.name}</td>
							{agt && <td>{agt.name}</td>}
							<td>{node.getDatenStand()}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	);
});

const DownloadPdf = observer((props: IBerichtePanelHeaderProps) => {
	const { pdfStore } = useStore();
	const { treeStore } = props;

	// const node = treeStore.menu.current;
	// const nodeTitle = node ? node.name : '';
	// const title = props.title ? props.title : nodeTitle;

	const downloadPdf = () => {
		// if (!treeStore.menu.current || !treeStore.printPath) {
		// 	console.error('ERR: No current node or print path');
		// 	return;
		// }
		// const pathToPrint = treeStore.menu.current.path;
		// remove '#/' from start of hash
		const currentPath = window.location.hash.substring(2);
		let fileName = 'Bericht';
		if (treeStore) {
			const repName = treeStore.menu.current!.parents.map((x) => x.name).join('-');
			fileName = normalizeFilename(repName);
		}

		pdfStore.printPdf2(currentPath, fileName);
	};

	// const downloadPdf = () => {
	// 	if (!props.panelAreaRef.current) {
	// 		return;
	// 	}
	// 	const html = props.panelAreaRef.current.innerHTML;
	// 	const tb = new ExportPdfTable();
	// 	tb.data = html;
	// 	tb.isHtml = true;
	// 	const versionDate = node && node.datenStandDate ? node.datenStandDate() : moment().format('YYYY-DD-MM');
	// 	pdfStore.printPdf({ tables: [tb], title, versionDate, agt: undefined });
	// };

	return (
		<Button className="button is-text" onClick={downloadPdf}>
			Download PDF
		</Button>
	);
});

import React from 'react';

import { AgtBestand5JahresVerlauf } from 'app/components/berichte/bestand/AgtBestand5JahresVerlauf';
import { AgtNeugeschaeft } from 'app/components/berichte/bestand/AgtNeugeschaeft';
import { BestandUndBewertungsliste } from 'app/components/berichte/bestand/BestandUndBewertungsliste';
import { DatenStand } from 'app/components/berichte/DatenStand';
import { Geburstagsliste } from 'app/components/berichte/personal/Gerburtstagsliste';
import { Jubelliste } from 'app/components/berichte/personal/Jubelliste';
import { Pensionsliste } from 'app/components/berichte/personal/Pensionsliste';
import { FilterStearts as AllSteartsExcept, LeiterSpeziSteartIds, MultiSteartIds, SpeziSteartIds, Steart } from '../core/steart.model';
import { ITreeNodeModel } from '../core/tree.node.model';
import { AgenturenBetreungList } from 'app/components/berichte/orga/UnbetreuteAgenturenList';
import { DigitalreportListe } from 'app/components/berichte/digitalreport/DigitalreportListe';
import { AgtErrungenschaftenOverview } from 'app/components/berichte/errungenschaften/AgtErrungenschaftenOverview';
import { AgtErrungenschaftenList } from 'app/components/berichte/errungenschaften/AgtErrungenschaftenList';
import { LeadNowListe } from 'app/components/berichte/leadnow/LeadNowListe';
import { LeadNowType } from 'app/stores/ui/agt.leadnow.ui.store';
import { FacebookListe } from 'app/components/berichte/socialmedia/FacebookListe';
import { InstagramListe } from 'app/components/berichte/socialmedia/InstagramListe';
import { MultiGp2025 } from 'app/components/berichte/multigp/MultiGp2025';
import { FitnessTrackerGUP } from 'app/components/berichte/fitnesstracker/FitnessTrackerGUP';
import { SociaMediaList } from 'app/components/berichte/multigp/SociaMediaList';
import { KvmListe } from 'app/components/berichte/kvm/KvmListe';
import { AkumaByVdList, AkumaList } from 'app/components/berichte/multigp/AkumaList';
import { LeadNowList } from 'app/components/berichte/multigp/LeadNowList';

export const BerichteTreeNodes: ITreeNodeModel = {
	name: 'root',
	icon: 'root',
	path: '',
	nodes: [
		{
			name: 'Produktion',
			icon: 'chart-line',
			path: 'production',
			visibileForRoles: AllSteartsExcept([Steart.DVSADMIN]),
			nodes: [
				{
					name: 'GSW',
					icon: '',
					path: 'gsw',
					visibileForRoles: [Steart.GSL],

					nodes: [
						{
							name: 'TODO',
							icon: '',
							path: 'xx',
							component: <div> </div>,
							datenStand: <DatenStand dataType="aup_bestand" />,
							hideFilter: true,
						},
					],
				},
				{ name: 'Geschäftsplan', icon: '', path: 'gp' },
				{ name: 'Agentur GP Positionen', icon: '', path: 'agtpos' },
				{ name: 'Ring', icon: '', path: 'ring', visibileForRoles: [Steart.VBL, ...SpeziSteartIds, ...LeiterSpeziSteartIds] },
				{
					name: 'Herbstwerbung',
					icon: '',
					path: 'herbst',
					// visibileForRoles: [Steart.VBL, ...SpeziSteartIds, ...LeiterSpeziSteartIds]
				},
			],
		},
		{
			name: 'Bestand',
			icon: 'landmark',
			path: 'bestand',
			visibileForRoles: AllSteartsExcept([Steart.DVSADMIN]),

			nodes: [
				{
					name: 'Fitness-Tracker',
					icon: '',
					path: 'fit',
					//visibileForRoles:  [Steart.DVSADMIN],
				},
				{
					name: 'Bestand und Bewertung',
					icon: '',
					path: 'bestandundbewertung',
					component: <BestandUndBewertungsliste />,
					datenStand: <DatenStand dataType="aup_bestand" />,
					visibileForRoles: [Steart.VBL, ...SpeziSteartIds, ...LeiterSpeziSteartIds],
				},
				{
					name: 'Digital-Report',
					icon: '',
					path: 'digitalreport',
					component: <DigitalreportListe />,
					datenStand: <DatenStand dataType="digitalreport" />,
				},

				{
					name: 'Bestandsanalyse',
					icon: '',
					path: 'analyse',
					visibileForRoles: [Steart.VBL, ...SpeziSteartIds, ...LeiterSpeziSteartIds],

					nodes: [
						{
							name: 'Agenturbestand im 5-Jahresverlauf',
							icon: '',
							path: 'agtbestand5jahre',
							component: <AgtBestand5JahresVerlauf />,
							datenStand: <DatenStand dataType="aup_bestand" />,
							hideFilter: true,
						},
						{
							name: 'Neugeschäft im 5-Jahresverlauf',
							icon: '',
							path: 'agtneugesch5jahre',
							component: <AgtNeugeschaeft />,
							datenStand: <DatenStand dataType="aup_bestand" />,
							hideFilter: true,
						},
					],
				},
				{
					name: 'Agentur Cluster',
					icon: '',
					path: 'cluster',
					nodes: [],
				},
			],
		},
		{
			name: 'Kunde',
			icon: 'users',
			path: 'kunde',
			nodes: [
				{
					name: 'AKUMA',
					icon: '',
					path: 'akuma',
					visibileForRoles: AllSteartsExcept([Steart.DVSADMIN]),
				},

				{
					name: 'Lead.NOW',
					icon: '',
					path: 'leadnow',
					visibileForRoles: AllSteartsExcept([Steart.DVSADMIN]),
					nodes: [
						{
							name: 'Brutto',
							icon: '',
							path: 'brutto',
							component: <LeadNowListe type={LeadNowType.brutto} />,
							datenStand: <DatenStand dataType="leadnow" />,
						},
						{
							name: 'Netto',
							icon: '',
							path: 'netto',
							component: <LeadNowListe type={LeadNowType.netto} />,
							datenStand: <DatenStand dataType="leadnow" />,
						},
					],
				},
				{
					name: 'KVM',
					icon: '',
					path: 'kvm',
					visibileForRoles: [Steart.DVSADMIN],
					nodes: [
						{
							name: 'Anrufe',
							icon: '',
							path: 'calls',
							component: <KvmListe />,
							datenStand: <DatenStand dataType="kvm" />,
						},

					],
				},
				{
					name: 'Social-Media',
					icon: '',
					path: 'sm',
					nodes: [
						{
							name: 'Facebook',
							icon: '',
							path: 'facebook',
							component: <FacebookListe />,
							datenStand: <DatenStand dataType="facebook" />,
							downloadPdf: <></>,
						},
						{
							name: 'Instagram',
							icon: '',
							path: 'instagram',
							component: <InstagramListe />,
							datenStand: <DatenStand dataType="facebook" />,
							downloadPdf: <></>,
						},
					],
				},

				// {
				// 	name: 'Digitalisierungsfaktor',
				// 	icon: '',
				// 	path: 'digi',
				// 	nodes: [],
				// 	visibileForRoles: AllSteartsExcept([Steart.DVSADMIN]),
				// },
			],
		},
		{
			name: 'Personal',
			icon: 'address-card',
			path: 'personal',
			visibileForRoles: AllSteartsExcept([Steart.DVSADMIN, ...MultiSteartIds]),

			nodes: [
				{ name: 'Geburtstagsliste', icon: '', path: 'geburtstag', component: <Geburstagsliste /> },
				{ name: 'Jubliäumsliste', icon: '', path: 'jubilaen', component: <Jubelliste /> },
				{ name: 'Pensionierung / Nachfolgeplanung', icon: '', path: 'pension', component: <Pensionsliste />, subTitle: 'Mitarbeiter die 55 Jahre oder älter sind' },
			],
		},
		{
			name: 'Errungenschaften',
			icon: 'trophy',
			path: 'errungenschaften',
			//visibileForRoles: AllSteartsExcept([Steart.DVSADMIN, ...MultiSteartIds]),
			hideFromAgt: true,

			nodes: [
				{ name: 'Übersicht', icon: '', path: 'overview', component: <AgtErrungenschaftenOverview /> },
				{ name: 'Agentur Errungenschaften', icon: '', path: 'agenturen', component: <AgtErrungenschaftenList /> },
			],
		},
		{
			name: 'Multi GP',
			icon: 'sitemap',
			path: 'multigp',
			visibileForRoles: [Steart.DVSADMIN],
			nodes: [
				{ name: 'Übersicht', icon: '', path: 'overview', component: <MultiGp2025 /> },
				{ name: 'Gup Agt ', icon: '', path: 'gup', component: <FitnessTrackerGUP showMultiGPfields={true} /> },
				{ name: 'SM Agt', icon: '', path: 'sm', component: <SociaMediaList /> },
				{ name: 'Akuma Agt', icon: '', path: 'akuma', component: <AkumaList /> },
				{ name: 'Akuma Agt by vd', icon: '', path: 'akumabyvd', component: <AkumaByVdList /> },
				{ name: 'LeadNow Agt', icon: '', path: 'leadnow', component: <LeadNowList /> },
			],
		},
		{
			name: 'Orga',
			icon: 'sitemap',
			path: 'Orga',
			visibileForRoles: [Steart.GSL,],
			nodes: [{ name: 'Betreuung', icon: '', path: 'unbetreut', component: <AgenturenBetreungList /> }],
		},
	],
};

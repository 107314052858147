import { observer } from 'mobx-react';
import { DocumentVM, DokumentReadState } from 'app/stores/ui/document.ui.store';
import { HtmlContent } from 'app/components/common/HtmlContent';
import { formatDateLong } from 'app/utils';
import React, { useEffect, useState } from 'react';
import { Button } from '../common/Button';
import { useStore } from 'app/context';
import { Field, Form, Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { TextEditorField } from '../form/TextEditorField';
import { Icon } from '../common/Icon';
import { ConfirmModal } from '../common/ConfirmModal';
import { runInAction } from 'mobx';
import { SharedUser } from '../shared/SharedUser';
import { BnrUploadModel } from 'app/stores/bnr.upload.store';
import { FileTypeIcon } from '../common/FileTypeIcon';
import { FileSize } from '../common/FileSize';
import { notify } from '../common/notify';
import { FileUpload } from '../common/FileUpload';
import { DocumentAp } from './DocumentAp';
import FormField from '../form/FormField';
import AgtSelectField from '../form/AgtSelectField';
import { PlanSelectField } from '../form/PlanSelectField';
import { Prompt } from 'react-router';
import { BnrDocumentShareModel } from 'app/stores/bnr.document.store';
import { Jpg25LeitfadenButton, JpgThemen } from './JpgThemen';
import { Jpg25DocumentShareModal } from './Jpg25DocumentShareModal';
import Config from 'Config';

export interface IAgtPlanungDoc {
	doc: DocumentVM;
	showAgt: boolean;
	showPlan: boolean;
}

export const DocumentSharedBy = observer((props: IAgtPlanungDoc) => {
	if (!props.doc.isSharedBy) {
		return <></>;
	}
	const doc = props.doc.doc;

	return (
		<div className="document-shared-by">
			VON:
			<SharedUser bnrId={doc.bnrId} />
		</div>
	);
});




const DocumentSharedWith = observer((props: IAgtPlanungDoc) => {
	const [sharedWith, setSharedWith] = useState<BnrDocumentShareModel[]>([]);

	useEffect(() => {
		setSharedWith(props.doc.doc.sharedWith);
	}, [props.doc.doc.sharedWith]);

	if (sharedWith.length === 0) {
		return <></>;
	}

	return (
		<div className="document-shared-with">
			AN:
			<span className="comma-list">

				{sharedWith.map((share) => (
					<span className="comma-list-item" key={share.bnrId}>
						<SharedUser bnrId={share.bnrId} />
					</span>
				))}

			</span>
		</div>
	);
});



const DocumentActions = observer((props: IAgtPlanungDoc) => {
	const { uiStore, documentUiStore } = useStore();
	const { submitForm } = useFormikContext();

	const [isEditing, setIsEditing] = useState(props.doc.doc.id === documentUiStore.currentDocId);

	useEffect(() => {
		setIsEditing(props.doc.doc.id === documentUiStore.currentDocId);
	}, [documentUiStore.currentDocId, props.doc.doc.id]);
	// const isEditing = props.doc.doc.id === documentUiStore.currentDocId;

	const doc = props.doc;
	const confirmDeleteModalId = uiStore.modalIds.confirmDeleteDocument + doc.id;
	const showConfirmDlg = () => {
		runInAction(() => {
			uiStore.showModal(confirmDeleteModalId);
		});
	};
	const onDelete = async () => {
		await documentUiStore.deleteById(doc.id);
		documentUiStore.setCurrentModalDoc();
	};

	const onCopy = async () => {
		if (isEditing) {
			await submitForm();
		}
		documentUiStore.copyDocument(doc.id);
	};

	const onDownload = async () => {
		if (isEditing) {
			await submitForm();
		}

		documentUiStore.download(doc);
	};

	const onSendEmail = async () => {
		if (isEditing) {
			await submitForm();
		}
		documentUiStore.setCurrentModalDoc(doc);
		uiStore.showModal(uiStore.modalIds.documentSendModal);
	};

	const onShare = async () => {
		if (doc.isVblJpg25) {
			// jpg25 uses formik to store the shares
			runInAction(() => {
				// uiStore.hideModal(uiStore.modalIds.documentViewModal);
				documentUiStore.setCurrentModalDoc(props.doc);
				uiStore.showModal(uiStore.modalIds.documentViewModal);
				documentUiStore.setCurrent(props.doc);
				uiStore.showModal(uiStore.modalIds.documentShareJpg25Modal);
			});
			return;
		}

		await submitForm();
		runInAction(() => {
			documentUiStore.setCurrentModalDoc(doc);
			uiStore.hideModal(uiStore.modalIds.documentViewModal);
			uiStore.showModal(uiStore.modalIds.documentShareModal);
		});
	};

	const onToggleRead = () => {
		runInAction(async () => {
			await documentUiStore.sharedDocumentStore.toggleRead(doc);
		});
	};

	return (
		<div className={`document-actions ${isEditing ? 'is-editing' : ''}`}>
			{doc.isVblJpg25 && <Jpg25LeitfadenButton />}
			{doc.canDelete && (
				<>
					<Button className="button is-icon is-small" onClick={showConfirmDlg} data-tooltip="Löschen" data-tooltip-position="bottom">
						<Icon iconClass="trash" />
					</Button>
					<ConfirmModal onConfirm={onDelete} onCancel={() => { }} modalId={confirmDeleteModalId}>
						Sind Sie sicher, dass Sie das Dokument <strong>{doc.doc.title}</strong>
						{doc.uploads.length > 0 && (
							<>
								{' '}
								mit <strong>{doc.uploads.length} Anhängen </strong>
							</>
						)}
						löschen wollen? Dies kann NICHT rückgängig gemacht werden.
					</ConfirmModal>
				</>
			)}
			{doc.canCopy && (
				<>
					<Button className="button is-icon is-small" onClick={onCopy} data-tooltip="Duplizieren" data-tooltip-position="bottom">
						<Icon iconClass="files-o" />
					</Button>
				</>
			)}
			{doc.canDownload && (
				<>
					<Button className="button is-icon is-small" onClick={onDownload} data-tooltip="Exportieren" data-tooltip-position="bottom">
						<Icon iconClass="download" />
					</Button>
					<Button className="button is-icon is-small" onClick={onSendEmail} data-tooltip="Per E-Mail versenden" data-tooltip-position="bottom">
						<Icon iconClass="share-all" />
					</Button>
				</>
			)}
			{doc.canShare && (
				<>
					<Button className="button is-icon is-small" onClick={onShare} data-tooltip={'Teilen'} data-tooltip-position="bottom">
						<Icon iconClass="share-alt" />
					</Button>
				</>
			)}

			<Button
				className={`button is-icon is-small is-readonly ${doc.doc.editable ? 'is-grey' : ''}`}
				data-tooltip={doc.doc.editable ? 'Bearbeitbar' : 'Schreibgeschützt'}
				data-tooltip-position="bottom"
			>
				{doc.doc.editable ? <Icon iconClass="unlock-alt" /> : <Icon iconClass="lock" />}
			</Button>

			{doc.canToggleShareRead && (
				<Button
					className="button is-icon is-small"
					onClick={onToggleRead}
					data-tooltip={doc.readState === DokumentReadState.read ? 'Als ungelesen markieren' : 'Als gelesen markieren'}
					data-tooltip-position="bottom"
				>
					{doc.readState === DokumentReadState.read && (
						<>
							<Icon iconClass="eye-slash" />
						</>
					)}

					{doc.readState === DokumentReadState.unread && (
						<>
							<Icon iconClass="eye" />
						</>
					)}
				</Button>
			)}
		</div>
	);
});
const DocumentChanges = observer((props: IAgtPlanungDoc) => {
	const doc = props.doc.doc;
	return (
		<div className="document-changes">
			<span>
				Erstellt: <strong>{formatDateLong(doc.created)}</strong>
			</span>
			<span>
				Zuletzt geändert: <strong>{formatDateLong(doc.modified)}</strong>
			</span>
		</div>
	);
});

interface IDocumentUpload {
	document: DocumentVM;
	upload: BnrUploadModel;
}
const DocumentUpload = observer((props: IDocumentUpload) => {
	const { documentUiStore, uiStore } = useStore();
	const upload = props.upload;
	const confirmDeleteModalId = uiStore.modalIds.confirmDeleteUpload + upload.id;

	const onDownload = async () => {
		if (upload.fileType === 'application/pdf') {
			const blob = await documentUiStore.bnrUploadStore.downloadFile(upload.id, upload.title, upload.type, true);
			const url = URL.createObjectURL(blob!);
			uiStore.setShowPdf(url);
			uiStore.setShowPdfTitle(upload.title);
			return;
		}

		await documentUiStore.bnrUploadStore.downloadFile(upload.id, upload.title, upload.type);
	};

	const showConfirmDelete = () => {
		uiStore.showModal(confirmDeleteModalId);
	};
	const onDelete = async () => {
		await documentUiStore.deleteUpload(props.document, upload);
		notify('Dokument wurde gelöscht', '', 'info');
	};

	return (
		<>
			<div className="document-upload">
				<Button className="button is-blank download-button " onClick={onDownload}>
					<FileTypeIcon mime={upload.fileType} />
					<div className="file-name">{upload.title}</div>
					<FileSize fileSize={upload.fileSize} />
				</Button>
				<Button className="button is-icon is-small delete-button" onClick={showConfirmDelete}>
					<Icon iconClass="trash" />
				</Button>
			</div>
			<ConfirmModal onConfirm={onDelete} onCancel={() => { }} modalId={confirmDeleteModalId}>
				Sind Sie sicher, dass Sie diesen Upload löschen wollen? Dies kann nicht rückgängig gemacht werden.
			</ConfirmModal>
		</>
	);
});

const DocumentUploads = observer((props: IAgtPlanungDoc) => {
	const uploads = props.doc.uploads;
	return (
		<div className="document-uploads">
			<div className="uploads-title">
				<Icon iconClass="paperclip" />
				<span>Anhänge</span>
				<span className="tag is-marginless is-small">{uploads.length}</span>
			</div>
			{props.doc.doc.editable && (
				<FileUpload
					maxSize={20000000}
					// maxFiles={1}
					multiple={false}
					dropZoneActiveText={'Datei hier loslassen'}
					dropZoneText={'Datei hinzufügen'}
					uploadingText={'Upload läuft'}
					agtId={props.doc.agt?.agtId}
					planId={props.doc.plan?.id}
					dokId={props.doc.id}
					type={'plan'}
					icon="plus-circle"
					inline={true}
				/>
			)}

			{uploads.length > 0 && (
				<div className="upload-items">
					{uploads.map((u) => (
						<DocumentUpload key={u.id} document={props.doc} upload={u} />
					))}
				</div>
			)}
		</div>
	);
});

const DocumentEditState = observer((props: IDocumentViewer) => {
	const { documentUiStore, uiStore } = useStore();
	const [editMode, setEditMode] = useState<boolean>(false);
	const [showNavi, setShowNavi] = useState<boolean>(true);
	const [isFirst, setIsFirst] = useState<boolean>(true);
	const [isLast, setIsLast] = useState<boolean>(true);
	const { resetForm } = useFormikContext();

	useEffect(() => {
		const id = props.doc.doc.id;
		const firstDoc = documentUiStore.currentItems[0];
		const lastDoc = documentUiStore.currentItems[documentUiStore.currentItems.length - 1];

		if (firstDoc) {
			setIsFirst(firstDoc.id === id);
		}
		if (lastDoc) {
			setIsLast(lastDoc.id === id);
		}

		if (props.doc.doc.id === documentUiStore.currentDocId) {
			setEditMode(true);
			setShowNavi(false);
		} else {
			setShowNavi(props.hideNavi === false ? false : true);
			setEditMode(false);
		}
	}, [documentUiStore.currentDocId, props.doc.doc.id, documentUiStore.currentItems, props.hideNavi]);

	const canEdit = props.doc.doc.editable;
	const onEdit = () => {
		if (canEdit) {
			if (props.editInModal) {
				documentUiStore.setCurrentModalDoc(props.doc);
				uiStore.showModal(uiStore.modalIds.documentViewModal);
			}
			documentUiStore.setCurrent(props.doc);
		}
	};
	const onCancel = async () => {
		resetForm();
		props.onClose();
		// documentUiStore.undoAdd();
		documentUiStore.setCurrent();
	};

	const onNext = () => {
		documentUiStore.setNextModalDoc();
	};
	const onPrev = () => {
		documentUiStore.setPreviousModalDoc();
	};

	return (
		<div className={`modal-footer document-edit-state grid ${showNavi ? 'is-col-half' : 'is-col-auto'}`}>
			<div className="is-left">
				{showNavi && (
					<>
						<Button className="button is-primary is-inverted" onClick={onPrev} disabled={isFirst}>
							<Icon iconClass="chevron-left" />
						</Button>
						<Button className="button is-primary is-inverted" onClick={onNext} disabled={isLast}>
							<Icon iconClass="chevron-right" />
						</Button>
					</>
				)}
			</div>
			<div className="is-right">
				{editMode ? (
					<>
						<Button className="button is-primary" type="submit">
							Speichern
						</Button>
						<Button className="button" onClick={onCancel}>
							Abbrechen
						</Button>
					</>
				) : (
					<Button className={`button is-inverted ${canEdit ? 'is-primary' : ''}`} onClick={onEdit} disabled={!canEdit}>
						{!canEdit && <Icon iconClass="lock" />}
						<span>Bearbeiten</span>
					</Button>
				)}
			</div>
		</div>
	);
});

interface IDocumentViewer {
	doc: DocumentVM;
	showAgt: boolean;
	showPlan: boolean;
	hideNavi?: boolean;
	editInModal?: boolean;
	printMode?: boolean;
	onClose: () => void;
}

const documentFormSchema = Yup.object({
	title: Yup.string().required('Titel ist erforderlich').default(''),
	text: Yup.string().default(''),
	meta: Yup.object().default({}),
	agtId: Yup.number(),
	planYear: Yup.number(),
}).required();

type DocumentForm = Yup.InferType<typeof documentFormSchema>;

export const DocumentViewer = observer((props: IDocumentViewer) => {
	const { documentUiStore, agtPlanStore } = useStore();
	const docModel = props.doc.doc;

	const title = docModel.title;
	const text = docModel.content.text;
	const meta = docModel.content.meta;

	const { printMode } = props;

	const handleSubmit = async (values: DocumentForm) => {
		docModel.planYear = undefined;
		// @ts-ignore
		docModel.planId = undefined;
		const planYear = values.planYear ? parseInt(values.planYear.toString()) : undefined;
		const agtId = values.agtId ? parseInt(values.agtId.toString()) : undefined;
		docModel.agtId = agtId;
		const plan = props.doc.plan;
		if (planYear && planYear > 0 && agtId && agtId > 0) {
			const plan = await agtPlanStore.findByAgtIdYear(agtId, planYear);
			if (plan && plan.id) {
				runInAction(() => {
					props.doc.plan = plan;
					docModel.planId = plan.id!;
					docModel.planYear = plan.year;
				});
			}
		} else {
			if (plan && plan.id && planYear !== 0) {
				runInAction(() => {
					props.doc.plan = plan;
					docModel.planId = plan.id!;
					docModel.planYear = plan.year;
				});
			}
		}

		docModel.title = values.title;
		docModel.content.text = values.text;
		await documentUiStore.save(props.doc);
		documentUiStore.setCurrent();
		props.onClose();
		return true;
	};

	let DocFormComponent = <></>;
	switch (docModel.category) {
		case 'ap':
			DocFormComponent = <DocumentAp {...props} />;
			break;
		case 'kbvr':
			DocFormComponent = <DocumentKbv {...props} />;
			break;
		default:
			DocFormComponent = <DocumentStandard {...props} />;
			break;
	}

	const cat = getDocumentClassname({ category: docModel.category });

	const [isEditing, setIsEditing] = useState(props.doc.doc.id === documentUiStore.currentDocId);
	useEffect(() => {
		setIsEditing(props.doc.doc.id === documentUiStore.currentDocId);
	}, [documentUiStore.currentDocId, props.doc.doc.id]);

	return (
		<div className={`widget document-widget is-${cat}`}>
			<Formik
				initialValues={{
					title,
					text,
					meta,
					agtId: docModel.agtId ? docModel.agtId : 0,
					planYear: docModel.planYear,
				}}
				validationSchema={documentFormSchema}
				onSubmit={handleSubmit}
			>
				{({ dirty, errors }) => {
					return (
						<>
							<div className="debug">
								{docModel.id} - {docModel.category}
							</div>
							<Prompt when={!!dirty} message={(location) => `Sie haben ungespeicherte Änderungen. Wenn Sie diese Seite verlassen gehen dies verloren. Sicher?`} />

							<Form className={`document-form ${isEditing ? 'is-editing' : 'is-reading'} is-${cat}`}>
								<div className="document-form-body">
									<DocumentTag category={docModel.category} />
									{!props.doc.isKbv && <DocumentActions {...props} />}
									<DocumentChanges {...props} />
									<div className="sharing">
										<DocumentSharedBy {...props} />
										<DocumentSharedWith {...props} />
									</div>

									{DocFormComponent}
									<DocumentUploads {...props} />
								</div>

								<div className="document-form-footer">
									<DocumentEditState {...props} />
								</div>

								<Jpg25DocumentShareModal  {...props} />
								{/* <DocumentAgt {...props} />
								<DocumentPlan {...props} /> */}
							</Form>
						</>
					);
				}}
			</Formik>
		</div>
	);
});

interface IDocumentTag {
	category: string;
}
const getDocumentClassname = (props: IDocumentTag): string => {
	const validTags: string[] = ['ap', 'kbvr', 'notiz', 'ev', 'jpg', 'upload', 'an'];
	return validTags.includes(props.category) ? props.category : 'none';
};

const DocumentTag = (props: IDocumentTag) => {
	const cat = getDocumentClassname(props);
	let documentIcon = 'file-alt';

	switch (cat) {
		case 'ap':
			documentIcon = 'tasks';
			break;
		case 'kbvr':
			documentIcon = 'calculator';
			break;
		case 'notiz':
			documentIcon = 'sticky-note';
			break;
		case 'ev':
			documentIcon = 'project-diagram';
			break;
		case 'jpg':
			documentIcon = 'calendar-alt';
			break;
		case 'upload':
			documentIcon = 'file-upload';
			break;
		case 'an':
			documentIcon = 'file-contract';
			break;
	}

	return (
		<div className="document-tag">
			<div className={`tag color-${cat}`}>
				<Icon iconClass={documentIcon} />
			</div>
		</div>
	);
};

const JpgOptions = observer((props: IAgtPlanungDoc) => {
	const plan = props.doc.plan;

	if (!props.doc.isMine) {
		return <> </>;
	}
	if (!plan) {
		return <>Geht nicht ohne Plan </>;
	}

	return (
		<>
			<div className="debug">
				{props.doc.isVblJpg25 && <>isVblJpg25</>}
				<br />
				{props.doc.doc.category} {props.doc.currentUser.isVbl && <>VBL</>} {props.doc.planYear}
				<br />
				{props.doc.plan && (
					<>
						HasPlan title:{props.doc.plan.title} year:{props.doc.plan.year}
					</>
				)}
			</div>

			<div className="document-intro">
				<strong>Rückblick/Ausblick</strong> <br />
				Wie war das vergangene Jahr und was nehme ich mir für das kommende Jahr vor?
			</div>
		</>
	);
});

const DocumentStandard = observer((props: IAgtPlanungDoc) => {
	const doc = props.doc.doc;

	const { documentUiStore } = useStore();
	const [editMode, setEditMode] = useState<boolean>(false);
	const { values } = useFormikContext();
	const formValues = values as DocumentForm;
	useEffect(() => {
		if (props.doc.doc.id === documentUiStore.currentDocId) {
			setEditMode(true);
		} else {
			setEditMode(false);
		}
	}, [documentUiStore.currentDocId, props.doc.doc.id]);

	return (
		<>
			<div className="document-agt">
				{props.showAgt && (
					<div>
						<FormField label="Agentur">
							{editMode ? (
								<AgtSelectField name="agtId" />
							) : (
								<>
									{props.doc.agt && (
										<>
											{props.doc.agt.name} ({props.doc.agt.agtId})
										</>
									)}
								</>
							)}
						</FormField>
					</div>
				)}
				{props.showPlan && (
					<FormField label="Planung ">{editMode ? <PlanSelectField name="planYear" agtId={formValues.agtId} /> : <>{props.doc.planYear && <>{props.doc.planYear}</>}</>}</FormField>
				)}
			</div>
			{doc.category === 'jpg' && <JpgOptions {...props} />}
			{doc.category === 'jpg' && doc.planYear && doc.planYear >= 2025 && <JpgThemen {...props} />}
			<div className="title">{editMode ? <Field className="input" name="title" /> : <strong>{doc.title}</strong>}</div>
			{doc.category === 'jpg' && doc.planYear && doc.planYear >= 2025 && !props.doc.isJpg25TextVisible ? (
				<>Du siehst mich nicht</>
			) : (
				<div className="document-content">{editMode ? <TextEditorField name="text" /> : <HtmlContent content={doc.content.text} />}</div>
			)}
		</>
	);
});

const DocumentKbv = observer((props: IAgtPlanungDoc) => {
	return (
		<div>
			<strong>Der KBV Rechner steht zur Zeit nicht zur Verfügung.</strong>
		</div>
	);
});

import { AgenturNameCell } from 'app/components/shared/AgenturName.cell';
import { Table } from 'app/components/table/table';
import { AggregrationType, ITableColumn, TableModel } from 'app/components/table/table.model';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { AgtFitnessVm } from 'app/stores/ui/agt.fitness.ui.store';




export const AkumaList = observer(() => {
	const { berichteUiiStore, agtFitnessUiStore, agenturListUiStore } = useStore();
	const [hasItems, setHasItems] = useState(false);

	const tm = new TableModel<AgtFitnessVm, number>();
	const cols: ITableColumn<AgtFitnessVm, number>[] = [];
	cols.push({
		label: 'Agentur',
		path: 'agt.name',
		sortBy: 'agt.name',
		render: AgenturNameCell,
	});
	cols.push({
		label: 'akumaMultiGpCount',
		path: `akumaMultiGpCount`,
		sortBy: `akumaMultiGpCount`,
		agg: [
			{
				aggType: AggregrationType.sum,
			},
		],
	});

	cols.push({
		label: 'isAkumaPrimeUser',
		path: `isAkumaPrimeUser`,
		sortBy: `isAkumaPrimeUser`,
		cellModifier: 'border-left-green bg-green',
	});

	cols.push({
		label: 'isCurrentAkumaPrimeUser',
		path: `isCurrentAkumaPrimeUser`,
		sortBy: `isCurrentAkumaPrimeUser`,
	});

	cols.push({
		label: 'prime.items.length',
		path: `prime.items.length`,
		sortBy: `prime.items.length`,
	});

	cols.push({
		label: 'max Prime KW',
		path: `prime.kw`,
		sortBy: `prime.kw`,
	});

	cols.push({
		label: 'planer.gpWochen',
		path: `planer.gpWochen`,
		sortBy: `planer.gpWochen`,
		cellModifier: 'border-left-green bg-green',
	});

	cols.push({
		label: 'gpWochenCurrYear',
		path: `gpWochenCurrYear`,
		sortBy: `gpWochenCurrYear`,
	});

	cols.push({
		label: 'has12WeeksOfPlanerInCurrentYear',
		path: `has12WeeksOfPlanerInCurrentYear`,
		sortBy: `has12WeeksOfPlanerInCurrentYear`,
	});

	tm.sortBy = 'agt.name';
	tm.setCols(cols);

	tm.idProperty = 'agtId';
	tm.idType = 'number';

	useEffect(() => {
		agtFitnessUiStore.load().then(() => {
			let items = agtFitnessUiStore.items;
			if (berichteUiiStore.selectedAgt) {
				const agtId = berichteUiiStore.selectedAgt.agtId;
				items = items.filter((i) => i.agt.agtId === agtId);
			} else {
				items = agenturListUiStore.applyFilterOnAgt(items) as AgtFitnessVm[];
			}
			setHasItems(items.length > 0);
			tm.setRowData(items);
			tm.sort();
		});
	}, [tm, berichteUiiStore.selectedAgt, agenturListUiStore.filterHasChanged, agenturListUiStore, agtFitnessUiStore.items, agtFitnessUiStore]);

	return (
		<>
			{!hasItems ? (
				<div className="pad-1rem">Keine Daten gefunden</div>
			) : (
				<>
					<Table stickyHeader={true} stickyFooter={true} tm={tm}></Table>
				</>
			)}
		</>
	);
});

export const AkumaByVdList = observer(() => {
	const { berichteUiiStore, agtFitnessUiStore, agenturListUiStore } = useStore();
	const [hasItems, setHasItems] = useState(false);

	const tm = new TableModel<AgtFitnessVm, number>();
	const cols: ITableColumn<AgtFitnessVm, number>[] = [];
	cols.push({
		label: 'Agentur',
		path: 'agt.vdId',
		sortBy: 'agt.vdId',
		// render: AgenturNameCell,
	});
	cols.push({
		label: 'akumaMultiGpCount',
		path: `akumaMultiGpCount`,
		sortBy: `akumaMultiGpCount`,
		agg: [{
			aggType: AggregrationType.sum,
		}]

	});

	cols.push({
		label: 'isAkumaPrimeUser',
		path: `isAkumaPrimeUser`,
		sortBy: `isAkumaPrimeUser`,
		cellModifier: 'border-left-green bg-green',
		agg: [{
			aggType: AggregrationType.sum,
		}]

	});

	cols.push({
		label: 'isCurrentAkumaPrimeUser',
		path: `isCurrentAkumaPrimeUser`,
		sortBy: `isCurrentAkumaPrimeUser`,
		agg: [{
			aggType: AggregrationType.sum,
		}]

	});

	cols.push({
		label: 'isCurrentAkumaPrimeUserGpCount',
		path: `isCurrentAkumaPrimeUserGpCount`,
		sortBy: `isCurrentAkumaPrimeUserGpCount`,
		agg: [{
			aggType: AggregrationType.sum,
		}]

	});

	cols.push({
		label: '	',
		path: `has12WeeksOfPlanerInCurrentYear`,
		sortBy: `has12WeeksOfPlanerInCurrentYear`,
		agg: [{
			aggType: AggregrationType.sum,
		}]

	});


	cols.push({
		label: 'akumaPlanerGpCount',
		path: `akumaPlanerGpCount`,
		sortBy: `akumaPlanerGpCount`,
		agg: [{
			aggType: AggregrationType.sum,
		}]

	});




	tm.sortBy = 'agt.name';
	tm.setCols(cols);

	tm.idProperty = 'agtId';
	tm.idType = 'number';

	const pivotCol = {
		label: 'VD',
		path: 'agt.vdId',
	}

	const aggCol = {
		label: 'Logins',
		path: 'isAkumaPrimeUser',
		agg: [
			{
				aggType: AggregrationType.sum
			}
		]
	}


	useEffect(() => {
		agtFitnessUiStore.load().then(() => {
			let items = agtFitnessUiStore.items;
			if (berichteUiiStore.selectedAgt) {
				const agtId = berichteUiiStore.selectedAgt.agtId;
				items = items.filter((i) => i.agt.agtId === agtId);
			} else {
				items = agenturListUiStore.applyFilterOnAgt(items) as AgtFitnessVm[];
			}
			setHasItems(items.length > 0);
			tm.setGroupByData(items, (x) => x.agt.vdId);
			// tm.setPivotData(items, , aggCol, pivotCol);
			// &&tm.setRowData(items);
			tm.sort();
		});
	}, [tm, berichteUiiStore.selectedAgt, agenturListUiStore.filterHasChanged, agenturListUiStore, agtFitnessUiStore.items, agtFitnessUiStore]);


	return (
		<>
			{!hasItems ? (
				<div className="pad-1rem">Keine Daten gefunden</div>
			) : (
				<>
					<Table stickyHeader={true} tm={tm}></Table>
				</>
			)}
			<ul>
				<li>isAkumaPrimeUser: Definition für AzD (war in letzter zeit mal mitglied bei Akuma Prime)</li>
				<li>isCurrentAkumaPrimeUser: ist in der letzten Datenlieferung Akuma Prime mitglied</li>
				<li>isCurrentAkumaPrimeUserGpCount: anzahl teilhaber pro agentur mit isCurrentAkumaPrimeUser</li>
				<li>has12WeeksOfPlanerInCurrentYear: agentur die Im aktuellen jahr mindestens 12 wochen mit mindestens 50 quote</li>
				<li>akumaPlanerGpCount: has12WeeksOfPlanerInCurrentYear mit anzahl telihaber</li>
			</ul>
		</>
	);
});

import { BaseCachedEntityStore } from './core/base.cached.entity.store';
import FileSaver from 'file-saver';
import { makeObservable, observable } from 'mobx';

// agtId: 979211200
// bnrId: 7022182
// created: 1551701005482
// documentId: "64e7c3c6-6394-4fd3-8992-d2732c29a4d4"
// editable: true
// fileSize: 810095
// filetype: "image/jpeg"
// id: "825b8129-efe2-4491-912f-f63d05c40fd2"
// planId: "8b68fd93-5593-446e-84d6-c46a4ea5809e"
// sharedWith: []
// title: "Bohm.jpg"
// type: "plan"

export class BnrUploadModel {
	constructor(opts: any) {
		makeObservable(this);
		this.id = opts.id;
		this.documentId = opts.documentId;
		this.bnrId = opts.bnrId;
		this.agtId = opts.agtId;
		this.planId = opts.planId;
		this.created = opts.created;

		this.editable = opts.editable;
		this.fileSize = opts.fileSize;
		this.fileType = opts.filetype;
		this.sharedWidth = opts.sharedWidth;
		this.sharedWidthSteart = opts.sharedWidthSteart;
		this.title = opts.title;
		this.type = opts.type;
	}
	@observable
	id: string;
	@observable
	documentId: string;
	bnrId: number;
	agtId: number;
	planId: string;
	created: Date;
	editable: boolean;
	fileSize: number;
	fileType: string;
	sharedWidth: [];
	sharedWidthSteart: [];
	title: string;

	// not sure what this is used for:
	// current values in db:
	// <null>
	// plan'
	// plan%00
	// user_notiz
	// plan

	type: string;
}

export interface IUploadOptions {
	agtId?: number;
	planId?: string;
	dokId?: string;
	type?: string;
}

export class BnrUploadStore extends BaseCachedEntityStore<BnrUploadModel> {
	apiPath = 'api/uploads';
	idProp: string = 'id';
	disableFindForStearts = [123];

	createInstances(opts: any): BnrUploadModel {
		return new BnrUploadModel(opts);
	}

	async downloadFile(uploadId: string, fileName: string, type: string, returnBlob?: boolean) {
		let url = this.apiPath + '/' + uploadId + '/download?c=' + new Date().getTime();
		this.session.incApiCallCount();

		await this.callRefreshTokenIfNecessary();
		const header = this.getAuthHeader();

		const res = await this.axios({
			headers: header.headers,
			url,
			method: 'GET',
			responseType: 'blob', // Important
		});
		var blob = new Blob([res.data], { type });
		this.session.decApiCallCount();

		if (returnBlob === true) {
			return blob;
		}

		FileSaver.saveAs(blob, fileName);
	}

	async downloadFitnessTrackerPdf(agtId: number, returnBlob?: boolean) {
		const fileName = 'fitness-' + agtId + '.pdf';
		const type = 'application/pdf';
		let url = this.apiPath + '/fitnesstracker/' + agtId + '?c=' + new Date().getTime();
		this.session.incApiCallCount();

		await this.callRefreshTokenIfNecessary();
		const header = this.getAuthHeader();

		const res = await this.axios({
			headers: header.headers,
			url,
			method: 'GET',
			responseType: 'blob', // Important
		});
		var blob = new Blob([res.data], { type });
		this.session.decApiCallCount();

		if (returnBlob === true) {
			return blob;
		}

		FileSaver.saveAs(blob, fileName);
	}

	async downloadFitnessPlanPdf(agtId: number, returnBlob?: boolean) {
		const fileName = 'fitnessplan-' + agtId + '.pdf';
		const type = 'application/pdf';
		let url = this.apiPath + '/fitnessplan/' + agtId + '?c=' + new Date().getTime();
		this.session.incApiCallCount();

		await this.callRefreshTokenIfNecessary();
		const header = this.getAuthHeader();

		const res = await this.axios({
			headers: header.headers,
			url,
			method: 'GET',
			responseType: 'blob', // Important
		});
		var blob = new Blob([res.data], { type });
		this.session.decApiCallCount();

		if (returnBlob === true) {
			return blob;
		}

		FileSaver.saveAs(blob, fileName);
	}

	async uploadFile(file: any, opts: IUploadOptions, onProgres: (p: number) => void) {
		let url = this.apiPath;
		await this.callRefreshTokenIfNecessary();

		let data = new FormData();
		data.append('file', file);
		if (opts.agtId) {
			data.append('agtId', opts.agtId.toString());
		}
		if (opts.planId) {
			data.append('planId', opts.planId.toString());
		}
		if (opts.dokId) {
			data.append('dokId', opts.dokId.toString());
		}
		if (opts.type) {
			data.append('type', opts.type.toString());
		}
		const header = this.getAuthHeader();
		header.headers['Content-Type'] = 'multipart/form-data';

		const res = await this.axios({
			onUploadProgress: (evt: any) => {
				onProgres(evt.loaded);
			},
			headers: header.headers,
			url,
			method: 'POST',
			data: data,
		});
		return new BnrUploadModel(res.data);
	}
}

import { useStore } from 'app/context';
import { Field, Form, Formik } from 'formik';
import { observer } from 'mobx-react';
import * as Yup from 'yup';
import FormField from 'app/components/form/FormField';
import { Button } from 'app/components/common/Button';
import { TemplateSteart, TemplateVM } from 'app/stores/ui/template.ui.store';
import { useHistory } from 'react-router';
import routes from 'routes';
import { TextEditorField } from 'app/components/form/TextEditorField';
import { UserStearts } from 'app/models/core/steart.model';
import { runInAction } from 'mobx';
import _ from 'lodash';

const yupStearts = Yup.object({
	VBL: Yup.boolean().default(false),
	LPV: Yup.boolean().default(false),

	LSV: Yup.boolean().default(false),
	LBSG: Yup.boolean().default(false),
	VAS: Yup.boolean().default(false),
	KVS: Yup.boolean().default(false),
	KME: Yup.boolean().default(false),
	BBS: Yup.boolean().default(false),
	UB: Yup.boolean().default(false),
});

const adminTemplateEditSchema = Yup.object({
	title: Yup.string().required('Bezeichnung ist erfoderlich').default(''),
	category: Yup.string().required().trim(),
	text: Yup.string().required().trim(),
	visible: Yup.boolean().default(false),
	stearts: yupStearts,
}).required();

type AdminTemplate = Yup.InferType<typeof adminTemplateEditSchema>;

interface IAdminTemplateEditForm {
	template: TemplateVM;
}

export const AdminTemplateEditForm = observer((props: IAdminTemplateEditForm) => {
	const { adminTemplatesUiStore } = useStore();
	const history = useHistory();
	const template = props.template;

	const handleSubmit = async (values: AdminTemplate) => {
		const t = template.template;
		t.content.title = values.title;
		t.content.text = values.text!;
		t.content.visible = values.visible;
		t.content.category = values.category;
		const keys = Object.keys(values.stearts);
		const stearts: number[] = [];
		keys.forEach((key) => {
			const val = _.get(values.stearts, key);
			if (val === true) {
				const steart = UserStearts.find((us) => us.name === key);
				stearts.push(steart!.id);
			}
		});
		t.stearts = stearts;
		await adminTemplatesUiStore.save(template);
		onCancel();
	};

	const onCancel = () => {
		history.push(routes.ADMINTEMPLATES.getPath());
		runInAction(() => {
			adminTemplatesUiStore.current = undefined;
			adminTemplatesUiStore.newTemplate = undefined;
		});
	};

	const getInitStearts = () => {
		return {
			VBL: !!template.stearts.find((s: TemplateSteart) => s.steart.steart === 934 && s.active),
			LPV: !!template.stearts.find((s: TemplateSteart) => s.steart.steart === 755 && s.active),
			LBSG: !!template.stearts.find((s: TemplateSteart) => s.steart.steart === 974 && s.active),
			LSV: !!template.stearts.find((s: TemplateSteart) => s.steart.steart === 756 && s.active),
			VAS: !!template.stearts.find((s: TemplateSteart) => s.steart.steart === 973 && s.active),
			KVS: !!template.stearts.find((s: TemplateSteart) => s.steart.steart === 968 && s.active),
			KME: !!template.stearts.find((s: TemplateSteart) => s.steart.steart === 959 && s.active),
			BBS: !!template.stearts.find((s: TemplateSteart) => s.steart.steart === 994 && s.active),
			UB: !!template.stearts.find((s: TemplateSteart) => s.steart.steart === 951 && s.active),
		};
	};

	return (
		<>
			<Formik
				validationSchema={adminTemplateEditSchema}
				initialValues={{
					title: template.title,
					text: template.template.content.text,
					category: template.template.content.category,
					visible: template.template.content.visible,
					stearts: getInitStearts(),
				}}
				onSubmit={handleSubmit}
			>
				{({ errors, touched, isValid, values }) => {
					return (
						<Form>
							<div className="pad-1rem">
								<FormField className="is-inline" error={errors.title} touched={touched.title} label="title">
									<Field type="text" name="title" placeholder="Bezeichnung" />
								</FormField>
								<FormField className="is-inline" error={errors.category} touched={touched.category} label="Kategorie">
									{template.isNew && (
										<div>
											<Field type="text" name="category" placeholder="Kategorie" />
											<div className="pad-top-1rem pad-bottom-1rem">
												Die Kategorie kann später zum Filtern der Dokumente verwendet werden. Dieser Wert sollte nachträglich nur in Absprache mit den
												Entwickler geändert werden.
											</div>
										</div>
									)}
									{template.isNew && <>{template.template.content.category} </>}
								</FormField>
								<FormField className="is-inline" error={errors.text} touched={touched.text} label="Text">
									<TextEditorField name="text" />
								</FormField>
								<FormField className="is-inline pad-top-1rem" label="Sichtbar / Aktiv">
									<Field type="checkbox" name="visible" />
								</FormField>

								<div className="form-control is-inline align-items-start pad-top-1rem">
									<label>Für Rollen</label>
									<div className="grid">
										{UserStearts.map((ts) => (
											<div key={ts.name}>
												<label>
													<Field type="checkbox" name={'stearts.' + ts!.name} /> {ts.name}
												</label>
											</div>
										))}
									</div>
								</div>
							</div>

							<div className="border-top">
								<div className="pad-1rem is-right">
									<Button type="submit" className="button is-primary" disabled={!isValid}>
										Speichern
									</Button>
									<Button type="button" className="button" onClick={onCancel}>
										Abbrechen
									</Button>
								</div>
							</div>
						</Form>
					);
				}}
			</Formik>
		</>
	);
});

import _ from 'lodash';
import { action, computed, IObservableArray, makeObservable, observable, runInAction, when } from 'mobx';
import moment from 'moment';
import { AdminImportsStore, AupFile, ImportDataType, ImportJobModel, ImportJobStatus } from '../admin.imports.store';
import { DatenStandModel, AdminDatenStandStore } from '../datenstand.store';
import { notify } from 'app/components/common/notify';

class JobFilter {
	constructor() {
		makeObservable(this);
	}

	@observable
	status?: ImportDataType;

	@action
	reset() {
		this.status = undefined;
	}
}

export class ImportJobVM {
	constructor(job: ImportJobModel) {
		this.job = job;
	}
	job: ImportJobModel;
	datenStand?: DatenStandModel;

	@computed
	get KW() {
		if (this.job.meta && this.job.meta.kw) {
			return this.job.meta.kw;
		}
		// if ((this.job.dataType === ImportDataType.aup || this.job.dataType === ImportDataType.crawler || this.job.dataType === ImportDataType.multisgp) && this.job.meta) {
		// 	return this.job.meta.kw;
		// }
		return;
	}


	@computed
	get kwFormated() {
		if (!this.KW) {
			return;
		}
		if ((this.job.dataType === ImportDataType.aup || this.job.dataType === ImportDataType.crawler) && this.job.meta) {
			return this.KW + ' (KW)';
		}
		return this.KW + ' (BM)';
	}

	@computed
	get fehler() {
		if (this.job.message) {
			return JSON.stringify(this.job.message, null, 2);
		}
		if (this.job.hasError2) {

			return this.job.message;
		}
	}
}

export class AdminImportsUiStore {
	adminImportsStore: AdminImportsStore;
	datenstandStore: AdminDatenStandStore;

	constructor(adminImportsStore: AdminImportsStore, datenstandStore: AdminDatenStandStore) {
		makeObservable(this);
		this.adminImportsStore = adminImportsStore;
		this.datenstandStore = datenstandStore;

		when(
			() => this.autoRefreshEnabled === true,
			async () => {
				do {
					await new Promise((resolve) => {
						setTimeout(() => {
							resolve(undefined);
						}, 2500);
					});
					console.log('refreshing');
					await this.reload();

					if (this.current) {
						runInAction(() => {
							this.current = this._items.find((i) => i.job.id === this.current!.job.id);
						});
					}
				} while (this.autoRefreshEnabled);
				return this.autoRefreshEnabled;
			},
		);
	}

	@observable current?: ImportJobVM;

	@observable
	currentFilter: JobFilter = new JobFilter();

	@computed
	get currentItems() {
		let items = this._items.map((i) => i);
		const f = this.currentFilter;
		if (!f) {
			return items;
		}

		return _.sortBy(items, (r) => r.job.created).reverse();
	}

	_items: IObservableArray<ImportJobVM> = observable([]);
	loaded: boolean = false;
	async load() {
		if (this.loaded) {
			return this._items;
		}

		const items = await this.adminImportsStore.findAll();
		const datenStaende = await this.datenstandStore.findAll();

		let res: ImportJobVM[] = [];

		items.forEach((t) => {
			const vm = new ImportJobVM(t);
			res.push(vm);
		});

		res = _.sortBy(res, i => i.job.created).reverse();


		datenStaende.forEach(d => {
			let dataType = d.dataType;
			if (dataType === 'aup_gp') {
				dataType = 'aup';
				const j = res.find(i => i.job.dataType === dataType);
				if (j) {
					j.datenStand = d;
				}

			} else {
				const j = res.find(i => i.job.dataType === dataType && i.job.meta.fileName === d.file);
				if (j) {
					j.datenStand = d;
				}
			}

		})


		runInAction(() => {
			this._items.replace(res);
			this.loaded = true;
		});

		return this._items;
	}

	@action
	async loadById(id?: string) {
		if (!id) {
			this.current = undefined;
		}
		const jobs = await this.findAll();
		const u = jobs.find((u) => u.job.id === id);
		runInAction(() => {
			this.current = u;
			this.newJob = undefined;
		});
	}

	@action
	newAupVorjahrJob() {
		const job = new ImportJobModel({
			created: new Date(),
			dataType: ImportDataType.aupvorjahr,
			duration: -1,
			status: ImportJobStatus.new,
		});
		job.meta = {
			kw: moment().format('YYYYww'),
		};
		this.current = undefined;
		this.newJob = new ImportJobVM(job);
	}
	@action
	newAupJob() {
		const job = new ImportJobModel({
			created: new Date(),
			dataType: ImportDataType.aup,
			duration: -1,
			status: ImportJobStatus.new,
		});
		job.meta = {
			kw: moment().format('YYYYww'),
		};
		this.current = undefined;
		this.newJob = new ImportJobVM(job);
	}
	@action
	newCrawlerJob() {
		const job = new ImportJobModel({
			created: new Date(),
			dataType: ImportDataType.crawler,
			duration: -1,
			status: ImportJobStatus.new,
		});
		job.meta = {
			kw: moment().format('YYYYww'),
		};
		this.current = undefined;
		this.newJob = new ImportJobVM(job);
	}

	@action
	newAladinJob() {
		const job = new ImportJobModel({
			created: new Date(),
			dataType: ImportDataType.aladin,
			duration: -1,
			status: ImportJobStatus.new,
		});
		this.current = undefined;
		this.newJob = new ImportJobVM(job);
	}
	@action
	newAladin2Job() {
		const job = new ImportJobModel({
			created: new Date(),
			dataType: ImportDataType.aladin2,
			duration: -1,
			status: ImportJobStatus.new,
		});
		this.current = undefined;
		this.newJob = new ImportJobVM(job);
	}
	@action
	adminsNowJob() {
		const job = new ImportJobModel({
			created: new Date(),
			dataType: ImportDataType.amisnow,
			duration: -1,
			status: ImportJobStatus.new,
		});
		this.current = undefined;
		this.newJob = new ImportJobVM(job);
	}

	newLeadnowJob() {
		const job = new ImportJobModel({
			created: new Date(),
			dataType: ImportDataType.leadnow,
			duration: -1,
			status: ImportJobStatus.new,
		});
		this.current = undefined;
		this.newJob = new ImportJobVM(job);
	}

	@action
	newAgenturClusterJob() {
		const job = new ImportJobModel({
			created: new Date(),
			dataType: ImportDataType.agenturcluster,
			duration: -1,
			status: ImportJobStatus.new,
		});
		this.current = undefined;
		this.newJob = new ImportJobVM(job);
	}

	@action
	newAgenturMultisAgenturJob() {
		const job = new ImportJobModel({
			created: new Date(),
			dataType: ImportDataType.multisagentur,
			duration: -1,
			status: ImportJobStatus.new,
		});
		this.current = undefined;
		this.newJob = new ImportJobVM(job);
	}

	@action
	newAgenturMultisGPJob() {
		const job = new ImportJobModel({
			created: new Date(),
			dataType: ImportDataType.multisgp,
			duration: -1,
			status: ImportJobStatus.new,
		});
		this.current = undefined;
		this.newJob = new ImportJobVM(job);
	}

	@action
	newGoogleProfileJob() {
		const job = new ImportJobModel({
			created: new Date(),
			dataType: ImportDataType.googleprofile,
			duration: -1,
			status: ImportJobStatus.new,
		});
		this.current = undefined;
		this.newJob = new ImportJobVM(job);
	}
	@action
	newFacebookJob() {
		const job = new ImportJobModel({
			created: new Date(),
			dataType: ImportDataType.facebook,
			duration: -1,
			status: ImportJobStatus.new,
		});
		this.current = undefined;
		this.newJob = new ImportJobVM(job);
	}
	@action
	newInstagramJob() {
		const job = new ImportJobModel({
			created: new Date(),
			dataType: ImportDataType.instagram,
			duration: -1,
			status: ImportJobStatus.new,
		});
		this.current = undefined;
		this.newJob = new ImportJobVM(job);
	}
	@action
	newKvmJob() {
		const job = new ImportJobModel({
			created: new Date(),
			dataType: ImportDataType.kvm,
			duration: -1,
			status: ImportJobStatus.new,
		});
		this.current = undefined;
		this.newJob = new ImportJobVM(job);
	}

	@action
	newStsJob() {
		const job = new ImportJobModel({
			created: new Date(),
			dataType: ImportDataType.sts,
			duration: -1,
			status: ImportJobStatus.new,
		});
		this.current = undefined;
		this.newJob = new ImportJobVM(job);
	}

	@action
	newSts2024Job() {
		const job = new ImportJobModel({
			created: new Date(),
			dataType: ImportDataType.sts2024,
			duration: -1,
			status: ImportJobStatus.new,
		});
		this.current = undefined;
		this.newJob = new ImportJobVM(job);
	}

	@action
	newAkumaPrime() {
		const job = new ImportJobModel({
			created: new Date(),
			dataType: ImportDataType.akumaprime,
			duration: -1,
			status: ImportJobStatus.new,
		});
		this.current = undefined;
		this.newJob = new ImportJobVM(job);
	}

	@action
	newAkumaPlaner() {
		const job = new ImportJobModel({
			created: new Date(),
			dataType: ImportDataType.akumaplaner,
			duration: -1,
			status: ImportJobStatus.new,
		});
		this.current = undefined;
		this.newJob = new ImportJobVM(job);
	}

	@action
	newDigitalreport() {
		const job = new ImportJobModel({
			created: new Date(),
			dataType: ImportDataType.digitalreport,
			duration: -1,
			status: ImportJobStatus.new,
		});
		this.current = undefined;
		this.newJob = new ImportJobVM(job);
	}

	@observable newJob?: ImportJobVM;

	@action
	async reload() {
		this.loaded = false;
		await this.load();
	}

	async getAupKwFiles(kw: string, lastChange?: Date) {
		let res = await this.adminImportsStore.getAupKwFiles(kw);
		if (res) {
			res = res.filter((f: AupFile) => f.fileName.toLowerCase().endsWith('.csv'));
		}
		return res;
	}

	async getAupVorjahrFiles(vorjahr: string, lastChange?: Date) {
		const res = await this.adminImportsStore.getAupVorjahrFiles(vorjahr);
		return res;
	}

	async getFilesByType(t: ImportDataType) {
		switch (t) {
			case ImportDataType.multisgp:
				return await this.adminImportsStore.getMultisGPFiles();
			case ImportDataType.googleprofile:
				return await this.adminImportsStore.getGoogleProfileFiles();
			case ImportDataType.facebook:
				return await this.adminImportsStore.getFacebookFiles();
			case ImportDataType.instagram:
				return await this.adminImportsStore.getInstagramFiles();
			case ImportDataType.leadnow:
				return await this.adminImportsStore.getLeadNowFiles();
			case ImportDataType.aladin:
				return await this.adminImportsStore.getAladinFiles();
			case ImportDataType.amisnow:
				return await this.adminImportsStore.getAmisNowFiles();
			case ImportDataType.aladin2:
				return await this.adminImportsStore.getAladin2Files();
			case ImportDataType.agenturcluster:
				return await this.adminImportsStore.getAgenturClusterFiles();
			case ImportDataType.digitalreport:
				return await this.adminImportsStore.getDigitalreportFiles();
			case ImportDataType.akumaprime:
				return await this.adminImportsStore.getAkumaPrimeFiles();
			case ImportDataType.akumaplaner:
				return await this.adminImportsStore.getAkumaPlanerFiles();
			case ImportDataType.multisagentur:
				return await this.adminImportsStore.getMultisAgenturFiles();
			case ImportDataType.kvm:
				return await this.adminImportsStore.getKvmFiles();
			case ImportDataType.sts:
				return await this.adminImportsStore.getStsFiles();
			case ImportDataType.sts2024:
				return await this.adminImportsStore.getSts2024Files();

			default:
				throw 'no getFilesByType defined for ' + t;
		}
	}

	async triggerJob(job: ImportJobModel) {
		notify('Job wird gestartet', '', 'info');
		const res = await this.adminImportsStore.triggerImportJob(job);
		return new Promise((resolve) => {
			setTimeout(() => {
				this.reload().then(() => {
					resolve(res);
				});
			}, 1000);
		});
	}

	async crawlOne(bensl: string, kw: string, forceSend: string) {
		const res = await this.adminImportsStore.crawlOne(bensl, kw, forceSend);
		return res;
	}

	@observable
	autoRefreshEnabled: boolean = false;

	@action
	toggleAutoRefresh() {
		this.autoRefreshEnabled = !this.autoRefreshEnabled;
	}

	async findAll() {
		return this.load();
	}
}

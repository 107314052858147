import { Table } from 'app/components/table/table';
import { AggregrationType, ITableColumn, TableModel, TableRow } from 'app/components/table/table.model';
import { useStore } from 'app/context';
import { UserLoginVM } from 'app/stores/ui/admin.user.login.ui.store';
import { formatDateLong } from 'app/utils';
import { observer } from 'mobx-react';
import { useEffect } from 'react';

export const AdminUserLoginTrackingList = observer(() => {
	const { adminUserLoginUiStore } = useStore();

	const tm = new TableModel<UserLoginVM, number>();
	let cols: ITableColumn<UserLoginVM, number>[] = [
		{
			label: 'bensl',
			path: 'track.bensl',
		},
		{
			label: 'Datum',
			path: 'track.created',
			render: (row: TableRow<UserLoginVM>) => {
				return <>{formatDateLong(row.data.track.created)} </>;
			},
		},
		{
			label: 'Action',
			path: 'track.action',
		},
		{
			label: 'Steart',
			path: 'user.user.steartText',
		},
		{
			label: 'Name',
			path: 'user.user.name',
		},
	];

	tm.setCols(cols);
	tm.sortBy = 'bnr.bnrId';
	tm.sortAsc = false;
	tm.idProperty = 'bnr.bnrId';

	useEffect(() => {
		adminUserLoginUiStore.load();
	}, [adminUserLoginUiStore]);

	useEffect(() => {
		if (adminUserLoginUiStore.loaded) {
			tm.setRowData(adminUserLoginUiStore._items);
		}
	}, [adminUserLoginUiStore.loaded, adminUserLoginUiStore._items]);

	return (
		<>
			<div>Anzahl Zeilen: {adminUserLoginUiStore._items.length}</div>
			<Table tm={tm} stickyFooter={true} />
		</>
	);
});

let baseCols = [
	{
		label: 'Logins',
		path: 'track.loginCount',
	},
	{
		label: 'Login Fehler',
		path: 'track.loginFailCount',
	},
	{
		label: 'Passwort vergessen',
		path: 'track.forgotPassword',
	},
	{
		label: 'Fitness Pdf',
		path: 'track.fitnessPdf',
	},
	{
		label: 'Fitness Pdf Not Found',
		path: 'track.fitnessPdfNotFound',
	},
	{
		label: 'Pdfs',
		path: 'track.pdfCount',
	},
	{
		label: 'Pdf Fehler',
		path: 'track.pdfErrorCount',
	},
	{
		label: 'Send',
		path: 'track.sendCount',
	},
	{
		label: 'Send  Doc',
		path: 'track.sendDocCount',
	},
	{
		label: 'Send Task',
		path: 'track.sendTaskCount',
	},
	{
		label: 'Send Multi',
		path: 'track.sendMultiCount',
	},
];

baseCols = baseCols.map((c) => {
	// @ts-ignore
	c.agg = [
		{
			aggType: AggregrationType.sum,
		},
	];
	return c;
});

export const Info = () => {
	return (
		<>
			<ul>
				<li>Tracking Fitness Pdfs ab 25.2.2025</li>
				<li>Tracking Pdf Generierung ab 19.1.2025. Vollständig ab 28.2.2025</li>
				<li>Tracking Send ab 6.3.2025</li>
			</ul>
		</>
	);
};

export const AdminUserLoginTrackingByYmd = observer(() => {
	const { adminUserLoginUiStore } = useStore();

	const tm = new TableModel<UserLoginVM, number>();
	let cols: ITableColumn<UserLoginVM, number>[] = [
		{
			label: 'Datum',
			path: 'track.ymdforsort',
		},
		...baseCols,
	];

	tm.setCols(cols);
	tm.sortBy = 'track.ymdforsort';
	tm.sortAsc = false;
	tm.idProperty = 'track.ymdforsort';

	useEffect(() => {
		adminUserLoginUiStore.load();
	}, [adminUserLoginUiStore]);

	useEffect(() => {
		if (adminUserLoginUiStore.loaded) {
			const filtered = adminUserLoginUiStore._items.filter((x) => x.user);
			tm.setGroupByData(filtered, (x) => x.track.ymd);
		}
	}, [adminUserLoginUiStore.loaded, adminUserLoginUiStore._items]);

	return (
		<>
			<div>Anzahl Zeilen: {adminUserLoginUiStore._items.length}</div>
			<Table tm={tm} stickyFooter={true} />
		</>
	);
});

export const AdminUserLoginTrackingByYm = observer(() => {
	const { adminUserLoginUiStore } = useStore();

	const tm = new TableModel<UserLoginVM, number>();
	let cols: ITableColumn<UserLoginVM, number>[] = [
		{
			label: 'Datum',
			path: 'track.ymforsort',
		},
		...baseCols,
	];

	tm.setCols(cols);
	tm.sortBy = 'track.ymforsort';
	tm.sortAsc = false;
	tm.idProperty = 'track.ymforsort';

	useEffect(() => {
		adminUserLoginUiStore.load().then(() => {
			const filtered = adminUserLoginUiStore._items; // && .filter(x => x.user)
			tm.setGroupByData(filtered, (x) => x.track.ym);
		});
	}, [adminUserLoginUiStore, adminUserLoginUiStore.loaded, adminUserLoginUiStore._items]);

	return (
		<>
			<div>Axxnzahl Zeilen: {adminUserLoginUiStore._items.length}</div>
			<Table tm={tm} stickyFooter={true} />
		</>
	);
});

interface IRowDef {
	rowCol: ITableColumn<UserLoginVM, number>;
	rows: (i: UserLoginVM) => any;
}

interface IPivotDef {
	pivotCol: ITableColumn<UserLoginVM, number>;
	drilldown?: IDrillDown;
}

interface IDrillDown {
	filter: (i: UserLoginVM) => any;
	drillCol: ITableColumn<UserLoginVM, number>;
}

interface ITrackingPivotProps {
	aggCol: ITableColumn<UserLoginVM, number>;
	pivotDef: IPivotDef;
	rowDef: IRowDef;
}

export const AdminTrackingPivot = observer((props: ITrackingPivotProps) => {
	const { adminUserLoginUiStore } = useStore();

	const tm = new TableModel<UserLoginVM, number>();
	let cols: ITableColumn<UserLoginVM, number>[] = [props.rowDef.rowCol];

	tm.setCols(cols);
	tm.sortBy = props.rowDef.rowCol.path as string;
	tm.sortAsc = false;
	tm.idProperty = props.rowDef.rowCol.path as string;
	useEffect(() => {
		adminUserLoginUiStore.load();
	}, [adminUserLoginUiStore]);

	useEffect(() => {
		if (adminUserLoginUiStore.loaded) {
			const filtered = adminUserLoginUiStore._items.filter((x) => x.user);
			tm.setPivotData(filtered, props.rowDef.rows, props.aggCol, props.pivotDef.pivotCol);
		}
	}, [adminUserLoginUiStore.loaded, adminUserLoginUiStore._items, props]);

	return (
		<>
			<div>Anzahl Zeilen: {adminUserLoginUiStore._items.length}</div>
			<Table tm={tm} stickyFooter={true} />
		</>
	);
});

export const AdminUserLoginTrackingPivotYm = observer(() => {
	const { adminUserLoginUiStore } = useStore();

	const tm = new TableModel<UserLoginVM, number>();
	let cols: ITableColumn<UserLoginVM, number>[] = [
		{
			label: 'Datum',
			path: 'track.ymforsort',
		},
	];

	tm.setCols(cols);
	tm.sortBy = 'track.ymforsort';
	tm.sortAsc = false;
	tm.idProperty = 'track.ymforsort';

	const aggCol = {
		label: 'Logins',
		path: 'track.loginCount',
		agg: [
			{
				aggType: AggregrationType.sum,
			},
		],
	};

	const pivotCol = {
		label: 'VD',
		path: 'user.user.firstVd',
	};

	useEffect(() => {}, [adminUserLoginUiStore]);

	useEffect(() => {
		adminUserLoginUiStore.load().then(() => {
			if (adminUserLoginUiStore.loaded) {
				const filtered = adminUserLoginUiStore._items.filter((x) => x.user);
				tm.setPivotData(filtered, (x) => x.track.ym, aggCol, pivotCol);
			}
		});
	}, [adminUserLoginUiStore, adminUserLoginUiStore.loaded, adminUserLoginUiStore._items]);

	return (
		<>
			<div>Anzahl Zeilen1: {adminUserLoginUiStore._items.length}</div>;
			<Table tm={tm} stickyFooter={true} />
			<Info></Info>
		</>
	);
});

import { AgenturNameCell } from 'app/components/shared/AgenturName.cell';
import { Table } from 'app/components/table/table';
import { AggregrationType, ITableColumn, TableModel } from 'app/components/table/table.model';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
// import { simpleFitCell } from './FitnessTrackerListe';
import { AgtSocialMediaVM } from 'app/stores/ui/agt.socialmedia.ui.store';

export const SociaMediaList = observer(() => {
	const { berichteUiiStore, agtSocialMediaUiStore, agenturListUiStore } = useStore();
	const [hasItems, setHasItems] = useState(false);

	const tm = new TableModel<AgtSocialMediaVM, number>();
	const cols: ITableColumn<AgtSocialMediaVM, number>[] = [];

	cols.push({
		label: 'Agentur',
		path: 'agt.name',
		sortBy: 'agt.name',
		render: AgenturNameCell,
	});

	cols.push({
		label: 'FF VJ',
		path: `prevFF`,
		sortBy: `prevFF`,
	});
	cols.push({
		label: 'FF VJ >= 250',
		path: `prevFFGreater250`,
		sortBy: `prevFFGreater250`,
		agg: [
			{
				aggType: AggregrationType.sum,
			},
		],
	});

	cols.push({
		label: 'FF Akt',
		path: `currFF`,
		sortBy: `currFF`,
	});
	cols.push({
		label: 'FF Akt >= 250',
		path: `currFFGreater250`,
		sortBy: `currFFGreater250`,
		agg: [
			{
				aggType: AggregrationType.sum,
			},
		],
	});

	tm.sortBy = 'agt.name';
	tm.setCols(cols);

	tm.idProperty = 'agtId';
	tm.idType = 'number';

	useEffect(() => {
		agtSocialMediaUiStore.load().then(() => {
			let items = agtSocialMediaUiStore.items;
			if (berichteUiiStore.selectedAgt) {
				const agtId = berichteUiiStore.selectedAgt.agtId;
				items = items.filter((i) => i.agt.agtId === agtId);
			} else {
				items = agenturListUiStore.applyFilterOnAgt(items) as AgtSocialMediaVM[];
			}
			setHasItems(items.length > 0);
			tm.setRowData(items);
			tm.sort();
		});
	}, [tm, berichteUiiStore.selectedAgt, agenturListUiStore.filterHasChanged, agenturListUiStore, agtSocialMediaUiStore.items, agtSocialMediaUiStore]);

	return (
		<>
			{!hasItems ? (
				<div className="pad-1rem">Keine Daten gefunden</div>
			) : (
				<>
					<Table stickyHeader={true} stickyFooter={true} tm={tm}></Table>
				</>
			)}
		</>
	);
});

import { Icon } from 'app/components/common/Icon';
import { TaskThemaSelectField } from 'app/components/tasks/TaskThemaSelectField';
import { useStore } from 'app/context';
import { AgtPlanModel, AgtPlanThemaModel } from 'app/stores/agt.plan.store';
import { TaskVM } from 'app/stores/ui/task.ui.store';
import { Field, Form, Formik } from 'formik';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment';
import * as Yup from 'yup';
import { Button } from '../common/Button';
import AgtSelectField from '../form/AgtSelectField';
import FormField from '../form/FormField';
import { FormikDateComponent } from '../form/FormikDateComponent';
import { PlanSelectField } from '../form/PlanSelectField';
import { TextEditorField } from '../form/TextEditorField';
import { TaskStatusField } from './TaskStatusField';
import { HtmlContent } from '../common/HtmlContent';

interface ITaskViewer {
	task: TaskVM;
	showAgt: boolean;
	showPlan: boolean;
	printMode?: boolean;
}

const taskFormSchema = Yup.object({
	text: Yup.string().default(''),
	dueDate: Yup.string(),
	themaKey: Yup.string(),
	status: Yup.string().required(),
	body: Yup.string().default(''),
	ohneDatum: Yup.boolean().default(false),
	agtId: Yup.number().positive('Bitte eine Agentur auswählen').default(0),
	planYear: Yup.number(),
}).required();

export type TaskForm = Yup.InferType<typeof taskFormSchema>;

export const TaskViewer = observer((props: ITaskViewer) => {
	const { uiStore, taskUiStore, agenturUiStore, agenturStore, agtPlanStore } = useStore();
	const { task, printMode } = props;

	const handleSubmit = async (values: TaskForm) => {
		const t = task.task;
		const agt = await agenturStore.findById(values.agtId);
		let plan: AgtPlanModel | undefined;
		if (values.agtId && values.planYear) {
			const py = parseInt(values.planYear.toString());
			if (py > 0) {
				plan = await agtPlanStore.findByAgtIdYear(values.agtId, py);
			}
		}
		runInAction(() => {
			task.agt = agt;
			t.agtId = values.agtId;
			t.text = values.text;
			if (plan) {
				t.planId = plan.id;
				t.planYear = plan.year;
				task.plan = plan;
			}
			if (!values.ohneDatum && values.dueDate) {
				t.dueDate = moment(values.dueDate).toDate();
			} else {
				t.dueDate = new Date(0);
			}
			t.body = values.body;

			t.status = parseInt(values.status);

			if (task.plan && !task.isNew) {
				t.thema = task.plan.themen.themen.find((t) => t.key === values.themaKey);

				if (!t.thema && values.themaKey) {
					// happens when the a thema has been removed
					t.thema = new AgtPlanThemaModel({
						key: values.themaKey,
						title: values.themaKey,
					});
				}
			}
			taskUiStore.save(task);
		});
		uiStore.hideModal(uiStore.modalIds.taskViewModal);
	};

	const previous = () => {
		taskUiStore.setPreviousModalTask();
	};

	const next = () => {
		taskUiStore.setNextModalTask();
	};
	const onConfirmDelete = () => {
		uiStore.showModal(uiStore.modalIds.confirmDeleteTask);
	};

	const onDownload = async () => {
		await taskUiStore.download(taskUiStore.currentModalTask!);
	};

	const onSendEmail = () => {
		uiStore.showModal(uiStore.modalIds.taskSendModal);
	};

	let agtId = task.agtId ? task.agtId : 0;
	if (agtId === 0 && !props.showAgt) {
		if (agenturUiStore.current && agenturUiStore.current.agtId) {
			agtId = agenturUiStore.current.agtId;
		}
	}

	if (printMode) {
		return (
			<div className="pad-1rem">
				<HtmlContent content={task.task.body} />
			</div>
		);
	}

	return (
		<>
			<Formik
				initialValues={{
					text: task.task.text,
					dueDate: task.task.dueDate ? moment(task.task.dueDate).format('YYYY-MM-DD') : moment(new Date()).add(7, 'days').format('YYYY-MM-DD'),
					status: task.task.status.toString(),
					body: task.task.body ? task.task.body : '',
					ohneDatum: task.task.noDueDate,
					agtId,
					planYear: task.planYear ? task.planYear : 0,
					themaKey: task.task.themaKey ? task.task.themaKey : '',
				}}
				validationSchema={taskFormSchema}
				onSubmit={handleSubmit}
			>
				{({ errors, touched, values, isValid, setFieldValue }) => {
					const onCancel = () => {
						uiStore.hideModal(uiStore.modalIds.taskViewModal);
					};

					return (
						<Form className="task-viewer-form">
							<div className="debug">{task.id}</div>
							{props.showAgt && (
								<FormField className="is-inline" error={errors.agtId} touched={touched.agtId} label="Agentur *">
									<AgtSelectField name="agtId" />
								</FormField>
							)}
							{props.showPlan && (
								<FormField className="is-inline" error={errors.planYear} touched={touched.planYear} label="Planung ">
									<PlanSelectField name="planYear" agtId={values.agtId} />
								</FormField>
							)}
							<FormField className="is-inline" error={errors.text} touched={touched.text} label="Beschreibung *">
								<Field type="text" name="text" placeholder="Beschreibung" />
							</FormField>
							{task.task.thema && (
								<FormField className="is-inline" label="Thema">
									<TaskThemaSelectField key={task.task.thema.key} name={'themaKey'} plan={props.task.plan} task={props.task} />
								</FormField>
							)}
							<FormField className="is-inline" error={errors.dueDate} touched={touched.dueDate} label="Wiedervorlage">
								<div className="task-date">
									{/* <DatePickerField name="dueDate" disabled={values.ohneDatum} /> */}
									<Field name="dueDate" disabled={values.ohneDatum} component={FormikDateComponent} />
									<Field type="checkbox" name="ohneDatum" /> <span>Ohne Datum</span>
								</div>
							</FormField>

							<FormField className="is-inline" error={errors.status} touched={touched.status} label="Status">
								<TaskStatusField name="status" />
							</FormField>

							<div className="pad-top-1rem">
								<TextEditorField name="body" />
								{errors.body && touched.body && <span className="help is-danger">{errors.body}</span>}
							</div>

							<div className="modal-footer task-viewer-actions grid is-col-half">
								<div className="is-left">
									{!taskUiStore.currentModalTask?.isNew && (
										<>
											<Button className="button is-icon" onClick={previous}>
												<Icon iconClass="chevron-left" />
											</Button>
											<Button className="button is-icon" onClick={next}>
												<Icon iconClass="chevron-right" />
											</Button>
											<Button type="button" className="button is-blank margin-right-1rem" onClick={onConfirmDelete}>
												<Icon iconClass="trash" />
												<span>LÖSCHEN</span>
											</Button>

											<Button className="button is-blank margin-right-1rem" onClick={onDownload}>
												<Icon iconClass="download" />
												<span>EXPORTIEREN</span>
											</Button>
											<Button className="button is-blank" onClick={onSendEmail}>
												<Icon iconClass="share-all" />
												<span>MIT AGENTUR TEILEN</span>
											</Button>
										</>
									)}
								</div>
								<div className="is-right">
									{errors.agtId && touched.agtId && <span className="help is-danger">{errors.agtId}</span>}
									{errors.planYear && touched.planYear && <span className="help is-danger">{errors.planYear}</span>}

									{errors.status}
									{errors.text}
									{errors.themaKey}

									<Button className="button is-primary" type="submit" disabled={!isValid}>
										Speichern
									</Button>
									<Button className="button" onClick={onCancel}>
										Abbrechen
									</Button>
								</div>
							</div>
						</Form>
					);
				}}
			</Formik>
		</>
	);
});

import { useStore } from 'app/context';
import { ApModel, ApVermittler } from 'app/models/documents/ap.model';
import { ApGoal } from 'app/stores/bnr.planungstool.store';
import { DocumentVM } from 'app/stores/ui/document.ui.store';
import { formatCurrency, formatMonth, formatPercent } from 'app/utils';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Button } from '../common/Button';
import { Icon } from '../common/Icon';
import { Modal } from '../Modal';
import { ApBestandForm } from './ApBestandForm';
import { ApGoalsTable } from './ApGoalsTable';
import { ApJahresZielForm } from './ApJahresZielForm';
import { ApPlanungszeitraumForm } from './ApPlanungszeitraumForm';
import { ApVermittlerZieleForm } from './ApVermittlerZieleForm';
import { ApZielErreichungsBalken } from './ApZielErreichungsBalken';

interface ICollapser {
	title: string;
	children: React.ReactNode;
}

export const Collapser = observer((props: ICollapser) => {
	const [open, setOpen] = useState(true);

	return (
		<>
			<div>
				<Button className="button is-collapser-button" onClick={() => setOpen(!open)}>
					<>
						{open && <Icon iconClass={'caret-down'} />}
						{!open && <Icon iconClass={'caret-right'} />}
					</>
					<span>{props.title}</span>
				</Button>
			</div>
			{open && <>{props.children}</>}
		</>
	);
});

export const ApAgenturDaten = observer((props: IAgtPlanungDoc) => {
	const { uiStore } = useStore();

	const doc = props.doc;
	const ap = props.doc.doc.content as ApModel;
	const openForm = () => {
		uiStore.showModal(uiStore.modalIds.apEditBestand);
	};

	return (
		<>
			<table className="table is-striped left-col-half">
				<tbody>
					<tr>
						<td>Provisionsmodell</td>
						<td>{doc.agt!.apProvsysId}</td>
						<td></td>
					</tr>
					<tr>
						<td>ZN</td>
						<td>{doc.agt!.vtnrBgzn}</td>
						<td></td>
					</tr>
					<tr>
						<td>VTNR</td>
						<td>{doc.agt!.agtId}</td>
						<td></td>
					</tr>
					<tr>
						<td>Sach-Gesamt Bestandsgröße in €</td>
						<td>{formatCurrency(ap.commissionCalcContent.bestand)} €</td>
						<td className="has-text-right">
							{props.printMode !== true && (
								<Button className="button is-primary is-small" onClick={openForm}>
									Bearbeiten
								</Button>
							)}
						</td>
					</tr>
				</tbody>
			</table>
			<Modal modalId={uiStore.modalIds.apEditBestand} title={'Sach-Gesamt-Bestand'}>
				<ApBestandForm {...props} />
			</Modal>
		</>
	);
});

export const ApZiele = observer((props: IAgtPlanungDoc) => {
	const { uiStore } = useStore();
	const ap = props.doc.doc.content as ApModel;

	const openBestandForm = () => {
		uiStore.showModal(uiStore.modalIds.apEditJahresZiel);
	};

	const openPlanungszeitraumForm = () => {
		uiStore.showModal(uiStore.modalIds.apEditPlanungszeitraum);
	};

	return (
		<>
			<table className="table is-striped left-col-half ">
				<tbody>
					<tr>
						<td>Jahresziel</td>
						<td></td>
						<td className="has-text-right">
							{props.printMode !== true && (
								<Button className="button is-primary is-small" onClick={openBestandForm}>
									Bearbeiten
								</Button>
							)}
						</td>
					</tr>
					<tr>
						<td>Erforderliche Gesamtbewertung in €</td>
						<td>{formatCurrency(ap.gesamtbewertung)} €</td>
						<td></td>
					</tr>
					<tr>
						<td>BAQ</td>
						<td>{formatPercent(ap.baq, 2)} %</td>
						<td></td>
					</tr>
					<tr>
						<td>
							Ziel Planungszeitraum ({formatMonth(ap.startMonth)} - {formatMonth(ap.endMonth)})
						</td>
						<td>{formatCurrency(ap.bewertungFuerPlanungszeitraum)} €</td>
						<td className="has-text-right">
							{props.printMode !== true && (
								<Button className="button is-primary is-small" onClick={openPlanungszeitraumForm}>
									Bearbeiten
								</Button>
							)}
						</td>
					</tr>
					<tr>
						<td>Akt. per. Jahresplan in €</td>
						<td>{formatCurrency(ap.jahresplan)} €</td>
						<td></td>
					</tr>
				</tbody>
			</table>
			<Modal size="large" modalId={uiStore.modalIds.apEditJahresZiel} title={'Jahresziel'}>
				<ApJahresZielForm {...props} />
			</Modal>

			<Modal size="large" modalId={uiStore.modalIds.apEditPlanungszeitraum} title={'Planungszeitraum'}>
				<ApPlanungszeitraumForm {...props} />
			</Modal>
		</>
	);
});

export const ApVermittlerList = observer((props: IAgtPlanungDoc) => {
	const { uiStore, documentUiStore } = useStore();
	const ap = props.doc.doc.content as ApModel;

	return (
		<>
			<table className="table is-striped left-col-half">
				<tbody>
					<tr>
						<td>Noch zu verteilen vor Planungszeitraum in €</td>
						<td>{formatCurrency(ap.bewertungVorPlanungszeitraumDelta)} €</td>
					</tr>
					<tr>
						<td>Noch zu verteilen für Planungszeitraum in €</td>
						<td>{formatCurrency(ap.bewertungFuerPlanungszeitraumDelta)} €</td>
					</tr>
				</tbody>
			</table>

			<table className="table is-striped left-col-half">
				<thead>
					<tr>
						<th>
							<strong>Vermittler</strong>
						</th>
						<th>
							<strong>Fehlwochen</strong>
						</th>
						<th>
							<strong>Plan in €</strong>
						</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{ap.vermittler.map((v) => (
						<ApVermittlerRow key={v.id} {...props} vermittler={v} />
					))}
				</tbody>
			</table>
			{documentUiStore.currentVermittler && (
				<Modal size="large" modalId={uiStore.modalIds.apEditVermittler} title={'Vermittlerziele: ' + documentUiStore.currentVermittler.name}>
					<ApVermittlerZieleForm {...props} vermittler={documentUiStore.currentVermittler} />
				</Modal>
			)}
		</>
	);
});

interface IApVermittlerRow extends IAgtPlanungDoc {
	vermittler: ApVermittler;
}

export const ApVermittlerRow = observer((props: IApVermittlerRow) => {
	const { uiStore, documentUiStore } = useStore();

	const vermittler = props.vermittler;
	const openForm = () => {
		documentUiStore.setCurrentVermittler(vermittler);
		uiStore.showModal(uiStore.modalIds.apEditVermittler);
	};

	return (
		<>
			<tr>
				<td>
					{vermittler.name} <small>{vermittler.label}</small>
				</td>
				<td>{vermittler.absence}</td>
				<td>{formatCurrency(vermittler.plan)} €</td>
				<td className="has-text-right">
					{props.printMode !== true && (
						<Button className="button is-primary is-small" onClick={openForm} disabled={!vermittler.isSalesPerson}>
							Bearbeiten
						</Button>
					)}
				</td>
			</tr>
		</>
	);
});

export const ApTermindurchfuehrung = observer((props: IAgtPlanungDoc) => {
	const { documentUiStore } = useStore();
	const ap = props.doc.doc.content as ApModel;
	const [goals, setGoals] = useState<ApGoal>();

	useEffect(() => {
		let isActive = true;
		documentUiStore.apCalcGoalsForAgency(ap).then((res) => {
			if (!isActive) {
				return;
			}
			setGoals(res!);
		});
		return () => {
			isActive = false;
		};
	}, [ap, documentUiStore]);

	return (
		<>
			<div>{goals && <ApGoalsTable goals={goals} />}</div>
		</>
	);
});

interface IAgtPlanungDoc {
	doc: DocumentVM;
	showAgt: boolean;
	showPlan: boolean;
	printMode?: boolean;
}

export const DocumentAp = observer((props: IAgtPlanungDoc) => {
	const { documentUiStore, uiStore } = useStore();
	const ap = props.doc.doc.content as ApModel;

	const modalId = uiStore.modalIds.apEdit;
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		documentUiStore.apEnsureProps(props.doc).then(() => setIsLoading(false));
	}, [props.doc.doc, documentUiStore, props.doc, documentUiStore.currentDocId]);

	useEffect(() => {
		if (documentUiStore.currentDocId === props.doc.id) {
			uiStore.showModal(modalId);
		}
	}, [documentUiStore.currentDocId, props.doc.id, modalId, uiStore]);

	const onModalClose = () => {
		documentUiStore.setCurrent(undefined);
		uiStore.hideModal(modalId);
	};

	if (isLoading) {
		return <></>;
	}
	return (
		<>
			<div className="title">Planungstool</div>
			<div className={`document-ap ${props.printMode === true ? 'bg-color-white' : ''}`}>
				{!ap.hasValidId && (
					<div>
						<strong>Invalid Id!!!!</strong>
					</div>
				)}
				<Modal modalId={modalId} title={'Planungstool'} modifier="is-table-modal" size="large" onClose={onModalClose}>
					<div className="planungstool">
						<Collapser title={'Agenturdaten'}>
							<ApAgenturDaten {...props} />
						</Collapser>
						<Collapser title={'Ziele'}>
							<ApZiele {...props} />
						</Collapser>
						<Collapser title={'Vermittler'}>
							<ApVermittlerList {...props} />
						</Collapser>
						{ap.isPlanned && (
							<Collapser title={'Termindurchführung'}>
								<ApTermindurchfuehrung {...props} />
							</Collapser>
						)}
					</div>
					<div className="modal-footer">
						<Button type="submit" className="button" onClick={onModalClose}>
							Fertig
						</Button>
					</div>
				</Modal>

				<div>
					{ap.isPlanned && <ApZielErreichungsBalken {...props} agtId={props.doc.agtId!} />}
					{!ap.isPlanned && !props.printMode && <>Keine Planung</>}
				</div>

				{props.printMode === true && (
					<div className="margin-top-1rem">
						<div className="page-break-avoid">
							<div className="pad-1rem bg-color-white">
								<strong>Agenturdaten</strong>
							</div>
							<ApAgenturDaten {...props} />
						</div>
						<div className="page-break-avoid">
							<div className="pad-1rem bg-color-white">
								<strong>Ziele</strong>
							</div>
							<ApZiele {...props} />
						</div>
						<div className="page-break-avoid">
							<div className="pad-1rem bg-color-white">
								<strong>Vermittler</strong>
							</div>
							<ApVermittlerList {...props} />
						</div>
						{ap.isPlanned && (
							<div className="page-break-avoid">
								<div className="pad-1rem bg-color-white">
									<strong>Termindurchführung</strong>
								</div>
								<ApTermindurchfuehrung {...props} />
							</div>
						)}
					</div>
				)}
			</div>
		</>
	);
});

import { AdminUsageBranchenplanung } from 'app/components/admin/usage/AdminUsageBranchenplanung';
import { AdminUsageLoginsProRolle } from 'app/components/admin/usage/AdminUsageLoginsProRolle';
import { AdminUsageMatomoDevices } from 'app/components/admin/usage/AdminUsageMatomoDevices';
import { AdminUsageMatomoGeneral } from 'app/components/admin/usage/AdminUsageMatomoGeneral';
import { AdminUsageMatomoLogins } from 'app/components/admin/usage/AdminUsageMatomoLogins';
import { AdminUsagePlanApDocs, AdminUsagePlanJpgDocs } from 'app/components/admin/usage/AdminUsagePlanDocs';
import { AdminUserRegisteredUsers } from 'app/components/admin/usage/AdminUsageRegisteredUsers';
import React from 'react';

import { ITreeNodeModel } from './core/tree.node.model';
import { AdminUsageFokusthemenOverview } from 'app/components/admin/usage/AdminUsageFokusthemenOverview';
import { AgenturListsFilter } from 'app/components/agt/AgenturListFilter';
import { AdminUsageFokusthemenRanked } from 'app/components/admin/usage/AdminUsageFokusthemenRanked';

export const AdminUsageTreeNodes: ITreeNodeModel = {
	name: 'root',
	icon: 'root',
	path: '',
	hideFilter: false,
	nodes: [
		{
			name: 'Agenturplanung',
			icon: 'briefcase',
			path: 'agtplan',
			nodes: [
				{
					name: 'JPG',
					icon: '',
					path: 'jpg',
					component: <AdminUsagePlanJpgDocs />,
				},
				{
					name: 'Planungstool',
					icon: '',
					path: 'ap',
					component: <AdminUsagePlanApDocs />,
				},
				{
					name: 'Branchenplanung',
					icon: '',
					path: 'bp',
					component: <AdminUsageBranchenplanung />,
				},
				{
					name: 'Fokusthemen',
					icon: '',
					path: 'ft',
					nodes: [
						{
							name: 'Übersicht',
							icon: '',
							path: 'overview',
							component: <AdminUsageFokusthemenOverview />,
							filterComponent: <AgenturListsFilter />
						},
						{
							name: 'Nach Thema',
							icon: '',
							path: 'ranked',
							component: <AdminUsageFokusthemenRanked />,
							filterComponent: <AgenturListsFilter />
						},
					]
				},
			],
		},
		{
			name: 'DVS Nutzung',
			icon: 'briefcase',
			path: 'usage',
			nodes: [
				{
					name: 'Logins pro Rolle',
					icon: '',
					path: 'loginsprorolle',
					component: <AdminUsageLoginsProRolle />,
				},

				{
					name: 'Angemeldete Nutzer',
					icon: '',
					path: 'registeredusers',
					component: <AdminUserRegisteredUsers />,
				},
			],
		},
		{
			name: 'Matomo',
			icon: 'chart-line',
			path: 'matomo',
			nodes: [
				{
					name: 'Logins nach VD/GS (Matomo)',
					icon: '',
					path: 'logins',
					component: <AdminUsageMatomoLogins />,
				},
				{
					name: 'Allgemeine Nutzung',
					icon: '',
					path: 'general',
					component: <AdminUsageMatomoGeneral />,
				},
				{
					name: 'Nutzung per Gerätetyp',
					icon: '',
					path: 'devices',
					component: <AdminUsageMatomoDevices />,
				},
			],
		},
	],
};

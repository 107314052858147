import { computed, makeObservable, observable, reaction, runInAction } from 'mobx';
import { AdminTrackingStore } from '../admin.tracking.store';
import { AdminUserUiStore, UserVM } from './admin.user.ui.store';

export class UserLogin {
	constructor(opts: any) {
		this.bnr_id = opts.bnr_id;
		this.bensl = opts.bensl;
		this.action = opts.action;
		this.created = new Date(opts.created);
	}
	bnr_id?: number;
	bensl: string;
	action: string;
	created: Date;

	@computed
	get loginCount() {
		return this.action === 'login' ? 1 : 0;
	}

	@computed
	get loginFailCount() {
		return (this.action.indexOf('login_failed') >= 0) ? 1 : 0;
	}

	@computed
	get forgotPassword() {
		return (this.action.indexOf('forgotpassword') >= 0) ? 1 : 0;
	}

	@computed
	get pdfCount() {
		return this.action.indexOf('pdfgen:') >= 0 ? 1 : 0;
	}

	@computed
	get pdfErrorCount() {
		return this.action.indexOf('pdfgenerror:') >= 0 ? 1 : 0;
	}

	@computed
	get sendCount() {
		return this.action.indexOf('send:') >= 0 ? 1 : 0;
	}

	@computed
	get sendDocCount() {
		return this.action.indexOf('send:doc') >= 0 ? 1 : 0;
	}
	@computed
	get sendTaskCount() {
		return this.action.indexOf('send:task') >= 0 ? 1 : 0;
	}
	@computed
	get sendMultiCount() {
		return this.action.indexOf('send:multipdf') >= 0 ? 1 : 0;
	}

	@computed
	get ymd() {
		return this.created.toISOString().slice(0, 10);
	}
	@computed
	get ym() {
		return this.created.toISOString().slice(0, 7);
	}

	@computed
	get ymdforsort() {
		return this.created.toISOString().slice(0, 10).replace(/-/g, '');
	}
	@computed
	get ymforsort() {
		return this.created.toISOString().slice(0, 7).replace(/-/g, '');
	}

	@computed
	get fitnessPdf() {
		return this.action.indexOf('fitness') >= 0 && this.action.indexOf('download') >= 0 ? 1 : 0;
	}
	@computed
	get fitnessPdfNotFound() {
		return this.action.indexOf('fitness') >= 0 && this.action.indexOf('notfound') >= 0 ? 1 : 0;
	}
}

export class UserLoginVM {
	track: UserLogin;
	user?: UserVM;
	constructor(track: UserLogin, user?: UserVM) {
		this.track = track;
		this.user = user;
	}
}

export class AdminUserLoginUiStore {
	adminTrackingStore: AdminTrackingStore;
	adminUserUiStore: AdminUserUiStore;

	constructor(adminTrackingStore: AdminTrackingStore, adminUserUiStore: AdminUserUiStore) {
		makeObservable(this);

		this.adminTrackingStore = adminTrackingStore;
		this.adminUserUiStore = adminUserUiStore;
	}

	@observable
	_items: UserLoginVM[] = [];
	@observable
	loaded: boolean = false;

	async load() {
		if (this.loaded) {
			return this._items;
		}
		const res = await Promise.all([this.adminUserUiStore.load(), this.adminTrackingStore.getUserLoginTrackingData()]);
		const tracks = res[1];
		const users = this.adminUserUiStore._items;

		const vms: UserLoginVM[] = [];
		tracks.forEach((t: UserLogin) => {
			const u = users.find((u) => u.user.bensl.toLowerCase() === t.bensl.toLowerCase());
			vms.push(new UserLoginVM(t, u));
		});

		runInAction(() => {
			this._items = vms.sort((b, a) => b.track.created.getTime() - a.track.created.getTime());
			this.loaded = true;
		});
	}

	@observable
	resetStepOne: number = 0;

	@observable
	resetStepTwo: number = 0;

	async resetAllPassword() {
		await this.adminUserUiStore.adminUserStore.resetAllPasswords();
		return window.confirm('passwörter wurden zurückgesetzt.');
	}
}

import { observer } from 'mobx-react';
import { useStore } from 'app/context';
import { IWMStyle, WmTableRow } from './Wochenmail';
import { WMHeading } from './PlainTextWochenmail';

export const WochenmailExpertisen = observer((props: IWMStyle) => {
	const { wmStyles, isText } = props;
	const { wochenmailtUiStore } = useStore();
	const title = 'Ablaufende Expertisen';
	return (
		<>
			{wochenmailtUiStore.loaded && wochenmailtUiStore.showExpertisen && (
				<>
					{isText ? (
						<>
							<WMHeading title={title} />
							{wochenmailtUiStore.agtWithEndingExpertisen.map((agt) => (
								<>
									* {agt.agt.name} ({agt.agt.agtId}) <br />
									{agt.wochenMailEndingExpertisen.map((e) => (
										<>
											-- {e.text}: {e.endAblauf} <br />
										</>
									))}
									<br />
								</>
							))}
							<br />
							<br />
						</>
					) : (
						<WmTableRow>
							<table width={'100%'} style={wmStyles.innerTableStyle}>
								<tbody>
									<tr>
										<td style={wmStyles.headingTdStyle}>
											<h2 style={{ ...wmStyles.headingStyle, textAlign: 'center' }}>{title}</h2>
										</td>
									</tr>
									{wochenmailtUiStore.agtWithEndingExpertisen.map((agt) => (
										<>
											<tr key={agt.agt.agtId}>
												<td style={{ verticalAlign: 'top', paddingTop: '14px', whiteSpace: 'nowrap' }}>
													<strong>{agt.agt.name}</strong> ({agt.agt.agtId})
												</td>
											</tr>
											<tr>
												<td>
													{agt.wochenMailEndingExpertisen.map((e, i) => (
														<div key={`exp-${i}`}>
															<strong style={{ fontSize: '16px', lineHeight: '30px' }}>
																{e.text} - {e.endAblauf}
															</strong>
														</div>
													))}
												</td>
											</tr>
										</>
									))}
								</tbody>
							</table>
						</WmTableRow>
					)}
				</>
			)}
		</>
	);
});

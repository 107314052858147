import { AgenturListItems } from 'app/components/agt/AgenturList';
import Widget from 'app/components/common/Widget';
import { Table } from 'app/components/table/table';
import { AggregrationType, ITableColumn, TableModel, TableRow } from 'app/components/table/table.model';
import { AgtQueryStringSetter } from 'app/containers/BerichteContainer';
import { useStore } from 'app/context';
import { AgenturExtendedVM } from 'app/stores/ui/agt.list.ui.store';
import { FocusThemaVM } from 'app/stores/ui/document.ui.store';
import { observer } from 'mobx-react';
import { useEffect, useMemo } from 'react';

export const AdminUsageFokusthemenRanked = observer(() => {
	const { agenturListUiStore } = useStore();

	useEffect(() => {
		agenturListUiStore.load();
	}, [agenturListUiStore]);

	return (
		<>
			<AdminFokusThemenByOrga />
			<AgtQueryStringSetter />
			<AgenturListItems onSelectAgt={(x: any) => {}} />
		</>
	);
});

export const AdminFokusThemenByOrga = observer(() => {
	const { agenturListUiStore, agenturFilterUiStore } = useStore();

	const sumAvg = () => {
		return [
			{
				aggType: AggregrationType.sum,
			},
			{
				aggType: AggregrationType.avg,
			},
		];
	};

	const tm = useMemo(() => {
		const tm = new TableModel<AgtOneFokuVM, number>();
		let cols: ITableColumn<AgtOneFokuVM, number>[] = [];

		cols.push({
			label: 'Thema',
			// path: 'fokusThema.def.keyThema',
			path: 'fokusThema.def.thema',
		});

		cols.push({
			label: 'Anz Agt Gesamt',
			path: 'agtId',
			agg: [
				{
					aggType: AggregrationType.countDistinct,
				},
				{
					aggType: AggregrationType.sum,
				},
			],
		});
		tm.setCols(cols);
		tm.sortBy = 'agtId';
		tm.idProperty = 'id';
		tm.idType = 'string';
		return tm;
	}, [agenturListUiStore.filterHasChanged, agenturFilterUiStore.hasVdFilter, agenturListUiStore.filtered]);

	useEffect(() => {
		// console.log('agenturListUiStore.filtered', agenturListUiStore.filteredItems.length)
		let items: AgtOneFokuVM[] = [];
		agenturListUiStore.filteredItems.forEach((a) => {
			a.focusThemen.forEach((f) => {
				items.push(new AgtOneFokuVM(a, f));
			});
		});
		tm.setGroupByData(items, (x) => x.fokusThema.def.thema);
	}, [tm, agenturFilterUiStore.hasVdFilter, agenturListUiStore.filteredItems]);

	return (
		<>
			<div className="pad-1rem">
				<Widget title="Fokusthemen " modifier="is-paddingless" action={<span className="tag is-green">DVS Datenbank</span>}>
					<Table tm={tm} />
				</Widget>
			</div>
		</>
	);
});

class AgtOneFokuVM {
	agtId: number;
	vdId: string;
	gsId: string;
	fokusThema: FocusThemaVM;
	constructor(agt: AgenturExtendedVM, f: FocusThemaVM) {
		this.agtId = agt.agtId;
		this.vdId = agt.vdId;
		this.gsId = agt.gsId;
		this.fokusThema = f;
	}
}

import { AgenturModel } from 'app/models/agentur.model';
import { ITreeNodeModel, TreeMenuModel, TreeNodeModel } from 'app/models/core/tree.node.model';
import { IRoute } from 'routes';
import { action, computed, makeObservable, observable } from 'mobx';
import { SessionStore } from './../session.store';
import { AgenturStore } from '../agentur.store';
import { UserModel } from 'app/models/core/user.model';
import { AgtUserUiStore } from '../ui/agt.user.ui.store';
import { QueryParmDef } from 'app/containers/BerichteContainer';
import { encodeQueryParams } from 'serialize-query-params';
import { stringify } from 'query-string';
import { AgenturListsFilter } from 'app/components/agt/AgenturListFilter';

const fakeTreeNodes: ITreeNodeModel = {
	name: 'root',
	icon: 'root',
	path: '',
	nodes: [],
};

export class BaseTreeStore {
	session: SessionStore;
	menu: TreeMenuModel = new TreeMenuModel(fakeTreeNodes);
	constructor(session: SessionStore) {
		makeObservable(this);
		this.session = session;
	}

	@computed
	get showFilter() {
		if (this.menu.current && !this.menu.current.hideFilter && !this.isInAgt) {
			return true;
		}
		return false;
	}

	filterComponent = (<AgenturListsFilter />);

	@observable
	selectedAgt?: AgenturModel;

	@observable
	selectedBnr?: UserModel;

	@observable
	printPath?: string;

	@computed
	get currentComponent() {
		const node = this.menu.current;
		if (!node) {
			return <></>;
		}
		if (this.selectedAgt) {
			if (node.agtComponent) {
				return node.agtComponent;
			} else if (node.component) {
				return node.component;
			} else {
				return <>y</>;
			}
		} else {
			if (node.component) {
				return node.component;
			} else {
				return <>x</>;
			}
		}
	}

	isInAgt: boolean = true;

	AGENTURROUTE?: IRoute;
	OUTEROUTE?: IRoute;
	getPath(node: TreeNodeModel, location: any, query?: any) {
		// outerroute and agenturroute need to be set in the subclass!!
		if (!this.OUTEROUTE) {
			console.warn('no outer route.. for ', node, location, query);
			return '';
		}
		let p = this.OUTEROUTE!.getPath(node.path);
		if (this.isInAgt && location) {
			const s = location.pathname.split('/');
			const agtId = s[2];
			const routeS = this.OUTEROUTE!.route.split('/');
			const app = routeS[1];
			p = this.AGENTURROUTE!.getPath(agtId, app, node.path);
		}

		if (query) {
			const encodedQuery = encodeQueryParams(QueryParmDef, query);
			p += `/?${stringify(encodedQuery)}`;
		}
		return p;
	}
}

export class AgtBaseTreeStore extends BaseTreeStore {
	agenturStore: AgenturStore;
	session: SessionStore;
	agtUserUiStore: AgtUserUiStore;

	constructor(session: SessionStore, agenturStore: AgenturStore, agtUserUiStore: AgtUserUiStore) {
		super(session);
		makeObservable(this);
		this.session = session;
		this.agenturStore = agenturStore;
		this.agtUserUiStore = agtUserUiStore;
	}

	filterComponent = (<AgenturListsFilter />);

	@computed
	get selectedAgtId() {
		if (this.selectedAgt) {
			return this.selectedAgt.agtId;
		}
		return undefined;
	}

	@action
	setSelectedAgt(agt?: AgenturModel) {
		this.selectedAgt = agt;
	}

	@action
	setSelectBnr(bnr?: UserModel) {
		this.selectedBnr = bnr;
	}

	isInAgt: boolean = true;

	// AGENTURROUTE?: IRoute;
	// OUTEROUTE?: IRoute;
	// getPath(node: TreeNodeModel, location: any, query?: any) {
	// 	// outerroute and agenturroute need to be set in the subclass!!
	// 	let p = this.OUTEROUTE!.getPath(node.path);
	// 	if (this.isInAgt && location) {
	// 		const s = location.pathname.split('/');
	// 		const agtId = s[2];
	// 		const routeS = this.OUTEROUTE!.route.split('/');
	// 		const app = routeS[1];
	// 		p = this.AGENTURROUTE!.getPath(agtId, app, node.path);
	// 	}

	// 	if (query) {
	// 		const encodedQuery = encodeQueryParams(QueryParmDef, query);
	// 		p += `/?${stringify(encodedQuery)}`;
	// 	}
	// 	return p;
	// }

	@action
	setSelectedAgtById(agtId?: number) {
		if (agtId) {
			this.agenturStore.findById(agtId).then((agt: AgenturModel | undefined) => {
				this.setSelectedAgt(agt);
			});
		} else {
			this.selectedAgt = undefined;
		}
	}

	@action
	setSelectBnrById(bnrId?: number) {
		if (bnrId) {
			this.agtUserUiStore.load().then(() => {
				const u = this.agtUserUiStore.items.find((u) => u.bnrId === bnrId);
				this.selectedBnr = u;
			});
		} else {
			this.selectedBnr = undefined;
		}
	}

	fillNodesFromUser() {
		if (!this.session.currentUser) {
			return;
		}
		console.log('override me!');
	}

	menu: TreeMenuModel = new TreeMenuModel(fakeTreeNodes);
}

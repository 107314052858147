import { computed } from 'mobx';
import { MenuModel, VblMenu } from './menu.model';
import { UserModel } from './user.model';

export enum Steart {
	// when updating this don't forget to update the server:
	// AUP Import: addUsersToAgentur
	// DbSteart
	GSL = 900,
	VBL = 934,
	LPV = 755,
	LBSG = 974,
	BSG = 977,
	VAS = 973,
	KVS = 968,
	KME = 959,
	BBS = 994,
	LSV = 756,

	UB = 951,

	LDP = 1001,
	EDP = 1002,
	EDT = 1003,
	DVSADMIN = 1,
	XXX = 0,

	// removed
	// SPS = 751,
	// BFG = 983,
}

export const SteartFromId = (id: number) => {
	switch (id) {
		case 934:
			return Steart.VBL;
		case 900:
			return Steart.GSL;
		case 977:
			return Steart.BSG;
		case 755:
			return Steart.LPV;
		case 974:
			return Steart.LBSG;
		case 973:
			return Steart.VAS;
		case 968:
			return Steart.KVS;
		case 959:
			return Steart.KME;
		case 994:
			return Steart.BBS;
		case 756:
			return Steart.LSV;
		case 951:
			return Steart.UB;
		case 1001:
			return Steart.LDP;
		case 1002:
			return Steart.EDP;
		case 1003:
			return Steart.EDT;

		case 123:
			return Steart.DVSADMIN;
		default:
			return Steart.XXX;
	}
};

type SpeziGroup = 'vbl' | 'spezi' | 'leiterspezi' | 'mitarbeiter' | 'gsl' | 'multi';

export class SteartModel {
	constructor(opts: any) {
		this.id = opts.steart;
		this.steart = SteartFromId(opts.steart);
		this.name = opts.name;
		this._name = opts._name;
		this.group = opts.group;
		this.gpId = opts.gpId;
		this.canShareWith = opts.canShareWith;
		if (opts.canViewGroups) {
			this.canViewGroups = opts.canViewGroups;
		}
		if (!opts.menu) {
			this.menu = VblMenu;
		} else {
			this.menu = opts.menu;
		}
		if (opts.sortOrder) {
			this.sortOrder = opts.sortOrder;
		}
	}

	canViewGroup(u: UserModel) {
		if (this.canViewGroups.includes(u.steart.group)) {
			return true;
		}
		if (this.steart === Steart.KME && u.steart.steart === Steart.VAS) {
			return true;
		}
		if (this.steart === Steart.VAS && u.steart.steart === Steart.KME) {
			return true;
		}
		return false;
	}

	id: number;
	name: string;
	_name: string;
	sortOrder: number = 99;

	@computed
	get isLeiter() {
		if (this.id === 755 || this.id === 756 || this.id === 974) {
			return true;
		}
		return false;
	}

	@computed
	get isDVSAdmin() {
		if (this.id === 123) {
			return true;
		}
		return false;
	}

	@computed
	get isVbl() {
		return this.id === 934;
	}

	@computed
	get isSpezi() {
		return SpeziStearts.map((s) => s.steart).includes(this.id);
	}

	@computed
	get isMulti() {
		return this.steart === Steart.EDP || this.steart === Steart.EDT || this.steart === Steart.LDP;
	}

	@computed
	get longName() {
		if (this._name) {
			return this._name;
		}
		return this.name;
	}

	menu: MenuModel;
	steart: Steart;
	group: SpeziGroup;
	gpId: number;
	canShareWith: SpeziGroup[];
	canViewGroups: SpeziGroup[] = [];
	ehComponents: [] = [];
}
const leiterCanShareWith: SpeziGroup[] = ['vbl', 'gsl', 'leiterspezi'];
const vblCanShareWith = ['vbl', 'gsl', 'spezi', 'leiterspezi', 'multi'];
const speziCanShareWith = ['vbl', 'spezi', 'multi'];

const multiCanShareWith: SpeziGroup[] = ['multi', 'vbl', 'spezi', 'leiterspezi'];

const gslCanView: SpeziGroup[] = ['vbl', 'gsl', 'leiterspezi'];
const leiterCanView: SpeziGroup[] = ['vbl', 'leiterspezi'];
const speziCanView: SpeziGroup[] = ['vbl', 'leiterspezi'];
const kmevasCanView: SpeziGroup[] = ['vbl', 'leiterspezi']; // kme/vas dürfen leben bp werte voneinander sehen. alle anderen spezis nur vbl

const multiCanView: SpeziGroup[] = ['multi'];

export const Stearts: SteartModel[] = [];

Stearts.push(new SteartModel({ steart: 900, name: 'GSL', sortOrder: 10, group: 'gsl', canShareWith: vblCanShareWith, gpId: 2255, canViewGroups: gslCanView }));
Stearts.push(new SteartModel({ steart: 934, name: 'VBL', sortOrder: 21, group: 'vbl', canShareWith: vblCanShareWith, gpId: 2255, canViewGroups: leiterCanView }));
Stearts.push(new SteartModel({ steart: 755, name: 'LPV', sortOrder: 22, group: 'leiterspezi', canShareWith: leiterCanShareWith, gpId: 2275, canViewGroups: leiterCanView }));
Stearts.push(new SteartModel({ steart: 974, name: 'LBSG', sortOrder: 22, group: 'leiterspezi', canShareWith: leiterCanShareWith, gpId: 2275, canViewGroups: leiterCanView }));
Stearts.push(new SteartModel({ steart: 973, name: 'VAS', sortOrder: 36, group: 'spezi', chef: 'lpv', canShareWith: speziCanShareWith, gpId: 2225, canViewGroups: kmevasCanView }));
Stearts.push(new SteartModel({ steart: 977, name: 'BSG', sortOrder: 36, group: 'spezi', chef: 'lbsg', canShareWith: speziCanShareWith, gpId: 2225, canViewGroups: kmevasCanView }));
Stearts.push(new SteartModel({ steart: 968, name: 'KVS', sortOrder: 34, group: 'spezi', chef: 'lpv', canShareWith: speziCanShareWith, gpId: 2240, canViewGroups: speziCanView }));
Stearts.push(new SteartModel({ steart: 959, name: 'KME', sortOrder: 33, group: 'spezi', chef: 'lpv', canShareWith: speziCanShareWith, gpId: 2235, canViewGroups: kmevasCanView }));
Stearts.push(new SteartModel({ steart: 994, name: 'BBS', sortOrder: 31, group: 'spezi', chef: 'lpv', canShareWith: speziCanShareWith, gpId: 2245, canViewGroups: speziCanView }));
Stearts.push(new SteartModel({ steart: 756, name: 'LSV', sortOrder: 23, group: 'leiterspezi', canShareWith: leiterCanShareWith, gpId: 2265, canViewGroups: leiterCanView }));
Stearts.push(new SteartModel({ steart: 951, name: 'UB', sortOrder: 39, group: 'spezi', chef: 'lpv', canShareWith: speziCanShareWith, gpId: 2230, canViewGroups: speziCanView }));

Stearts.push(new SteartModel({ steart: 1001, name: 'LDP', sortOrder: 39, group: 'multi', canShareWith: multiCanShareWith, canViewGroups: multiCanView }));
Stearts.push(new SteartModel({ steart: 1002, name: 'EDP', sortOrder: 39, group: 'multi', canShareWith: multiCanShareWith, canViewGroups: multiCanView, chef: 'ldp' }));
Stearts.push(new SteartModel({ steart: 1003, name: 'EDT', sortOrder: 39, group: 'multi', canShareWith: multiCanShareWith, canViewGroups: multiCanView, chef: 'ldp' }));

Stearts.push(new SteartModel({ steart: 750, _name: 'Agenturservice', name: 'AGTSERVICE', gpId: 0, group: 'mitarbeiter' }));
Stearts.push(new SteartModel({ steart: 3001, _name: 'Assistent/in des Vertreters', name: 'ASS VTN', gpId: 0, group: 'mitarbeiter' }));
Stearts.push(new SteartModel({ steart: 3002, _name: 'Azubi Kauffrau/-mann für Bürokommunikation', name: 'AZUBI BK', gpId: 0, group: 'mitarbeiter' }));
Stearts.push(new SteartModel({ steart: 690, _name: 'Azubi Versicherungskaufmann', name: 'AZUBI VKM', gpId: 0, group: 'mitarbeiter' }));
Stearts.push(new SteartModel({ steart: 3003, _name: 'Bürokraft', name: 'BK', gpId: 0, group: 'mitarbeiter' }));
Stearts.push(new SteartModel({ steart: 3004, _name: 'Büroleiter/in', name: 'BL', gpId: 0, group: 'mitarbeiter' }));
Stearts.push(new SteartModel({ steart: 888, _name: 'Dualer Student', name: 'DS', gpId: 0, group: 'mitarbeiter' }));
Stearts.push(new SteartModel({ steart: 3005, _name: 'Leiter/in Kundenservice', name: 'LT KSVC', gpId: 0, group: 'mitarbeiter' }));
Stearts.push(new SteartModel({ steart: 3006, _name: 'Praktikant/in', name: 'PKT', gpId: 0, group: 'mitarbeiter' }));
Stearts.push(new SteartModel({ steart: 3007, _name: 'Schadenssachbearbeiter/in in Agentur', name: 'SB SCHADEN', gpId: 0, group: 'mitarbeiter' }));
Stearts.push(new SteartModel({ steart: 620, _name: 'Sekretärin', name: 'SEKR', gpId: 0, group: 'mitarbeiter' }));
Stearts.push(new SteartModel({ steart: 3008, _name: 'Servicekraft', name: 'SVC', gpId: 0, group: 'mitarbeiter' }));
Stearts.push(new SteartModel({ steart: 3009, _name: 'Sonstige MA', name: 'SONST', gpId: 0, group: 'mitarbeiter' }));
Stearts.push(new SteartModel({ steart: 3010, _name: 'Telefonist/in', name: 'TEL', gpId: 0, group: 'mitarbeiter' }));
Stearts.push(new SteartModel({ steart: 3011, _name: 'Vertriebsassistent/in', name: 'ASS VERTR', gpId: 0, group: 'mitarbeiter' }));
Stearts.push(new SteartModel({ steart: 676, _name: 'Aushilfe über 8 Wochen/kaufmännisch', name: 'AUSH 8', gpId: 0, group: 'mitarbeiter' }));

const DummySteart = new SteartModel({ steart: 1, name: 'Unbekannt', group: '', chef: '', gpId: 0 });
const DvsAdminSteart = new SteartModel({ steart: 123, name: 'DVS Admin', group: '', chef: '', gpId: 0 });

export const FindSteart = (stearId: number) => {
	const s = Stearts.find((x) => x.id === stearId);
	if (s) {
		return s;
	}
	if (stearId === 123) {
		return DvsAdminSteart;
	}
	return DummySteart;
};

export const FindSteartForAdmin = (stearId: number) => {
	const s = Stearts.find((x) => x.id === stearId);
	if (s) {
		return s;
	}
	if (stearId === 123) {
		return DvsAdminSteart;
	}
	return DummySteart;
};

export const AdminUserSteartOpts = [900, 977, 934, 755, 974, 973, 968, 959, 994, 756, 751, 983, 951, 123, 1, 1001, 1002, 1003].map((i) => {
	return { value: i, label: FindSteartForAdmin(i).name + ' [' + i + ']' };
});

export const FilterStearts = (stearts: Steart[]) => {
	const all = [900, 934, 977, 974, 755, 973, 968, 959, 994, 951, 1001, 1002, 1003, 123, 1].map((s) => FindSteart(s));
	const f = stearts.map((s) => FindSteart(s));
	const x = all.filter((s) => !f.includes(s)).map((s) => s.steart);
	return x;
};

export const SteartOpts = [900, 934, 977, 755, 974, 973, 968, 959, 994, 951, 1].map((i) => {
	return { value: i, label: FindSteart(i).name };
});

export const SteartTextOpts = [934, 977, 755, 974, 973, 968, 959, 994, 951, 1001, 1002, 1003, 1].map((i) => {
	const s = FindSteart(i);
	return { value: s.name, label: s.name };
});


export const Jpg25SteartTextOpts = [977, 755, 974, 973, 968, 959, 994, 951, 1001, 1002, 1003].map((i) => {
	const s = FindSteart(i);
	return { value: s.name, label: s.name };
});


export const UserStearts = [934, 755, 977, 974, 973, 968, 959, 994, 756, 751, 983, 951].map((i) => {
	return Stearts.find((s) => s.id === i)!;
});

export const AdminBnrSteartIds = [900, 934, 977, 974, 755, 973, 968, 959, 994, 756, 751, 983, 951];

export const SpeziStearts = Stearts.filter((s) => s.group === 'spezi');
export const SpeziSteartIds = SpeziStearts.map((item) => item.steart);

export const LeiterSpeziStearts = Stearts.filter((s) => s.group === 'leiterspezi');
export const LeiterSpeziSteartIds = LeiterSpeziStearts.map((item) => item.steart);

export const MultiStearts = Stearts.filter((s) => s.group === 'multi');
export const MultiSteartIds = MultiStearts.map((item) => item.steart);

import { action, computed, makeObservable, observable } from 'mobx';
import { AgenturStore } from '../agentur.store';
import { AgtGoogleProfileUiStore, GoogleProfileVM } from './agt.googleprofile.ui.store';
import { AgenturModel } from 'app/models/agentur.model';
import { AgtAkumaPrimeKwVM, AgtAkumaPrimeUiStore } from './agt.akumaprime.ui.store';
import { AgtAkumaPlanerUiStore, AgtAkumaPlanerVM } from './agt.akumaplaner.ui.store';
import { AgtDigitalreportUiStore, DigitalReportVM } from './agt.digitalreport.ui.store';
import { BaseUiStore } from './base.ui.store';
import { AgtAmisNowUiStore, AmisNowVm } from './agt.amisnow.ui.store';
import { AgtSocialMediaUiStore, AgtSocialMediaVM } from './agt.socialmedia.ui.store';
import { AgtLeadNowUiStore, LeadNowVM } from './agt.leadnow.ui.store';
import { AgtKvmUiStore, KvmVM } from './agt.kvm.ui.store';

interface IFitModel {
	ziel?: number;
	ergebnis?: number;
	max?: number;
}

export class FitModel {
	constructor(opts: IFitModel) {
		makeObservable(this);
		this.ergebnis = opts.ergebnis;
		this.ziel = opts.ziel;
		this.max = opts.max;
	}

	ergebnis?: number;
	ziel?: number;
	max?: number;

	@computed
	get ze() {
		if (!this.ziel || !this.ergebnis || this.ziel === 0) {
			return 0;
		}
		const z = this.ziel;
		const e = this.ergebnis;
		if (e > z) return 100;
		let ze = (e / z) * 100;
		ze = Math.round(ze * 100) / 100;
		return ze;
	}
}

export class AgtFitnessVm {
	agt: AgenturModel;
	gup?: GoogleProfileVM;
	prime?: AgtAkumaPrimeKwVM;
	planer?: AgtAkumaPlanerVM;
	digiReport?: DigitalReportVM;
	amisNow?: AmisNowVm;
	isPrime?: boolean;
	maxPrimeKw: number = 0;
	sm?: AgtSocialMediaVM;
	leadNow?: LeadNowVM;
	maxLeadnowKw: number = 0;
	kvm?: KvmVM;

	keineDaten: boolean = false;

	constructor(
		agt: AgenturModel,
		gup?: GoogleProfileVM,
		prime?: AgtAkumaPrimeKwVM,
		planer?: AgtAkumaPlanerVM,
		digiReport?: DigitalReportVM,
		ami?: AmisNowVm,
		isPrime?: boolean,
		maxPrimeKw?: number,
		sm?: AgtSocialMediaVM,
		leadNow?: LeadNowVM,
		maxLeadnowKw?: number,
		kvm?: KvmVM,
	) {
		this.agt = agt;
		this.gup = gup;
		this.prime = prime;
		this.planer = planer;
		this.digiReport = digiReport;
		this.amisNow = ami;
		this.isPrime = isPrime;
		this.maxPrimeKw = maxPrimeKw ? maxPrimeKw : 0;
		this.sm = sm;
		this.leadNow = leadNow;
		this.maxLeadnowKw = maxLeadnowKw ? maxLeadnowKw : 0;
		this.kvm = kvm;

		if (!agt && !gup && !prime && !planer && !digiReport && !ami && !isPrime && !sm && !leadNow && !kvm) {
			this.keineDaten = true;
		}
	}

	@computed
	get googleExists() {
		return !!this.gup ? 1 : 0;
	}

	@computed
	get primeExists() {
		return !!this.prime ? 1 : 0;
	}

	@computed
	get planerExists() {
		return !!this.planer ? 1 : 0;
	}

	@computed
	get mazExists() {
		if (this.digiReport && this.mazBestandDurchdringungsquote && this.mazBestandDurchdringungsquote.ziel! >= 0) {
			return 1;
		}
		return 0;
	}

	@computed
	get amisNowExists() {
		return !!this.amisNow ? 1 : 0;
	}

	//// GOOGLE ///////
	@computed
	get gupAverage() {
		if (!this.gup) {
			return new FitModel({});
		}
		const vals = [this.anzBew.ze, this.bewAvg.ze, this.imgCount.ze, this.qCount.ze];
		const res = vals.reduce((acc, val) => acc + val, 0) / vals.length;

		return new FitModel({
			ziel: 100,
			ergebnis: res,
		});
	}

	@computed
	get anzBew() {
		if (!this.gup) {
			return new FitModel({});
		}
		return new FitModel({
			ziel: 25,
			ergebnis: this.gup.bew,
			max: 100,
		});
	}

	@computed
	get bewAvg() {
		if (!this.gup) {
			return new FitModel({});
		}
		return new FitModel({
			ziel: 5,
			ergebnis: this.gup.bewAvg,
		});
	}

	@computed
	get imgCount() {
		if (!this.gup) {
			return new FitModel({});
		}
		return new FitModel({
			ziel: 5,
			ergebnis: this.gup.imgCount,
		});
	}

	@computed
	get qCount() {
		if (!this.gup) {
			return new FitModel({});
		}
		return new FitModel({
			ziel: 5,
			ergebnis: this.gup.qCount,
		});
	}

	//// AMIS.NOW  ///////
	@computed
	get amisNowQuoteRead() {
		if (!this.amisNow) {
			return new FitModel({});
		}

		return new FitModel({
			ziel: this.amisNow.item?.summe,
			ergebnis: this.amisNow.item?.davonRead,
		});
	}

	//// AKUMA  ///////
	@computed
	get isAkumaPrimeUser() {
		if (this.prime && this.prime.einordnungsQuote > 0) {
			return 1;
		}
		return 0;
	}

	@computed
	get planerAnzahlWochenNutzung() {
		if (!this.planer) {
			return new FitModel({});
		}
		return new FitModel({
			ziel: this.planer.kwItems.length,
			ergebnis: this.planer.gpWochen,
		});
	}

	@computed
	get planerUebertragungquoteAmisNow() {
		// ?AmisNow?
		if (!this.planer) {
			return new FitModel({});
		}
		return new FitModel({
			// ziel: 40,
			// ergebnis: this.planer.uebertragungsQuote,
			ziel: this.planer.gesamt,
			ergebnis: this.planer.zu,
		});
	}

	@computed
	get primeEinordnungsquote() {
		if (!this.prime) {
			return new FitModel({});
		}
		return new FitModel({
			ziel: this.prime.einordnungTotal,
			ergebnis: this.prime.eingeordnetTotal,
		});
	}

	@computed
	get primeAbwahlquote() {
		if (!this.prime) {
			return new FitModel({});
		}
		return new FitModel({
			ziel: this.prime.eingeordnetTotal,
			ergebnis: this.prime.abwahl,
		});
	}

	@computed
	get primeTerminierungExtern() {
		if (!this.prime) {
			return new FitModel({});
		}
		return new FitModel({
			ziel: this.prime.zuKontaktierendeKunden,
			ergebnis: this.prime.terminierungExternTotal,
		});
	}

	/// Meine Allianz
	@computed
	get mazBestandDurchdringungsquote() {
		if (!this.digiReport) {
			return new FitModel({ ziel: -1 });
		}

		const anz = this.digiReport.pos.anzKunden18;
		const erg = this.digiReport.pos.anzMaz18;

		// erg = Math.round(erg);
		return new FitModel({
			ziel: anz,
			ergebnis: erg,
		});
	}

	@computed
	get mazNeuDurchdringungsquote() {
		if (!this.digiReport) {
			return new FitModel({});
		}
		const anz = this.digiReport.pos.anzKundenNeu;
		const erg = this.digiReport.pos.anzMazNeu;

		return new FitModel({
			ziel: anz,
			ergebnis: erg,
		});
	}

	// multisgp - GUP
	@computed
	get gupMin25() {
		if (this.anzBew.ergebnis && this.anzBew.ergebnis >= 25) {
			return this.agt.thCount ? this.agt.thCount : 1;
		}
		return 0;
	}

	// multisgp - Akuma
	@computed
	get isCurrentAkumaPrimeUser() {
		if (this.prime) {
			// this.prime
			return this.prime.kw >= this.maxPrimeKw ? 1 : 0;
		}
		return 0;
	}

	@computed
	get gpWochenCurrYear() {
		if (this.planer) {
			return this.planer.kwItems.filter((kw) => kw.year === new Date().getFullYear() && kw.gpWochen > 0).length;
		}
		return 0;
	}
	@computed
	get has12WeeksOfPlanerInCurrentYear() {
		return this.gpWochenCurrYear >= 12 ? 1 : 0;
	}

	@computed
	get akumaMultiGpCount() {
		if (this.isCurrentAkumaPrimeUser || this.has12WeeksOfPlanerInCurrentYear) {
			return this.agt.thCount ? this.agt.thCount : 1;
		}
		return 0;
	}

	@computed
	get isCurrentAkumaPrimeUserGpCount() {
		if (this.isCurrentAkumaPrimeUser) {
			return this.agt.thCount ? this.agt.thCount : 1;
		}
		return 0;
	}
	@computed
	get akumaPlanerGpCount() {
		if (this.has12WeeksOfPlanerInCurrentYear) {
			// planer zählt wenn nicht gleichzeitig prime aktiv ist
			if (this.isCurrentAkumaPrimeUser) {
				return 0;
			}
			return this.agt.thCount ? this.agt.thCount : 1;
		}
		return 0;
	}
	@computed
	get akumaPlanerGpCountDebug() {
		if (this.has12WeeksOfPlanerInCurrentYear) {
			return this.agt.thCount ? this.agt.thCount : 1;
		}
		return 0;
	}

	// multisgp - LeadNow
	@computed
	get isCurrentLeadNowUser() {
		if (this.leadNow && this.leadNow.maxKw) {
			return this.leadNow.maxKw >= this.maxLeadnowKw ? 1 : 0;
		}
		return 0;
	}

	@computed
	get leadnowMultiGpCount() {
		if (this.isCurrentLeadNowUser) {
			return this.agt.thCount ? this.agt.thCount : 1;
		}
		return 0;
	}

	/// Facebook
	@computed
	get fbStatus() {
		return this.sm?.currFb?.status || 0;
	}
	@computed
	get fbFanAnzahl() {
		if (this.sm && this.sm.currFb) {
			return new FitModel({
				ziel: 250,
				ergebnis: this.sm.currFb.fans,
			});
		}
		return new FitModel({});
	}
	@computed
	get fbPostinganzahl() {
		if (this.sm && this.sm.currFb) {
			return new FitModel({
				ziel: 4,
				ergebnis: this.sm.fbPostsYtd,
			});
		}
		return new FitModel({});
	}
	@computed
	get fbEigenePosts() {
		if (this.sm && this.sm.currFb) {
			return new FitModel({
				ergebnis: this.sm.fbOwnPostsYtd,
			});
		}
		return new FitModel({});
	}
	@computed
	get fbPRAbo() {
		return this.sm?.currFb?.aboPr || 0;
	}
	@computed
	get fb1890Abo() {
		return this.sm?.currFb?.abo1890 || 0;
	}

	// Instagram
	@computed
	get instaStatus() {
		return this.sm?.currInsta?.status || 0;
	}
	@computed
	get instaFollower() {
		if (this.sm && this.sm.currInsta) {
			return new FitModel({
				ziel: 250,
				ergebnis: this.sm.currInsta.follow,
			});
		}
		new FitModel({});
	}
	@computed
	get instaPostinganzahl() {
		if (this.sm && this.sm.currInsta) {
			return new FitModel({
				ziel: 4,
				ergebnis: this.sm.instaPostsYtd,
			});
		}
		new FitModel({});
	}
	@computed
	get instaEigenePosts() {
		if (this.sm && this.sm.currFb) {
			return new FitModel({
				ergebnis: this.sm.instaOwnPostsYtd,
			});
		}
		return new FitModel({});
	}
	@computed
	get instaPRAbo() {
		return this.sm?.currInsta?.aboPr || 0;
	}

	// LeadNow
	@computed
	get leadNowBrutto() {
		if (!this.leadNow) return new FitModel({});
		return new FitModel({
			ziel: 100,
			ergebnis: this.leadNow.brutto,
		});
	}
	@computed
	get leadNowNetto() {
		if (!this.leadNow) return new FitModel({});
		return new FitModel({
			ziel: 100,
			ergebnis: this.leadNow.netto,
		});
	}
	@computed
	get leadNowStorno() {
		if (!this.leadNow) return new FitModel({});
		return new FitModel({
			ziel: 100,
			ergebnis: this.leadNow.storno,
		});
	}
	@computed
	get leadNowAv() {
		if (!this.leadNow) return new FitModel({});
		return new FitModel({
			ziel: 100,
			ergebnis: this.leadNow.av?.brutto,
		});
	}
	@computed
	get leadNowBaufi() {
		if (!this.leadNow) return new FitModel({});
		return new FitModel({
			ziel: 100,
			ergebnis: this.leadNow.baufi?.brutto,
		});
	}
	@computed
	get leadNowBausp() {
		if (!this.leadNow) return new FitModel({});
		return new FitModel({
			ziel: 100,
			ergebnis: this.leadNow.bausp?.brutto,
		});
	}
	@computed
	get leadNowBu() {
		if (!this.leadNow) return new FitModel({});
		return new FitModel({
			ziel: 100,
			ergebnis: this.leadNow.bu?.brutto,
		});
	}
	@computed
	get leadNowFsach() {
		if (!this.leadNow) return new FitModel({});
		return new FitModel({
			ziel: 100,
			ergebnis: this.leadNow.fsach?.brutto,
		});
	}
	@computed
	get leadNowKfz() {
		if (!this.leadNow) return new FitModel({});
		return new FitModel({
			ziel: 100,
			ergebnis: this.leadNow.kfz?.brutto,
		});
	}
	@computed
	get leadNowKv() {
		if (!this.leadNow) return new FitModel({});
		return new FitModel({
			ziel: 100,
			ergebnis: this.leadNow.kv?.brutto,
		});
	}
	@computed
	get leadNowKzus() {
		if (!this.leadNow) return new FitModel({});
		return new FitModel({
			ziel: 100,
			ergebnis: this.leadNow.kzus?.brutto,
		});
	}
	@computed
	get leadNowPsach() {
		if (!this.leadNow) return new FitModel({});
		return new FitModel({
			ziel: 100,
			ergebnis: this.leadNow.psach?.brutto,
		});
	}
	@computed
	get leadNowReise() {
		if (!this.leadNow) return new FitModel({});
		return new FitModel({
			ziel: 100,
			ergebnis: this.leadNow.reise?.brutto,
		});
	}
	@computed
	get leadNowTier() {
		if (!this.leadNow) return new FitModel({});
		return new FitModel({
			ziel: 100,
			ergebnis: this.leadNow.tier?.brutto,
		});
	}
	@computed
	get leadNowBerat() {
		if (!this.leadNow) return new FitModel({});
		return new FitModel({
			ziel: 100,
			ergebnis: this.leadNow.berat?.brutto,
		});
	}
	@computed
	get anzahlAnrufeGesamtYtd() {
		if (!this.kvm) return new FitModel({});
		return new FitModel({
			ergebnis: this.kvm.anzahlAnrufeGesamtYtd,
		});
	}
}

export class AgtFitnessUiStore extends BaseUiStore<AgtFitnessVm> {
	name = 'AgtFitnessUiStore';
	agenturStore: AgenturStore;
	agtDigitalreportUiStore: AgtDigitalreportUiStore;
	agtAkumaPlanerUiStore: AgtAkumaPlanerUiStore;
	agtAkumaPrimeUiStore: AgtAkumaPrimeUiStore;
	agtGoolgeProfileUiStore: AgtGoogleProfileUiStore;
	agtAmisNowUiStore: AgtAmisNowUiStore;
	agtSocialMediaUiStore: AgtSocialMediaUiStore;
	agtLeadNowUiStore: AgtLeadNowUiStore;
	kvmUiStore: AgtKvmUiStore;

	constructor(
		agenturStore: AgenturStore,
		agtGoolgeProfileUiStore: AgtGoogleProfileUiStore,
		agtAkumaPrimeUiStore: AgtAkumaPrimeUiStore,
		agtAkumaPlanerUiStore: AgtAkumaPlanerUiStore,
		agtDigitalreportUiStore: AgtDigitalreportUiStore,
		agtAmisNowUiStore: AgtAmisNowUiStore,
		agtSocialMediaUiStore: AgtSocialMediaUiStore,
		agtLeadNowUiStore: AgtLeadNowUiStore,
		kvmUiStore: AgtKvmUiStore,
	) {
		super();
		this.agenturStore = agenturStore;
		this.agtDigitalreportUiStore = agtDigitalreportUiStore;
		this.agtAkumaPlanerUiStore = agtAkumaPlanerUiStore;
		this.agtAkumaPrimeUiStore = agtAkumaPrimeUiStore;
		this.agtGoolgeProfileUiStore = agtGoolgeProfileUiStore;
		this.agtAmisNowUiStore = agtAmisNowUiStore;
		this.agtSocialMediaUiStore = agtSocialMediaUiStore;
		this.agtLeadNowUiStore = agtLeadNowUiStore;
		this.kvmUiStore = kvmUiStore;
	}

	@action
	async _baseLoad() {
		const promises = await Promise.all([
			this.agenturStore.findAll(),
			this.agtGoolgeProfileUiStore.load(),
			this.agtAkumaPrimeUiStore.load(),
			this.agtAkumaPlanerUiStore.load(),
			this.agtDigitalreportUiStore.load(),
			this.agtAmisNowUiStore.load(),
			this.agtSocialMediaUiStore.load(),
			this.agtLeadNowUiStore.load(),
			this.kvmUiStore.load(),
		]);
		// const promises = await Promise.all([this.agenturStore.findAll(), this.agtGoolgeProfileUiStore.load(), this.agtDigitalreportUiStore.findAll()]);

		// set the the date ranges:
		this.agtGoolgeProfileUiStore.bmCurrent = this.agtGoolgeProfileUiStore.maxBm;
		this.agtAkumaPrimeUiStore.minKwCurrent = this.agtAkumaPrimeUiStore.firstWeek;
		this.agtAkumaPrimeUiStore.maxKwCurrent = this.agtAkumaPrimeUiStore.lastWeek;
		this.agtAkumaPlanerUiStore.minKwCurrent = this.agtAkumaPlanerUiStore.firstWeek;
		this.agtAkumaPlanerUiStore.maxKwCurrent = this.agtAkumaPlanerUiStore.lastWeek;

		let gups = this.agtGoolgeProfileUiStore.currentBMItems.slice();
		let primes = this.agtAkumaPrimeUiStore.currentKWItems.slice();
		let plans = this.agtAkumaPlanerUiStore.currentKWItems.slice();
		let digis = this.agtDigitalreportUiStore.items.slice();
		let amis = this.agtAmisNowUiStore.items.slice();
		let sms = this.agtSocialMediaUiStore.items.slice();
		let leads = this.agtLeadNowUiStore.items.slice();
		let kvms = this.kvmUiStore.items.slice();

		const res: AgtFitnessVm[] = [];
		const agts = promises[0];
		let i = 0;
		const maxPrimeKw = this.agtAkumaPrimeUiStore.maxKw;
		const maxLeadnowKw = this.agtLeadNowUiStore.maxKw;
		for (const agt of agts) {
			if (agt.gpShort === 'AGP') {
				continue;
			}
			if (!agt.vdId || !agt.gsId) {
				continue;
			}
			if (agt.gsId === 'XX') {
				continue;
			}

			let gup;
			let prime = undefined;
			let planer = undefined;
			let digi = undefined;
			let ami = undefined;
			let sm = undefined;
			let lead = undefined;
			let kvm = undefined;

			const isPrime = this.agtAkumaPrimeUiStore.checkAgtParticipation(agt.agtId);

			[gup, gups] = this.findAndSplice(gups, (a: any) => a.agt.agtId === agt.agtId);

			[prime, primes] = this.findAndSplice(primes, (a: any) => a.agt.agtId === agt.agtId);

			[planer, plans] = this.findAndSplice(plans, (a: any) => a.agt.agtId === agt.agtId);

			[digi, digis] = this.findAndSplice(digis, (a: any) => a.agt.agtId === agt.agtId);

			[ami, amis] = this.findAndSplice(amis, (a: any) => a.agt.agtId === agt.agtId);

			[sm, sms] = this.findAndSplice(sms, (a: any) => a.agt.agtId === agt.agtId);

			[lead, leads] = this.findAndSplice(leads, (a: any) => a.agt.agtId === agt.agtId);

			[kvm, kvms] = this.findAndSplice(kvms, (a: any) => a.agt.agtId === agt.agtId);

			const vm = new AgtFitnessVm(agt, gup, prime, planer, digi, ami, isPrime, maxPrimeKw, sm, lead, maxLeadnowKw, kvm);
			if (vm.keineDaten) {
				i++;
				continue;
			}

			res.push(vm);
		}

		console.warn('FITNESSTRACKER: ', i, ' Agenturen haben keine Daten und werden nicht angezeigt.');

		this.items = res;
	}
}

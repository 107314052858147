import { Einheit, EinheitModel, FindEinheit } from '../core/einheit.model';
import { computed } from 'mobx';
import { GpGroups, UnkownGroup } from './gp.groups';
import { allGps, GP } from './gp';
import { isArray } from 'lodash';

export interface IGpPosModel {
	posId: number | number[];
	part?: string;
	group?: string;
	planKey?: string;
	posText?: string;
}

export const GPBases = [1110, 1111, 1113, 1114, 1115, 1116, 1117, 1119, 1130, 1190]

export interface IAgtGpPosModel {
	year: number;
	posId: number | number[];
	decimals?: number
	group?: string;
	posText?: string;
	planKey: string;
	// einheit?: 'stck' | 'bew' | 'minuten';
	einheit: Einheit;

	showGraph?: boolean;
	pro3GpPos?: IGpPosModel;
	nonPro3GpPos?: IGpPosModel;
	boniStufen?: number;
	pro3Only?: boolean;
	hideFromPro3?: boolean;
	hideFromTreeMenu?: boolean;
	visibleForGps?: GP[];
	problem?: string;
}

export class AgtGpPosModel {
	constructor(opts: IAgtGpPosModel) {
		if (isArray(opts.posId)) {
			// new ways. old way should be removed in 2025
			this._posId = opts.posId[0];
			this._posIds = opts.posId;
		} else {
			this._posId = opts.posId;
		}
		if (opts.posText) {
			this._posText = opts.posText;
		}
		if (opts.decimals) {
			this.decimals = opts.decimals;
		}

		this.year = opts.year;
		this.planKey = opts.planKey;
		this.nonPro3GpPos = opts.nonPro3GpPos;
		this.pro3GpPos = opts.pro3GpPos;
		this._group = opts.group;
		this._einheit = opts.einheit;
		this.einheit = FindEinheit(opts.einheit);
		if (opts.showGraph) {
			this.showGraph = opts.showGraph;
		}
		if (opts.boniStufen) {
			this.boniStufen = opts.boniStufen;
		}

		if (opts.pro3Only) {
			this.pro3Only = opts.pro3Only;
		}
		// if (opts.hideFromPro3) {
		// 	this.hideFromPro3 = opts.hideFromPro3;
		// }

		if (opts.hideFromTreeMenu) {
			this.hideFromTreeMenu = opts.hideFromTreeMenu;
		}

		if (opts.visibleForGps) {
			this.visibleForGps = opts.visibleForGps;
		}
	}

	_posIds: number[] = [];
	_posText?: string;

	decimals: number = 0;

	visibleForGps: GP[] = allGps;

	_einheit: Einheit;
	boniStufen: number = 1;
	pro3Only: boolean = false;
	// hideFromPro3: boolean = false;
	_posId: number;
	showGraph: boolean = false;
	einheit: EinheitModel;
	year: number;

	_group?: string;
	planKey: string;
	pro3GpPos?: IGpPosModel;
	nonPro3GpPos?: IGpPosModel;
	hideFromTreeMenu: boolean = false;

	cloneAsPro3() {
		return new AgtGpPosModel({
			year: this.year,
			posId: this._posId,
			planKey: this.planKey,
			// nonPro3GpPos: this.nonPro3GpPos,
			pro3GpPos: this.pro3GpPos,
			group: this._group,
			einheit: this._einheit,
			showGraph: this.showGraph,
			boniStufen: this.boniStufen,
		});
	}

	@computed
	get group() {
		let g: string;
		if (this.nonPro3GpPos && this.nonPro3GpPos.group) {
			g = this.nonPro3GpPos.group;
		}

		if (this.pro3GpPos && this.pro3GpPos.group) {
			g = this.pro3GpPos.group;
		}
		if (this._group) {
			g = this._group;
		}
		const res = GpGroups.find((group) => group.id === g);
		if (res) {
			return res;
		}
		return UnkownGroup;
	}

	@computed
	get posText() {
		if (this._posText) {
			return this._posText;
		}
		if (this.nonPro3GpPos && this.nonPro3GpPos.posText) {
			return this.nonPro3GpPos.posText;
		}

		if (this.pro3GpPos && this.pro3GpPos.posText) {
			return this.pro3GpPos.posText;
		}
		return 'no postext for ' + this.planKey;
	}

	@computed
	get posIds() {
		let res: number[] = [];

		if (this._posIds.length > 0) {
			this._posIds.forEach(pId => {

				for (let s = 0; s < this.boniStufen; s++) {
					if (pId > 1000) {
						res.push(pId + s);
					} else {
						const bases = [1110, 1111, 1113, 1114, 1115, 1116, 1117, 1119, 1130, 1190];
						bases.forEach((b) => res.push(b * 1000 + pId + s));
					}
				}

			});
			return res;
		}

		// old way
		res.push(this._posId);


		// 2025 this should be deletable

		if (this.pro3GpPos && this.pro3GpPos.posId) {

			if (Array.isArray(this.pro3GpPos.posId)) {
				res = res.concat(this.pro3GpPos.posId);
			} else {
				const pId = this.pro3GpPos.posId;
				for (let s = 0; s < this.boniStufen; s++) {
					if (pId > 1000) {
						res.push(pId + s);
					} else {
						const bases = [1110, 1111, 1113, 1114, 1115, 1116, 1117, 1119, 1130, 1190];
						bases.forEach((b) => res.push(b * 1000 + pId + s));
					}
				}
				res.push(this.pro3GpPos.posId);
			}
		}

		// sollte bald nicht mehr verwendet werden
		if (this.nonPro3GpPos && this.nonPro3GpPos.posId) {
			const pId = this.nonPro3GpPos.posId as number;
			for (let s = 0; s < this.boniStufen; s++) {
				if (pId > 1000) {
					res.push(pId + s);
				} else {
					const bases = [1110, 1111, 1113, 1114, 1115, 1116, 1117, 1119, 1130, 1190];
					bases.forEach((b) => res.push(b * 1000 + pId + s));
				}
			}
		}
		return res;
	}
}

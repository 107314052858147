import { KvBnrMsModel } from 'app/models/kv.bnr.ms.model';
import { computed, observable } from 'mobx';
import { BaseCachedEntityStore } from './core/base.cached.entity.store';

export class KvVdYearMultiGpModel extends KvBnrMsModel {
	constructor(opts: any) {
		super(opts);
		this.group = 'multigp';
		if (this.valueJson) {
			try {
				this.val = JSON.parse(this.valueJson);
			} catch (ex) {
				console.error('Invalid JSON for KvBnrYearErrungenschaftenModel', this.valueJson);
			}
		}
	}

	@computed
	get year() {
		return this.bm;
	}

	@observable
	val?: any;


}

export class KvVdYearMultiGpStore extends BaseCachedEntityStore<KvVdYearMultiGpModel> {
	idProp: string = 'id';
	apiPath = 'api/kv_bnr_ms/multigp';
	// disableFindForStearts = [123];

	createInstances(opts: any): KvVdYearMultiGpModel {
		return new KvVdYearMultiGpModel(opts);
	}


	async copyToPosBnr() {
		await this.post('copygp', {});
	}
}

import { useStore } from 'app/context';
import { UserModel } from 'app/models/core/user.model';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';

interface ISharedUser {
	bnrId: number;
	showName?: boolean;
}

export const SharedUser = observer((props: ISharedUser) => {
	const [user, setUser] = useState<UserModel>();
	const { userStore } = useStore();
	const showName = props.showName !== false;

	useEffect(() => {
		let closing = false;
		userStore.findById(props.bnrId).then((u) => {
			if (closing) {
				return;
			}
			setUser(u);
		});
		return () => {
			closing = true;
		};
	}, [props.bnrId, userStore]);

	return (
		<>
			{user && (
				<>
					<strong> {user.steart.name}</strong>
					{showName && ' ' + user.name}
				</>
			)}
			{!user && <>&nbsp;{props.bnrId}</>}
		</>
	);
});

import { computed, makeObservable, runInAction } from "mobx";
import { BaseUiStore } from "./base.ui.store";
import { AgtFitnessUiStore } from "./agt.fitness.ui.store";
import { KvVdYearMultiGpModel, KvVdYearMultiGpStore } from "../kv.vd.year.multigp.store";
import { AgtSocialMediaUiStore } from "./agt.socialmedia.ui.store";


const VDDef = [
    { vd: 'M', base: 898, },
    { vd: 'H', base: 939, },
    { vd: 'K', base: 1063, },
    { vd: 'B', base: 1018, },
    { vd: 'S', base: 1158, },
    { vd: 'F', base: 846, },
    { vd: 'N', base: 593, },
    { vd: 'L', base: 1068, },
]


export class MultiGpVM {
    year: number;
    vd: String;

    constructor(year: number, vd: string) {
        this.year = year;
        this.vd = vd;
    }
    // Gup
    gupMin25Count: number = 0;
    gupMin25Base: number = 0;

    setGupGP(gupMin25Count: number, base: number) {
        this.gupMin25Count = gupMin25Count;
        this.gupMin25Base = base;
    }
    @computed
    get gupZiel1() {
        return Math.floor(this.gupMin25Base * 0.45);
    }
    @computed
    get gupZiel2() {
        return Math.floor(this.gupMin25Base * 0.55);
    }

    // SM (Facebook & Insta)
    smErgebnis: number = 0;
    smVorjahr: number = 0;
    setSmGP(smErgebnis: number, smVorjahr: number) {
        this.smErgebnis = smErgebnis;
        this.smVorjahr = smVorjahr;
    }

    @computed
    get smZiel1() {
        return Math.floor(this.smVorjahr * 1.1);
    }

    @computed
    get smZiel2() {
        return Math.floor(this.smVorjahr * 1.15);
    }

    // AKUMA 
    akumaCount: number = 0;
    akumaBase: number = 0;
    isCurrentAkumaPrimeUserGpCount: number = 0;
    akumaPlanerGpCount: number = 0;
    akumaPlanerGpCountDebug: number = 0;
    setAkumaGp(akumaCount: number, akumaBase: number, primeUserCount: number = 0, akumaPlanerGpCount: number = 0, akumaPlanerGpCountDebug: number = 0) {
        this.akumaCount = akumaCount;
        this.akumaBase = akumaBase;
        this.isCurrentAkumaPrimeUserGpCount = primeUserCount;
        this.akumaPlanerGpCount = akumaPlanerGpCount;
        this.akumaPlanerGpCountDebug = akumaPlanerGpCountDebug;
    }

    @computed
    get akumaZiel() {
        return Math.floor(this.akumaBase * 0.25);
    }

    // LEADNOW
    leadnowCount: number = 0;
    leadnowBase: number = 0;
    setLeadNowGp(leadnowCount: number, leadnowBase: number) {
        this.leadnowCount = leadnowCount;
        this.leadnowBase = leadnowBase;
    }

    @computed
    get leadnowZiel1() {
        return Math.floor(this.leadnowBase * 0.15);
    }

    @computed
    get leadnowZiel2() {
        return Math.floor(this.leadnowBase * 0.20);
    }
}





export class AgtMultiGpUiStore extends BaseUiStore<MultiGpVM> {
    name = 'AgtMultiGpUiStore';
    agtFitnessUiStore: AgtFitnessUiStore;
    kvVdYearMultiGpStore: KvVdYearMultiGpStore;
    agtSocialMediaUiStore: AgtSocialMediaUiStore;

    constructor(agtFitnessUiStore: AgtFitnessUiStore,
        kvVdYearMultiGpStore: KvVdYearMultiGpStore,
        agtSocialMediaUiStore: AgtSocialMediaUiStore) {
        super();
        makeObservable(this);
        this.agtFitnessUiStore = agtFitnessUiStore;
        this.kvVdYearMultiGpStore = kvVdYearMultiGpStore;
        this.agtSocialMediaUiStore = agtSocialMediaUiStore
    }


    async _baseLoad() {
        if (this.items.length > 0) {
            return;
        }

        await Promise.all([this.agtFitnessUiStore.load(), this.agtSocialMediaUiStore.load(),]);

        const year = new Date().getFullYear();
        const res: MultiGpVM[] = [];

        for (const vd of VDDef) {
            const multiGp = new MultiGpVM(year, vd.vd);
            res.push(multiGp);

            const fits = this.agtFitnessUiStore.items.filter((a) => a.agt.vdId === vd.vd);
            /// GUP /// 
            const gups = fits.filter(x => x.gupMin25 >= 1);
            let gupSum = 0;
            gups.forEach(gup => {
                gupSum += gup.gupMin25;
            })
            multiGp.setGupGP(gupSum, vd.base);

            //// SM ////
            const sms = this.agtSocialMediaUiStore.items.filter((a) => a.agt.vdId === vd.vd);
            let ffSum = 0;
            let ffSumPrev = 0;
            sms.forEach(sm => {
                ffSum += sm.currFFGreater250;
                ffSumPrev += sm.prevFFGreater250;
            });
            multiGp.setSmGP(ffSum, ffSumPrev);

            /// AKUMA
            const akumas = fits.filter(x => x.akumaMultiGpCount >= 1);
            let akumaSum = 0;
            let primeUserCount = 0;
            let akumaPlanerGpCount = 0;
            let akumaPlanerGpCountDebug = 0;
            akumas.forEach(sm => {
                akumaSum += sm.akumaMultiGpCount;
                primeUserCount += sm.isCurrentAkumaPrimeUserGpCount;
                akumaPlanerGpCount += sm.akumaPlanerGpCount;
                akumaPlanerGpCountDebug += sm.akumaPlanerGpCountDebug;
            });
            multiGp.setAkumaGp(akumaSum, vd.base, primeUserCount, akumaPlanerGpCount, akumaPlanerGpCountDebug);

            /// LEAD.NOW
            const leadnows = fits.filter(x => x.leadNow!);
            let leadnowSum = 0;
            leadnows.forEach(sm => {
                leadnowSum += sm.leadnowMultiGpCount;
            });
            multiGp.setLeadNowGp(leadnowSum, vd.base);

        }
        runInAction(() => {
            this.items = res;
        })

    }


    async saveGup() {
        const kvs = await this.kvVdYearMultiGpStore.findAll();
        for (const a of this.items) {
            let model = kvs.find(x => x.group === 'multigp' && x.key === a.vd && x.bm === a.year && x.key2 === 'gup1');


            if (!model) {
                model = new KvVdYearMultiGpModel({ group: 'multigp', key: a.vd, bm: a.year, key2: 'gup1' });
            }
            model.valueJson = JSON.stringify({ ergebnis: a.gupMin25Count, ziel: a.gupZiel1, posId: 10010 });
            await this.kvVdYearMultiGpStore.save(model);


            model = kvs.find(x => x.group === 'multigp' && x.key === a.vd && x.bm === a.year && x.key2 === 'gup2');
            if (!model) {
                model = new KvVdYearMultiGpModel({ group: 'multigp', key: a.vd, bm: a.year, key2: 'gup2' });
            }
            model.valueJson = JSON.stringify({
                ergebnis: a.gupMin25Count, ziel: a.gupZiel2, posId: 10011
            });
            await this.kvVdYearMultiGpStore.save(model);
            await this.kvVdYearMultiGpStore.copyToPosBnr();
        }
    }

    async saveSm() {
        const kvs = await this.kvVdYearMultiGpStore.findAll();
        for (const a of this.items) {
            let model = kvs.find(x => x.group === 'multigp' && x.key === a.vd && x.bm === a.year && x.key2 === 'sm1');


            if (!model) {
                model = new KvVdYearMultiGpModel({ group: 'multigp', key: a.vd, bm: a.year, key2: 'sm1' });
            }
            model.valueJson = JSON.stringify({ ergebnis: a.smErgebnis, ziel: a.smZiel1, posId: 10020 });
            await this.kvVdYearMultiGpStore.save(model);


            model = kvs.find(x => x.group === 'multigp' && x.key === a.vd && x.bm === a.year && x.key2 === 'sm2');
            if (!model) {
                model = new KvVdYearMultiGpModel({ group: 'multigp', key: a.vd, bm: a.year, key2: 'sm2' });
            }
            model.valueJson = JSON.stringify({
                ergebnis: a.smErgebnis, ziel: a.smZiel2, posId: 10021
            });
            await this.kvVdYearMultiGpStore.save(model);
            await this.kvVdYearMultiGpStore.copyToPosBnr();
        }
    }


    async saveAkuma() {
        const kvs = await this.kvVdYearMultiGpStore.findAll();
        for (const a of this.items) {
            let model = kvs.find(x => x.group === 'multigp' && x.key === a.vd && x.bm === a.year && x.key2 === 'akuma');


            if (!model) {
                model = new KvVdYearMultiGpModel({ group: 'multigp', key: a.vd, bm: a.year, key2: 'akuma' });
            }
            model.valueJson = JSON.stringify({ ergebnis: a.akumaCount, ziel: a.akumaZiel, posId: 10030 });
            await this.kvVdYearMultiGpStore.save(model);
            await this.kvVdYearMultiGpStore.copyToPosBnr();
        }
    }

    async saveLeadNow() {
        const kvs = await this.kvVdYearMultiGpStore.findAll();
        for (const a of this.items) {
            let model = kvs.find(x => x.group === 'multigp' && x.key === a.vd && x.bm === a.year && x.key2 === 'leadnow1');


            if (!model) {
                model = new KvVdYearMultiGpModel({ group: 'multigp', key: a.vd, bm: a.year, key2: 'leadnow1' });
            }
            model.valueJson = JSON.stringify({ ergebnis: a.leadnowCount, ziel: a.leadnowZiel1, posId: 10040 });
            await this.kvVdYearMultiGpStore.save(model);

            model = kvs.find(x => x.group === 'multigp' && x.key === a.vd && x.bm === a.year && x.key2 === 'leadnow2');
            if (!model) {
                model = new KvVdYearMultiGpModel({ group: 'multigp', key: a.vd, bm: a.year, key2: 'leadnow2' });
            }
            model.valueJson = JSON.stringify({ ergebnis: a.leadnowCount, ziel: a.leadnowZiel2, posId: 10041 });
            await this.kvVdYearMultiGpStore.save(model);
            await this.kvVdYearMultiGpStore.copyToPosBnr();
        }
    }


}
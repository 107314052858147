import { Button } from 'app/components/common/Button';
import { CheckboxControl } from 'app/components/common/CheckboxControl';
import { DocumentSendModal, WarnNoExcel } from 'app/components/documents/DocumentSendModal';
import { Modal } from 'app/components/Modal';
import { useStore } from 'app/context';
import { AgtPlanExportOpts } from 'app/stores/ui/agt.plan.export.ui.store';
import _ from 'lodash';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

interface IAgtPlanungExport {
	export: AgtPlanExportOpts;
}
export const AgtPlanungExport = observer((props: IAgtPlanungExport) => {
	const exp = props.export;

	const onChangeDocType = (name: string, v: boolean) => {
		const dt = exp.selectedDocTypes.find((d) => d.type === name);
		if (dt) {
			runInAction(() => {
				dt.selected = v;
			});
		}
	};

	const onChange = (name: string, v: boolean) => {
		runInAction(() => {
			_.set(exp, name, v);
		});
	};

	return (
		<>
			<table className="table no-hover fill-first-col">
				<tbody>
					<tr>
						<td>Bitte stellen Sie hier ein was alles exportiert werden soll</td>
					</tr>
					{exp.apIsSelected && (
						<tr>
							<td>
								<WarnNoExcel />
							</td>
						</tr>
					)}
				</tbody>
			</table>

			<table className="table fill-first-col is-striped ">
				<thead>
					<tr>
						<th>Zu exportieren</th>
						<th>Anzahl</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							<CheckboxControl alignment="left" label="Alle" name="all" value={exp.all} onChange={(n: string, v: boolean) => exp.setAll(v)} />
						</td>
						<td>{exp.allCount}</td>
					</tr>
					{exp.selectedDocTypes.map((s) => (
						<tr key={s.type}>
							<td>
								<CheckboxControl alignment="left" label={s.label} name={s.type} value={s.selected} onChange={onChangeDocType} />
							</td>
							<td>{s.count}</td>
						</tr>
					))}
					{props.export.plan.year < 2025 &&
						<tr>
							<td>
								<CheckboxControl alignment="left" label="Ziele" name="goals" value={exp.goals} onChange={onChange} />
							</td>
							<td></td>
						</tr>
					}
					{props.export.plan.year < 2025 &&
						<tr>
							<td>
								<CheckboxControl alignment="left" label="Themen & Maßnahmen" name="tasks" value={exp.tasks} onChange={onChange} />
							</td>
							<td></td>
						</tr>
					}
					<tr>
						<td>
							<CheckboxControl alignment="left" label="Branchenplanung" name="bp" value={exp.bp} onChange={onChange} />
						</td>
						<td></td>
					</tr>
				</tbody>
			</table>
			<table className="table fill-first-col">
				<thead>
					<tr>
						<th>Anhänge</th>
						<th>Anzahl</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							<CheckboxControl alignment="left" label="Anhänge" name="attachments" value={exp.attachments} onChange={onChange} />
						</td>
						<td>{exp.attachmentsCount}</td>
					</tr>
				</tbody>
			</table>
			<table className="table fill-first-col">
				<thead>
					<tr>
						<th>Erweiterte Optionen</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							<CheckboxControl alignment="left" label="Ausgewählte Dokumente in ein PDF zusammenfassen" name="combined" value={exp.combined} onChange={onChange} />
						</td>
					</tr>
				</tbody>
			</table>
		</>
	);
});

export const AgtPlanungExportModal = observer(() => {
	const { uiStore, agtPlanExportUiStore, agtPlanUiStore, documentUiStore } = useStore();
	const [exp, setExp] = useState<AgtPlanExportOpts>();
	const modalId = uiStore.modalIds.apExport;
	const onClose = () => {
		uiStore.hideModal(modalId);
	};

	const onDownload = async () => {
		if (!exp) {
			return;
		}
		await agtPlanExportUiStore.export(exp);
		uiStore.hideModal(modalId);
	};
	const onSend = () => {
		uiStore.showModal(uiStore.modalIds.apExportSendModal);
	};

	useEffect(() => {
		if (agtPlanUiStore.current && documentUiStore.currentItems) {
			agtPlanExportUiStore.getExportModel().then((m) => {
				setExp(m);
			});
		}
	}, [agtPlanUiStore, agtPlanExportUiStore, documentUiStore.currentItems]);
	const modalTitle = exp ? exp.title : 'Exportieren';
	return (
		<>
			<Modal modifier="is-table-modal is-paddingless" modalId={modalId} title={modalTitle} onClose={onClose}>
				{exp && <AgtPlanungExport export={exp} />}
				<div className="modal-footer">
					<Button className="button" onClick={onSend}>
						Als E-Mail verschicken
					</Button>
					<Button className="button is-primary" onClick={onDownload}>
						Download
					</Button>
				</div>
			</Modal>
			{exp && <DocumentSendModal exportOpts={exp} modalId={uiStore.modalIds.apExportSendModal} />}
		</>
	);
});

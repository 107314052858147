import { observer } from 'mobx-react';
import { useStore } from 'app/context';
import { makeObservable, observable, runInAction } from 'mobx';
import { Modal } from 'app/components/Modal';
import { useEffect, useMemo } from 'react';
import { useField, useFormikContext } from 'formik';
import { IJpgThemaDef, JpgTextDef, JpgZieleDef, mapFocusThemenToVM } from 'app/stores/ui/jpg.themen.ui.store';
import { IJpg25, JpgThema } from 'app/stores/bnr.document.store';
import { ITableColumn, TableModel } from '../table/table.model';
import { Table } from '../table/table';
import * as Yup from 'yup';
import { Button } from '../common/Button';
import { DocumentVM } from 'app/stores/ui/document.ui.store';
import { UserModel } from 'app/models/core/user.model';

interface IDocumentSendModalProps {
	// onClose: () => void;
	doc: DocumentVM;
}

export const steartsSchema = Yup.object().shape({
	VBL: Yup.boolean(),
	BSG: Yup.boolean(),
	LPV: Yup.boolean(),
	VAS: Yup.boolean(),
	KVS: Yup.boolean(),
	KME: Yup.boolean(),
	BBS: Yup.boolean(),
	UB: Yup.boolean(),
	LDP: Yup.boolean(),
	EDP: Yup.boolean(),
	EDT: Yup.boolean(),
});

class Jpg25RoleVM {
	index: number = 0;
	def: IJpgThemaDef;
	shares: number[];
	[key: string]: any;

	constructor(def: IJpgThemaDef, shares: number[], index: number, users: UserModel[]) {
		this.index = index;
		this.def = def;
		this.shares = shares ? shares : [];

		users.forEach((u) => {
			this[u.bnrId.toString() as any] = this.shares.includes(u.bnrId);
		});
	}

	getState(bnrId: number) {
		return this[bnrId.toString() as any];
	}

	setAll(users: UserModel[], val: boolean) {
		users.forEach((u) => {
			this[u.bnrId.toString() as any] = val;
		});
	}
	getBnrIds(users: UserModel[]) {
		const res: number[] = [];
		users.forEach((u) => {
			if (this[u.bnrId.toString() as keyof Jpg25RoleVM]) {
				res.push(u.bnrId);
			}
		});
		return res;
	}
}

export const formatBool = (val: any) => {
	return <input type="checkbox" checked={val} disabled={true} />;
};

export const Jpg25DocumentShareModal = observer((props: IDocumentSendModalProps) => {
	const { uiStore } = useStore();

	const modalId = uiStore.modalIds.documentShareJpg25Modal;

	const { setFieldValue } = useFormikContext();
	const [field] = useField('meta'); // a bit ugly. but it works.
	const meta = field.value as IJpg25;
	if (!props.doc.agt) {
		return <></>;
	}
	const tm = useMemo(() => {
		const tm = new TableModel<Jpg25RoleVM, number>();
		const cols: ITableColumn<Jpg25RoleVM, number>[] = [
			{
				label: 'Thema',
				path: 'def.thema',
				render: (row) => {
					return (
						<>
							{row.data.def.themaShort && <strong>{row.data.def.themaShort}:&nbsp;</strong>}
							{row.data.def.thema}
						</>
					);
				},
			},
		];

		const currentUser = props.doc.currentUser;
		props.doc
			.agt!.users.filter((u) => u.bnrId !== currentUser.bnrId)
			.forEach((u) => {
				cols.push({
					label: u.name + ' ' + u.steartText,
					path: u.bnrId.toString(),
					format: formatBool,
					editType: 'checkbox',
					editable: true,
				});
			});

		tm.setCols(cols);
		tm.editMode = true;
		tm.hideEditModeHeader = true;
		tm.useDetachedFooter = true;
		tm.saveButtonText = 'Übernehmen';
		tm.idType = 'number';
		tm.idProperty = 'index';
		tm.editDisabled = false;
		tm.sortDisabled = true;
		return tm;
	}, [props.doc.agt]);

	const onCancel = () => {
		runInAction(() => {
			uiStore.hideModal(modalId);
		});
	};

	useEffect(() => {
		const focusThemen = meta.focusThemen ? meta.focusThemen : [];
		const all = mapFocusThemenToVM(focusThemen as JpgThema[]);
		const data: Jpg25RoleVM[] = [];
		let i = 0;

		data.push(new Jpg25RoleVM(JpgTextDef, meta.textShare ? meta.textShare : [], i++, props.doc.agt!.users));
		data.push(new Jpg25RoleVM(JpgZieleDef, meta.zieleShare ? meta.zieleShare : [], i++, props.doc.agt!.users));

		all.forEach((t) => {
			data.push(new Jpg25RoleVM(t.def, t.focusThema ? t.focusThema.share : [], i++, props.doc.agt!.users));
		});
		tm.setRowData(data);
		tm.setEditMode(true);
	}, [meta.focusThemen]);

	const onSave = () => {
		tm.data.forEach((vm) => {
			if (vm.def.keyThema === 'text') {
				meta.textShare = vm.getBnrIds(props.doc.agt!.users);
				return;
			}
			if (vm.def.keyThema === 'ziele') {
				meta.zieleShare = vm.getBnrIds(props.doc.agt!.users);
				return;
			}
			const t = meta.focusThemen!.find((f: any) => f.key === vm.def.keyThema);
			if (t) {
				t.share = vm.getBnrIds(props.doc.agt!.users);
			}
		});
		setFieldValue('meta', meta);
		uiStore.hideModal(modalId);
	};

	const allOrNone = () => {
		const val = tm.data[0].getState(props.doc.agt!.users[0].bnrId); // [props.doc.agt!.users[0].bnrId.toString()];
		tm.data.forEach((vm) => {
			vm.setAll(props.doc.agt!.users, !val);
		});
		tm.setRowData(tm.data);
	};

	return (
		<>
			<Modal modalId={modalId} title={'JPG teilen'} size="large" modifier="is-paddingless" onClose={onCancel}>
				<div className="pad-1rem">
					<Button type="button" className="button is-small is-primary is-inverted" onClick={allOrNone}>
						Alle / Keine
					</Button>
				</div>
				<div>
					<Table tm={tm} onSubmit={onSave} schema={steartsSchema} onCancel={onCancel} />
				</div>
			</Modal>
		</>
	);
});

import { Einheit } from 'app/models/core/einheit.model';
import { AgtGpPosModel, GPBases } from 'app/models/gp/agt.gp.pos.model';
import { allExceptBasis, allExceptPro3andSV, allExceptSV, onlyPro3, onlyPro3AndSV, PosTodo, pro3GP } from '../gp';
const agtPosDef: AgtGpPosModel[] = [];

/////////////////////// 2025 ////////////////////////////////////
// in agt.gp.pos.model.ts search for:
// 2025 this should be deletable
// then refactor accordingly. (definition bevor 2023 should be deleted)


/// sonderlocke produktivität

// siehe auch loadProduktivitaet
agtPosDef.push(
	new AgtGpPosModel({
		year: 2025,
		posId: GPBases.map(x => parseInt(x + '040')), // [1119040, 90144103, 90144102, 90144101],
		planKey: 'produktivitaet',
		einheit: Einheit.bew,
		group: 'eh',
		posText: 'Produktivität',
		hideFromTreeMenu: true,
	}),
);


// kunde
agtPosDef.push(
	new AgtGpPosModel({
		year: 2025,
		// posId: 2255001,
		posId: [1],
		planKey: 'idd',
		group: 'kunde',
		posText: 'Weiterbildungspflicht. AGT',
		einheit: Einheit.stck,
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2025,
		posId: [11],
		planKey: 'neukunden',
		group: 'kunde',
		posText: 'Neukunden',
		einheit: Einheit.stck,
	}),
);
agtPosDef.push(
	new AgtGpPosModel({
		year: 2025,
		posId: [12],
		planKey: 'neukundenmaz',
		group: 'kunde',
		posText: 'Neukunden (MAZ)',
		einheit: Einheit.stck,
	}),
);
agtPosDef.push(
	new AgtGpPosModel({
		year: 2025,
		posId: [13],
		planKey: 'neukundenomaz',
		group: 'kunde',
		posText: 'Neukunden (oMAZ)',
		einheit: Einheit.stck,
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2025,
		posId: [16],
		planKey: 'lebenbavlfnst',
		group: 'kunde',
		posText: 'Leben bAV (lfNST)',
		einheit: Einheit.stck,
	}),
);
agtPosDef.push(
	new AgtGpPosModel({
		year: 2025,
		posId: [17],
		planKey: 'lebenbavlfnstmaz',
		group: 'kunde',
		posText: 'Leben bAV (lfNST MAZ)',
		einheit: Einheit.stck,
	}),
);
agtPosDef.push(
	new AgtGpPosModel({
		year: 2025,
		posId: [18],
		planKey: 'lebenbavlfnstomaz',
		group: 'kunde',
		posText: 'Leben bAV (lfNST oMAZ)',
		einheit: Einheit.stck,
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2025,
		posId: [20],
		planKey: 'mazneueanmeldungenbeibest',
		group: 'kunde',
		posText: 'Meine Allianz: Neue Anmeldungen bei Bestandskunden',
		einheit: Einheit.stck,
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2025,
		posId: [22],
		planKey: 'npwswert',
		group: 'kunde',
		posText: 'NPS Wert',
		einheit: Einheit.stck,
		visibleForGps: pro3GP
	}),
);
agtPosDef.push(
	new AgtGpPosModel({
		year: 2025,
		posId: [23],
		planKey: 'gesamtzufriedenheit',
		group: 'kunde',
		posText: 'Gesamtzufriedenheit 5 Sterne',
		einheit: Einheit.stck,
		visibleForGps: pro3GP
	}),
);
agtPosDef.push(
	new AgtGpPosModel({
		year: 2025,
		posId: [24],
		planKey: 'npwswertnazahlbew',
		group: 'kunde',
		posText: 'NPS Anzahl Kundenbewertungen',
		einheit: Einheit.stck,
		visibleForGps: pro3GP,
		problem: 'Nicht in der datenliefereung enthalten'

	}),
);


// vertriebsschwerpunkte
agtPosDef.push(
	new AgtGpPosModel({
		year: 2025,
		posId: [26],
		planKey: 'privatundunternehemsschutz',
		group: 'vss',
		posText: 'Privatschutz und Unternehmensschutz lfNST (MAZ)',
		einheit: Einheit.lfNST,
		boniStufen: 1,
	}),
);
agtPosDef.push(
	new AgtGpPosModel({
		year: 2025,
		posId: [30],
		planKey: 'lebnstmaz',
		group: 'vss',
		posText: 'Leben lfNST (MAZ)',
		einheit: Einheit.lfNST,
		boniStufen: 1,
	}),
);
agtPosDef.push(
	new AgtGpPosModel({
		year: 2025,
		posId: [32],
		planKey: 'lebenakspvkp',
		group: 'vss',
		posText: 'Leben AKS+PV+KP lfNST (MAZ)',
		einheit: Einheit.lfNST,
		// boniStufen: 1,
	}),
);
agtPosDef.push(
	new AgtGpPosModel({
		year: 2025,
		posId: [36],
		planKey: 'krankenpflege',
		group: 'vss',
		posText: 'Kranfen Pflege lfNST (MAZ)',
		einheit: Einheit.lfNST,
		boniStufen: 1,
	}),
);

// eingangshürde
agtPosDef.push(
	new AgtGpPosModel({
		year: 2025,
		group: 'eh',
		posId: GPBases.map(x => parseInt(x + '040')),
		planKey: 'gesamtbewertung',
		posText: 'Gesamtbewertung',
		einheit: Einheit.bew,
		visibleForGps: allExceptPro3andSV,
	}),
);
agtPosDef.push(
	new AgtGpPosModel({
		year: 2025,
		group: 'eh',
		posId: [45],
		planKey: 'sachlfnst',
		posText: 'Sach lfNST',
		einheit: Einheit.stck,
		visibleForGps: allExceptPro3andSV,
	}),
);


// // Versicherung und Vorsorge
agtPosDef.push(
	new AgtGpPosModel({
		year: 2025,
		posId: [121],
		planKey: 'psachokrunfbew',
		group: 'vuv',
		posText: 'P-Sach o.Kr/U oAnP BEW Digi',
		einheit: Einheit.bew,
		pro3GpPos: { posId: 121, group: 'vuv', posText: 'P-Sach o.Kr/U oAnP BEW Digi' },
		visibleForGps: allExceptSV,
		boniStufen: 3,
	}),
);
agtPosDef.push(
	new AgtGpPosModel({
		year: 2025,
		posId: [129],
		planKey: 'psachokrunfstk',
		einheit: Einheit.stck,
		group: 'vuv',
		posText: 'P-Sach o.Kr/U BZUW Stück',
		visibleForGps: allExceptSV,
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2025,
		posId: [131, 1190251],
		planKey: 'unfallbew',
		einheit: Einheit.bew,
		group: 'vuv',
		posText: 'Unfall Bew o. Anp. Digi',
		boniStufen: 3,
	}),
);

let tempPosId = PosTodo();

agtPosDef.push(
	new AgtGpPosModel({
		year: 2025,
		posId: [tempPosId],
		planKey: 'unfallrisbew',
		group: 'vuv',
		einheit: Einheit.bew,
		posText: 'Unfall Risiko BEW',
		problem: 'nicht in der Datenlieferung enthalten'
	}),
);

tempPosId = PosTodo();
agtPosDef.push(
	new AgtGpPosModel({
		year: 2025,
		posId: [tempPosId],
		planKey: 'unfallkusew',
		group: 'vuv',
		einheit: Einheit.bew,
		posText: 'Unfall KUS BEW',
		problem: 'nicht in der Datenlieferung enthalten'
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2025,
		posId: [139],
		planKey: 'unfallrisbestzuwst',
		einheit: Einheit.stck,
		group: 'vuv',
		posText: 'Unfall Risiko BZW Stück',
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2025,
		posId: [141],
		planKey: 'khpwklfnstdigi',
		group: 'vuv',
		posText: 'Kraft Haft PKW LfNST (Digi)',
		einheit: Einheit.bew,
		visibleForGps: allExceptSV,
		boniStufen: 2,
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2025,
		posId: [151],
		planKey: 'fsachokraftoanpbew',
		group: 'vuv',
		posText: 'Sach Firmen o. Kraft Bewertung oAnp',
		einheit: Einheit.bew,
		visibleForGps: allExceptSV,
		boniStufen: 3,
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2025,
		posId: [161],
		planKey: 'vaglebenbewdigi',
		pro3GpPos: { posId: 151 },
		group: 'vuv',
		posText: 'Leben Bewertung oZE (Digi)',
		einheit: Einheit.bew,
		visibleForGps: allExceptSV,
	}),
);
agtPosDef.push(
	new AgtGpPosModel({
		year: 2025,
		posId: [211],
		planKey: 'krankenzusatzbewdigi',
		group: 'vuv',
		posText: 'Kranken Zusatz Bew oDyn (Digi)',
		einheit: Einheit.bew,
		visibleForGps: allExceptSV,
		boniStufen: 3,
	}),
);
agtPosDef.push(
	new AgtGpPosModel({
		year: 2025,
		posId: [214],
		planKey: 'krankenzuwachsdigi',
		group: 'vuv',
		posText: 'Kranken Zuwachs (versicherte Personen)',
		einheit: Einheit.bew,
		visibleForGps: allExceptSV,
		boniStufen: 3,
	}),
);


// GP PLUS
agtPosDef.push(
	new AgtGpPosModel({
		year: 2025,
		posId: [1180130, 1190130],
		planKey: 'lebenknpebdigi',
		group: 'gpplus',
		posText: 'Leben Einmalbetrag Bewertung oZE (Bew)',
		einheit: Einheit.bew,
	}),
);
agtPosDef.push(
	new AgtGpPosModel({
		year: 2025,
		posId: [1180150, 1190150],
		planKey: 'wiederanlagesumme',
		group: 'gpplus',
		posText: 'Wiederanlagesumme',
		einheit: Einheit.bew,
	}),
);
agtPosDef.push(
	new AgtGpPosModel({
		year: 2025,
		posId: [1180220, 1190220],
		planKey: 'krankenbkvbew',
		einheit: Einheit.bew,
		group: 'gpplus',
		posText: 'Kranken bKV Bewertung oDyn',
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2025,
		posId: [1180230, 1190230],
		planKey: 'krankenneuebefkvgv',
		einheit: Einheit.bew,
		group: 'gpplus',
		posText: 'Kranken bKV neue befüllte Gruppenverträge',
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2025,
		posId: [1180240, 119040],
		planKey: 'krankenofmneuetarifstueck',
		einheit: Einheit.bew,
		group: 'gpplus',
		posText: 'Kranken OFM neue Tarfistück',
	}),
);




agtPosDef.push(
	new AgtGpPosModel({
		year: 2025,
		posId: [1180300, 1190300],
		planKey: 'weiterbildungfonds',
		einheit: Einheit.std,
		group: 'gpplus',
		posText: 'Weiterbildung Kapitalmarktwissen',
		visibleForGps: allExceptBasis,
	}),
);
agtPosDef.push(
	new AgtGpPosModel({
		year: 2025,
		posId: [1180301, 1190301],
		planKey: 'fondsbew',
		einheit: Einheit.bew,
		group: 'gpplus',
		posText: 'Fonds Bewertung',
		visibleForGps: allExceptBasis,
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2025,
		posId: [1180311, 1190311],
		planKey: 'fondsnettowmittelzufluss',
		einheit: Einheit.bew,
		group: 'gpplus',
		posText: 'Fonds Nettomittelzufluss',
		visibleForGps: allExceptBasis,
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2025,
		posId: [1180401, 1190401],
		planKey: 'baufinanzierungbew',
		group: 'gpplus',
		posText: 'Baufinanzierung Allianz Leben Bew',
		einheit: Einheit.bew,
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2025,
		posId: [1180404, 1190404],
		planKey: 'risikiolvbaufi',
		einheit: Einheit.stck,
		group: 'gpplus',
		posText: 'Leben Risiko i.V. Baufi AZL',
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2025,
		posId: [1180421, 1190421],
		planKey: 'baufinanzierungwuro',
		einheit: Einheit.bew,
		group: 'gpplus',
		posText: 'Baufinanzierung Wüstenrot Bewertung',
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2025,
		posId: [1180450, 1190450],
		planKey: 'bausparenneustueck',
		einheit: Einheit.stck,
		group: 'gpplus',
		posText: 'Bausparen Neustück',
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2025,
		posId: [1180451, 1190451],
		planKey: 'bausparenbewertung',
		einheit: Einheit.bew,
		group: 'gpplus',
		posText: 'Bausparen Bewertung m. GG Regel',
	}),
);

// Incentives: Basis
agtPosDef.push(
	new AgtGpPosModel({
		year: 2025,
		posId: [1180700, 1190700],
		planKey: 'lfirmenknp80ris',
		einheit: Einheit.stck,
		group: 'incfuerfach',
		posText: 'VAG: L-Firmen KNP80 + Ris',
		visibleForGps: onlyPro3,
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2025,
		posId: [1180710, 1190710],
		planKey: 'krankenbkVbew',
		einheit: Einheit.stck,
		group: 'incfuerfach',
		posText: 'VAG:L-F KNP80+R F-Online',
		visibleForGps: onlyPro3,
	}),
);

export const AgtPositionen2025 = agtPosDef;

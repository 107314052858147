import { useEffect, useRef } from 'react';
import BaseContainer from 'app/containers/BaseContainer';
import { AgenturList, AgenturListItems } from 'app/components/agt/AgenturList';
import { MainMenu } from './MainMenu';
import { RouteComponentProps } from 'react-router-dom';
import { ADADetail, AgenturDetail } from 'app/components/agt/AgenturDetail';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { useHistory, useLocation, useParams } from 'react-router';
import { AgtQueryStringSetter } from './BerichteContainer';
import { AgenturListsFilter, AgenturMapsFilter } from 'app/components/agt/AgenturListFilter';
import { Button } from 'app/components/common/Button';
import { Icon } from 'app/components/common/Icon';
import Config from 'Config';
import { AgenturMap } from 'app/components/agt/AgenturMap';
import { AgenturExtendedVM } from 'app/stores/ui/agt.list.ui.store';
import routes from 'routes';
import { AgtType } from 'app/models/agentur.model';

const AgenturNavBar = observer(() => {
	return <div>Agenturen</div>;
});

const AgenturActions = observer(() => {
	const { uiStore, session } = useStore();

	const toggleMap = () => {
		uiStore.toggleMap();
	};

	const openTempAgtModal = () => {
		uiStore.showModal(uiStore.modalIds.newTempAgentur);
	};

	return (
		<>
			{session.currentUser && (
				<div className="navbar-actions">
					<Button className="button is-inverted is-primary" onClick={toggleMap}>
						{uiStore.showMap ? (
							<>
								<Icon iconClass="list-ul" />
								<span>LISTE</span>
							</>
						) : (
							<>
								<Icon iconClass="globe-africa" />
								<span>KARTE</span>
							</>
						)}
					</Button>

					{session.currentUser.isVbl && !uiStore.showMap && (
						<Button className="button is-inverted is-primary" onClick={openTempAgtModal} data-tooltip="Temporäre Agentur anlegen" data-tooltip-position="left">
							<Icon iconClass="plus" />
						</Button>
					)}
				</div>
			)}
		</>
	);
});
const AgenturContainer = observer((props: RouteComponentProps) => {
	const { agenturUiStore, uiStore, berichteUiiStore } = useStore();
	const history = useHistory();
	const location = useLocation();

	const agt = agenturUiStore.current;

	let mapRef = useRef<HTMLDivElement>(null);

	const selectAgt = (agt: AgenturExtendedVM | undefined) => {
		if (!agt) {
			return;
		}
		let url = routes.AGENTUR.getPath(agt.agtId.toString(), agenturUiStore.currentPanel, berichteUiiStore.menu.current?.path);
		if (location.search) {
			url += location.search;
			console.log(url);
		}

		if (history.location.pathname !== url) {
			history.push(url);
		}
	};
	return (
		<BaseContainer sideMenu={<MainMenu {...props} />} navbar={<AgenturNavBar />} actionControls={<AgenturActions />}>
			<AgtQueryStringSetter />
			<AgenturLoader />
			<AgenturListItems onSelectAgt={selectAgt} />

			{uiStore.showMap && <></>}

			{agt && agt.agtType === AgtType.ada ? <ADADetail /> : <AgenturDetail />}

			{uiStore.showMap ? (
				<div className="agt-map-container">
					<div className="map" id="map" ref={mapRef}>
						This is where the map will render
					</div>
					<AgenturMapsFilter hideAgt={true} />
					<AgenturMap mapRef={mapRef} onSelectAgt={selectAgt} />
				</div>
			) : (
				<div className="agt-list-container">
					<AgenturListsFilter hideAgt={true} />
					<AgenturList onSelectAgt={selectAgt} />
				</div>
			)}
		</BaseContainer>
	);
});

export interface AgenturParamTypes {
	agtId?: string;
	panel?: string;
	level1?: string;
	level2?: string;
	level3?: string;
	level4?: string;
	level5?: string;
}

const AgenturLoader = observer(() => {
	const { agenturUiStore, agtPlanUiStore, agenturListUiStore, berichteUiiStore, anwendungenUiStore } = useStore();
	const { agtId, panel, level1, level2, level3, level4, level5 } = useParams<AgenturParamTypes>();

	// load the agt
	useEffect(() => {
		agenturUiStore.loadById(agtId, panel, level1, level2);
	}, [agenturUiStore, agtId, panel, level1, level2]);

	// planungs loader
	useEffect(() => {
		if (panel === 'planung' && agenturUiStore.currentAgtId) {
			const yString = level1;
			let year = agtPlanUiStore.currentYear;
			if (yString && yString.length === 4) {
				try {
					year = parseInt(yString);
				} catch (ex) { }
			}
			agtPlanUiStore.setCurrent(agenturUiStore.currentAgtId, year);
		}
		// agenturUiStore.loadById(agtId, panel, level1, level2);
		// berichteUiiStore.menu.setActivePath(level1, level2, level3, level4, level5);
		// berichteUiiStore.menu.openByPath(level1, level2, level3, level4, level5);

		// anwendungenUiStore.menu.setActivePath(level1, level2, level3, level4);
		// anwendungenUiStore.menu.openByPath(level1, level2, level3, level4);
	}, [agenturUiStore, agtPlanUiStore, agenturUiStore.currentAgtId, agtPlanUiStore.currentYear, agtId, panel, level1, level2, level3, level4, level5]);

	// berichte & anwendungen navi
	useEffect(() => {
		if (panel === 'berichte' || panel === 'anwendungen') {
			berichteUiiStore.isInAgt = true;
			anwendungenUiStore.isInAgt = true;
			if (agtId) {
				const _agtId = parseInt(agtId);
				berichteUiiStore.setSelectedAgtById(_agtId);
				anwendungenUiStore.setSelectedAgtById(_agtId);
			} else {
				berichteUiiStore.setSelectedAgtById();
				anwendungenUiStore.setSelectedAgtById();
			}
			berichteUiiStore.menu.setActivePath(level1, level2, level3, level4, level5);
			berichteUiiStore.menu.openByPath(level1, level2, level3, level4, level5);

			anwendungenUiStore.menu.setActivePath(level1, level2, level3, level4);
			anwendungenUiStore.menu.openByPath(level1, level2, level3, level4);
		}
	}, [berichteUiiStore, anwendungenUiStore, agtId, panel, level1, level2, level3, level4, level5]);

	useEffect(() => {
		agenturListUiStore.load();
	}, [agenturListUiStore]);
	return <></>;
});

export default AgenturContainer;

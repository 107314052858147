import { FitnessPlanPdfDownloadButton } from 'app/components/berichte/fitnessplan/FitnessPlanPdfDownloadButton';
import { FitnessTrackerPdfDownloadButton } from 'app/components/berichte/fitnesstracker/FitnessTrackerPdfDownloadButton';
import Widget, { IWidgetConsumer } from 'app/components/common/Widget';
import { WidgetNavigate } from 'app/components/common/WidgetNavigate';
import { useStore } from 'app/context';
import { AgtFitnessVm } from 'app/stores/ui/agt.fitness.ui.store';
import { formatPercentWithIcon } from 'app/utils';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import routes from 'routes';

export const AgenturFitnessWidget = observer((props: IWidgetConsumer) => {
	const { agenturUiStore, agtFitnessUiStore } = useStore();
	const agt = agenturUiStore.current;
	const [fitVm, setFitVm] = useState<AgtFitnessVm | undefined>(undefined);

	useEffect(() => {
		agtFitnessUiStore.load().then(() => {
			let items = agtFitnessUiStore.items;
			if (agt && items.length > 0) {
				items = items.filter((i) => i.agt.agtId === agt.agtId);
				setFitVm(items[0]);
			}
		});
	});

	if (!fitVm) {
		return null;
	}
	const agtIdStr = fitVm.agt.agtId.toString();
	const navPath = routes.AGENTUR.getPath(agtIdStr, 'berichte', 'bestand', 'fit', 'overview');

	return (
		<Widget title={'Agentur der Zukunft'} modifier="is-paddingless" widgetId={props.widgetKey} action={<WidgetNavigate text={'Zum Bericht'} path={navPath} />}>
			<div className="fitness-widget-grid">
				<div className={`fitness-grid-item bg-green`}>
					<div className="title">GUP</div>
					<div className="value">{formatPercentWithIcon(fitVm.gupAverage.ze)}</div>
				</div>

				<div className={`fitness-grid-item bg-turquoise`}>
					<div className="title">AMIS.NOW</div>
					<div className="value">{formatPercentWithIcon(fitVm.amisNowQuoteRead.ze)}</div>
				</div>

				{fitVm.isAkumaPrimeUser ? (
					<div className={`fitness-grid-item bg-yellow`}>
						<div className="title">AKUMA Prime</div>
						<div className="value">{formatPercentWithIcon(fitVm.primeEinordnungsquote.ze)}</div>
					</div>
				) : (
					<div className={`fitness-grid-item bg-yellow`}>
						<div className="title">AKUMA Planer</div>
						<div className="value">{formatPercentWithIcon(fitVm.planerUebertragungquoteAmisNow.ze)}</div>
					</div>
				)}

				<div className={`fitness-grid-item bg-blue`}>
					<div className="title">mAZ</div>
					<div className="value">{formatPercentWithIcon(fitVm.mazBestandDurchdringungsquote.ze)}</div>
				</div>
			</div>

			<div className="pad-1rem grid is-col-half">
				<FitnessTrackerPdfDownloadButton modifier="is-block is-marginless" agtId={fitVm.agt.agtId} />
				<FitnessPlanPdfDownloadButton modifier="is-block is-marginless" agtId={fitVm.agt.agtId} />
			</div>
		</Widget>
	);
});

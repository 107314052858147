import React from 'react';
import BaseContainer from 'app/containers/BaseContainer';
import { observer } from 'mobx-react';
import { MainMenu } from './MainMenu';
import { RouteComponentProps } from 'react-router-dom';

const VerlinkungenContainer = observer((props: RouteComponentProps) => {
	const links = [
		{
			groupTitle: 'AMIS.NOW',
			groupLinks: [
				{
					title: 'AMIS.NOW AnspracheUnterlage Agentur PDF',
					url: 'AMIS_NOW_JPG.pdf',
				},
				{
					title: 'AMIS.NOW - Der Umzug | amisonline.allianz.de',
					url: 'https://amisonline.allianz.de/agentur-und-vertrieb/beratung-und-verkauf/amis-now/der-umzug.html',
				},
				{
					title: 'AMIS.NOW - 20241030_umzugscheckliste.pdf (allianz.de)',
					url: 'https://amisonline.allianz.de/content/dam/onemarketing/dechap/common/amis/documents/agentur-und-vertrieb/beratung-und-verkauf/amis-now/der-umzug/20241030_umzugscheckliste.pdf',
				},
				{
					title: 'AMIS.NOW - Übergangslösungen',
					url: 'https://amisonline.allianz.de/agentur-und-vertrieb/beratung-und-verkauf/amis-now/der-umzug/arbeiten-mit-amis-now.html',
				},
				{
					title: 'AMIS.NOW Digitalen Lernwelt',
					url: 'https://akademien.allianz.de/app.html#!/training/943941358',
				},
				{
					title: 'Webcast-Kalender | amisonline.allianz.de',
					url: 'https://amisonline.allianz.de/ansprechpartner/digitale-spezialisten-einheit-dse/webcast-kalender.html',
				},
			],
		},
		{
			groupTitle: 'Agentur der Zukunft',
			groupLinks: [
				{
					title: 'Leitfaden',
					url: 'Fitness_Leitfaden.pdf',
				},
			],
		},
		{
			groupTitle: 'Weitere',
			groupLinks: [
				{
					title: 'Erfassungskriterien',
					url: 'https://amisonline.allianz.de/agentur-und-vertrieb/organisation-und-finanzen/finanzen/geschaeftsplaene.html',
				},
				{
					title: 'Primavista',
					url: 'https://primavista.allianz-people.de/login?ReturnUrl=%2F',
				},
				{
					title: 'ProGress',
					url: 'https://avc-portal.srv.allianz/Citrix/SmartCardWeb/',
				},
			],
		},
	];

	return (
		<BaseContainer sideMenu={<MainMenu {...props} />} navbar={<div>Verlinkungen</div>}>
			<section className="section">
				<div className="content">
					<h4>Nützliche Verlinkungen</h4>
					<p>
						Über diese Links gelangen Sie direkt zur jeweiligen Anmeldeseite. <br />
						Progress und Erfassungskriterien funktionieren nur im Allianz-Netz.
					</p>

					{links.map((group, i) => (
						<div key={i}>
							<strong>{group.groupTitle}</strong>
							<ul className="links-list">
								{group.groupLinks.map((link, j) => (
									<li key={j}>
										<a href={link.url} title={link.title} target="_blank" rel="noreferrer">
											{link.title}
										</a>
									</li>
								))}
							</ul>
						</div>
					))}
				</div>
			</section>
		</BaseContainer>
	);
});

export default VerlinkungenContainer;

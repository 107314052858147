import { AgtExpertisenModel } from "app/models/agentur.model";
import { computed, makeObservable, runInAction } from "mobx";
import { BaseUiStore } from "./base.ui.store";
import { KvAgtCustomExpertiseStore } from "../kv.agt.custom.expertise.store";
import { AgenturExtendedVM, AgenturListUiStore } from "./agt.list.ui.store";

export class AgtExpertiesenVm {
    agt: AgenturExtendedVM;
    exp: AgtExpertisenModel[];
    constructor(agt: AgenturExtendedVM, exp: AgtExpertisenModel[]) {
        this.agt = agt;
        this.exp = exp;
    }

    @computed
    get wochenMailEndingExpertisen() {
        return this.exp.filter((e) => e.displayInWochenmail);
    }
}

export class AgtExpertiesenUiStore extends BaseUiStore<AgtExpertiesenVm> {
    name = 'AgtExpertiesenUiStore';
    kvAgtCustomExpertiseStore: KvAgtCustomExpertiseStore;
    agtListUiStore: AgenturListUiStore;

    constructor(agtListUiStore: AgenturListUiStore, kvAgtCustomExpertiseStore: KvAgtCustomExpertiseStore) {
        super();
        makeObservable(this);
        this.kvAgtCustomExpertiseStore = kvAgtCustomExpertiseStore;
        this.agtListUiStore = agtListUiStore;
    }


    customClearCache() {
        this.kvAgtCustomExpertiseStore.clearCache();

    }

    async _baseLoad() {
        await Promise.all([this.kvAgtCustomExpertiseStore.findAll(), this.agtListUiStore.load()])
        const res: AgtExpertiesenVm[] = [];
        for (let index = 0; index < this.agtListUiStore.items.length; index++) {
            const agt = this.agtListUiStore.items[index];
            const customExp = await this.kvAgtCustomExpertiseStore.findByAgtId(agt.agtId);
            const exp: AgtExpertisenModel[] = [];

            // if (agt.agtId === 101400652) {
            //     debugger;
            // }

            const aladinExps = agt.aladin.expertisen.filter((e) => e.bit === 1);
            aladinExps.forEach((a) => {
                const e = new AgtExpertisenModel({
                    id: 'aladin',
                    type: 'aladin',
                    text: a.text,
                    ablauf: a.end,
                });
                exp.push(e);
            });

            customExp.forEach((c) => {
                const e = new AgtExpertisenModel({
                    id: c.id,
                    type: 'custom',
                    text: c.text,
                    ablauf: c.ablauf,
                });
                exp.push(e);
            });

            res.push(new AgtExpertiesenVm(agt, exp));
        };
        runInAction(() => {
            this.items = res;
        });

    }


}
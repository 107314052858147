import { AgenturModel } from 'app/models/agentur.model';
import { AgenturStore } from '../agentur.store';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import { ISelectOpt } from 'app/components/common/Select';
import { formatKw } from 'app/utils';
import _ from 'lodash';
import { BaseUiStore } from './base.ui.store';
import { KvAgtMsLiteModel } from 'app/models/kv.agt.ms.lite.model';
import { KvAgtKvmStore } from '../kv.agt.kvm.store';
import moment from 'moment';

// interface ILeadNowAbos {
// 	path: string;
// 	label: string;
// 	val?: number;
// }

// export enum LeadNowType {
// 	brutto = 'brutto',
// 	netto = 'netto',
// }

// export const LeadNowAbos: ILeadNowAbos[] = [
// 	{ path: 'av', label: 'Altersvorsorge', val: 0 },
// 	{ path: 'baufi', label: 'Baufinanzierung', val: 0 },
// 	{ path: 'bausp', label: 'Bausparen', val: 0 },
// 	{ path: 'bu', label: 'Berufsunfähigkeit', val: 0 },
// 	{ path: 'fsach', label: 'Firmen Sach', val: 0 },
// 	{ path: 'kfz', label: 'Kfz und Motorrad', val: 0 },
// 	{ path: 'kv', label: 'Krankenvoll', val: 0 },
// 	{ path: 'kzus', label: 'Krankenzusatz und Pflege', val: 0 },
// 	{ path: 'psach', label: 'Privat Sach', val: 0 },
// 	{ path: 'reise', label: 'Reise', val: 0 },
// 	{ path: 'tier', label: 'Tier', val: 0 },
// 	{ path: 'berat', label: 'Übergreifende Beratung', val: 0 },
// 	{ path: 'verm', label: 'Vermögensaufbau', val: 0 },
// ];

export class KvmKw {
	kw: number;
	anzahlAnrufeGesamt: number;
	anzahlAnrufeWaehrendServicezeiten: number;
	ziel1Schaden: number;
	ziel2Vertragsanliegen: number;
	ziel3SonstigeAnliegen: number;
	ziel3DannRetoure: number;
	retoure: number;
	keineTaste: number;
	schnellauflege: number;

	constructor(kw: number, j: any) {
		this.kw = kw;
		this.anzahlAnrufeGesamt = j.a || 0;
		this.anzahlAnrufeWaehrendServicezeiten = j.asz || 0;
		this.ziel1Schaden = j.zs || 0;
		this.ziel2Vertragsanliegen = j.zv || 0;
		this.ziel3SonstigeAnliegen = j.za || 0;
		this.ziel3DannRetoure = j.zr || 0;
		this.retoure = j.r || 0;
		this.keineTaste = j.kt || 0;
		this.schnellauflege = j.sa || 0;
	}
}

export class KvmVM {
	agt: AgenturModel;
	@observable
	items: KvmKw[] = [];

	// make it easier to get the computeds
	[key: string]: any;

	constructor(agt: AgenturModel, kvs: KvAgtMsLiteModel[]) {
		makeObservable(this);
		this.agt = agt;

		for (const kv of kvs) {
			const j = kv.j ? kv.j : {};
			this.items.push(new KvmKw(kv.bm, j));
		}
	}

	@computed
	get anzahlAnrufeGesamtYtd() {
		return this._sumYtd('anzahlAnrufeGesamt');
	}

	_sumYtd(abo: string) {
		const year = moment().year();
		let f = this.items.filter((i) => i.kw >= year * 100);
		return _.sumBy(f, abo);
	}

	// @computed
	// get av() {
	// 	return this._sumByAbo('av');
	// }
	// @computed
	// get baufi() {
	// 	return this._sumByAbo('baufi');
	// }
	// @computed
	// get bausp() {
	// 	return this._sumByAbo('bausp');
	// }
	// @computed
	// get bu() {
	// 	return this._sumByAbo('bu');
	// }
	// @computed
	// get fsach() {
	// 	return this._sumByAbo('fsach');
	// }
	// @computed
	// get kfz() {
	// 	return this._sumByAbo('kfz');
	// }
	// @computed
	// get kv() {
	// 	return this._sumByAbo('kv');
	// }
	// @computed
	// get kzus() {
	// 	return this._sumByAbo('kzus');
	// }
	// @computed
	// get psach() {
	// 	return this._sumByAbo('psach');
	// }
	// @computed
	// get reise() {
	// 	return this._sumByAbo('reise');
	// }
	// @computed
	// get tier() {
	// 	return this._sumByAbo('tier');
	// }
	// @computed
	// get berat() {
	// 	return this._sumByAbo('berat');
	// }
	// get verm() {
	// 	return this._sumByAbo('verm');
	// }
}

export class AgtKvmUiStore extends BaseUiStore<KvmVM> {
	name = 'AgtKvmUiStore';
	kvAgtKvmStore: KvAgtKvmStore;
	agenturStore: AgenturStore;

	constructor(agenturStore: AgenturStore, kvAgtKvmStore: KvAgtKvmStore) {
		super();
		makeObservable(this);
		this.kvAgtKvmStore = kvAgtKvmStore;
		this.agenturStore = agenturStore;
	}
	@observable
	kws: number[] = [];

	@computed
	get kwsOpts(): ISelectOpt[] {
		return this.kws.map((kw) => {
			return {
				value: kw,
				label: formatKw(kw),
			};
		});
	}

	@computed
	get minKw(): number {
		if (this.minKwCurrent) {
			return this.minKwCurrent;
		}

		return 0;
	}

	@computed
	get maxKw(): number {
		if (this.maxKwCurrent) {
			return this.maxKwCurrent;
		}

		return 0;
	}

	@observable
	minKwCurrent?: number;

	@observable
	maxKwCurrent?: number;

	// @computed
	// get currentKWItems() {
	// 	const kvVmItems: LeadNowVM[] = [];
	// 	this.items.forEach((vm) => {
	// 		const f = vm.items.filter((i) => {
	// 			if (this.minKw && i.kw >= this.minKw) {
	// 				if (this.maxKw && i.kw <= this.maxKw) {
	// 					return true;
	// 				}
	// 			}
	// 			return false;
	// 		});
	// 		const r = new LeadNowVM(vm.agt, []);
	// 		runInAction(() => {
	// 			r.items = f;
	// 		});
	// 		kvVmItems.push(r);
	// 	});

	// 	return kvVmItems;
	// }

	async _baseLoad() {
		const data = await this.kvAgtKvmStore.findAll();
		const agts = await this.agenturStore.findAll();
		const res: KvmVM[] = [];
		for (const agt of agts) {
			const kvs = data.filter((d) => d.agtId === agt.agtId);
			if (kvs.length > 0) {
				const vm = new KvmVM(agt, kvs);
				res.push(vm);
			}
		}
		runInAction(() => {
			this.kws = _.uniq(_.map(data, 'bm')).sort();
			this.minKwCurrent = this.kws[0];
			this.maxKwCurrent = this.kws[this.kws.length - 1];
			this.items = res;
		});
	}
}

import { BranchenplanungPosDef } from 'app/models/branchenplanung/bp.pos.def';
import _ from 'lodash';
import { computed, observable } from 'mobx';
import { AgtBpBaseModel, UmrechFaktor } from '../agt.bp.store';
import { SessionStore } from '../session.store';
import { AgtBpVM, AgtBranchenplanungUiStore } from './agt.branchenplanung.ui.store';
import { calcZielErriechung, calcZielErriechungZeit } from 'app/utils';
import moment from 'moment';

export class BnrBpSummaryVM extends AgtBpBaseModel {
	@observable totalAgtCount?: number;
	@observable baufiCount?: number;
	@observable bausparCount?: number;
	@observable bestandsZuwachsCount?: number;
	@observable firmenSachOhneKraftCount?: number;
	@observable fondsCount?: number;
	@observable gesamtBewertungCount?: number;
	@observable kfzHaftPkwCount?: number;
	@observable krankenCount?: number;
	@observable lebenCount?: number;
	@observable psachOhneKraftUnfallCount?: number;
	@observable unfallCount?: number;
}

export class BnrBranchenplanungUiStore {
	agtBranchenplanungUiStore: AgtBranchenplanungUiStore;
	session: SessionStore;
	constructor(session: SessionStore, agtBranchenplanungUiStore: AgtBranchenplanungUiStore) {
		this.agtBranchenplanungUiStore = agtBranchenplanungUiStore;
		this.session = session;
	}
	async getBnrSummaryByYear(year: number, isGsl: boolean, bnrId?: number) {
		await this.agtBranchenplanungUiStore.agtRingUiStore.findAll(year);
		await this.agtBranchenplanungUiStore.load();
		let agtYearItems = this.agtBranchenplanungUiStore.items.filter((i) => i.year === year);
		if (bnrId) {
			agtYearItems = agtYearItems.filter((i) => i.agt.bnrIdCanSee(bnrId));
		}

		const opts = {
			bnrId: this.session.currentUser?.bnrId,
			agtId: -1,
			year: year,
			user: this.session.currentUser,
		};
		const vm = new AgtBpVM(opts, {} as any, [], []);

		vm.totalAgtCount = agtYearItems.length;

		if (isGsl) {
			agtYearItems.forEach((i) => {
				vm.add(i.GSL);
				vm.ringZiel.add(i.ringZiel);
				vm.ringErgebnis.add(i.ringErgebnis);
				vm.countPlanned.add(i.countPlanned);
				vm.totalCountPlanned += i.totalCountPlanned;
			});
		} else {
			agtYearItems.forEach((i) => {
				vm.add(i);
				vm.ringZiel.add(i.ringZiel);
				vm.ringErgebnis.add(i.ringErgebnis);
				vm.countPlanned.add(i.countPlanned);
				vm.totalCountPlanned += i.totalCountPlanned;
			});
		}

		let month = 1;
		const ds = this.agtBranchenplanungUiStore.agtProdUiStore.posAgtZielStore.getDatenStandByYear(year);
		if (ds) {
			month = moment(ds).month() + 1;
		}

		BranchenplanungPosDef.forEach((pos) => {

			let ziel = _.get(vm, pos.planKey);
			if (pos.planKey === 'bestandsZuwachs') {
				ziel = ziel / (vm.countPlanned.bestandsZuwachs ? vm.countPlanned.bestandsZuwachs : 1);
				_.set(vm, pos.planKey, ziel);
			}


			// const ziel = _.get(vm.GSL, pos.planKey);
			//  console.log()
			const ergebnis = _.get(vm.ringErgebnis, pos.planKey);
			// @ts-ignore
			vm.ringZE[pos.planKey] = calcZielErriechung(ziel, ergebnis);
			// @ts-ignore
			vm.ringZEZeit[pos.planKey] = calcZielErriechungZeit(ziel, ergebnis, month);
		});

		return vm;
	}

	@computed
	get currentUmrechnungsFaktor() {
		return UmrechFaktor.bewertung;
	}
}

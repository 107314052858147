import { Icon } from 'app/components/common/Icon';
import { PercentAmpel } from 'app/components/common/PercentAmpel';
import { AgenturNameCell } from 'app/components/shared/AgenturName.cell';
import { Table } from 'app/components/table/table';
import { AggregrationType, TableModel, TableRow } from 'app/components/table/table.model';
import { useStore } from 'app/context';
import { DigitalReportVM } from 'app/stores/ui/agt.digitalreport.ui.store';
import { formatNum } from 'app/utils';
import { observer } from 'mobx-react';
import { useEffect, useMemo } from 'react';

export const DigitalreportListe = observer(() => {
	const { agtDigitalreportUiStore, berichteUiiStore, agenturListUiStore } = useStore();

	const tm = useMemo(() => {
		const getAgg = (path: string) => {
			return [
				{
					path,
					format: formatNum,
				},
				{
					path,
					format: formatNum,
					aggType: AggregrationType.avg,
				},
				{
					path,
					format: formatNum,
					aggType: AggregrationType.count,
				},
			];
		};

		const getAggProz = (path: string, yellow: number, green: number) => {
			return [
				{
					path,
					format: formatNum,
					aggType: AggregrationType.avg,
					render: (tm: TableModel<DigitalReportVM, number>, aggValue?: number) => {
						if (!aggValue) return <></>;
						return <PercentAmpel value={aggValue} yellowMin={yellow} greenMin={green} />;
					},
				},
			];
		};

		const tm = new TableModel<DigitalReportVM, number>();
		const cols = [
			{
				label: 'Agentur',
				path: 'agt.name',
				render: AgenturNameCell,
			},
			{
				label: ['Bestand', 'Kunden 18-70 Jahre'],
				path: 'pos.anzKunden18',
				agg: getAgg('pos.anzKunden18'),
			},
			{
				label: 'Digital ID',
				path: 'pos.prozDigiId18',
				agg: getAggProz('pos.prozDigiId18', 60, 85),
				render: (row: TableRow<DigitalReportVM>) => {
					return <PercentAmpel value={row.data.pos.prozDigiId18} yellowMin={60} greenMin={85} />;
				},
			},
			{
				label: 'wEWE',
				path: 'pos.prozWewe18',
				agg: getAggProz('pos.prozWewe18', 35, 60),
				render: (row: TableRow<DigitalReportVM>) => {
					return <PercentAmpel value={row.data.pos.prozWewe18} yellowMin={35} greenMin={60} />;
				},
			},
			{
				label: 'mAZ',
				path: 'pos.prozMaz18',
				agg: getAggProz('pos.prozMaz18', 30, 60),
				render: (row: TableRow<DigitalReportVM>) => {
					return <PercentAmpel value={row.data.pos.prozMaz18} yellowMin={30} greenMin={60} />;
				},
			},
			{
				label: ['Neugeschäft', 'Neukunden'],
				path: 'pos.anzKundenNeu',
				agg: getAgg('pos.anzKundenNeu'),
				cellModifier: 'border-left-blue bg-blue',
				labelModifier: 'border-left-blue bg-blue',
			},
			{
				label: 'Digital ID',
				path: 'pos.prozDigiIdNeu',
				agg: getAggProz('pos.prozDigiIdNeu', 90, 95),
				cellModifier: 'bg-blue',
				labelModifier: 'bg-blue',
				render: (row: TableRow<DigitalReportVM>) => {
					return <PercentAmpel value={row.data.pos.prozDigiIdNeu} yellowMin={90} greenMin={95} />;
				},
			},
			{
				label: 'wEWE',
				path: 'pos.prozWeweNeu',
				agg: getAggProz('pos.prozWeweNeu', 40, 70),
				cellModifier: 'bg-blue',
				labelModifier: 'bg-blue',
				render: (row: TableRow<DigitalReportVM>) => {
					return <PercentAmpel value={row.data.pos.prozWeweNeu} yellowMin={40} greenMin={70} />;
				},
			},
			{
				label: 'mAZ',
				path: 'pos.prozMazNeu',
				agg: getAggProz('pos.prozMazNeu', 50, 75),
				cellModifier: 'bg-blue',
				labelModifier: 'bg-blue',
				render: (row: TableRow<DigitalReportVM>) => {
					return <PercentAmpel value={row.data.pos.prozMazNeu} yellowMin={50} greenMin={75} />;
				},
			},
		];
		tm.setCols(cols);
		tm.sortBy = 'agt.name';
		tm.idProperty = 'id';
		tm.idType = 'number';
		return tm;
	}, []);

	useEffect(() => {
		agtDigitalreportUiStore.load().then(() => {
			let res = agtDigitalreportUiStore.items;
			res = agenturListUiStore.applyFilterOnAgt(res) as DigitalReportVM[];
			if (berichteUiiStore.selectedAgt) {
				const agtId = berichteUiiStore.selectedAgt.agtId;
				res = res.filter((d) => d.agt.agtId === agtId);
			}
			tm.setRowData(res);
		});
	}, [tm, agtDigitalreportUiStore, agtDigitalreportUiStore.items, berichteUiiStore.selectedAgt, agenturListUiStore.filteredItems, agenturListUiStore.filterHasChanged, agenturListUiStore]);
	return (
		<>
			<div className="table-container">
				<Table tm={tm} stickyHeader={true}></Table>

				<div className="pad-1rem">
					<div className="note-box content page-break-avoid">
						<p>
							<Icon iconClass="info-circle" />
						</p>
						<div className="note-message">
							<p>
								<strong>Legende:</strong>
							</p>

							<table className="table">
								<thead>
									<tr>
										<th></th>
										<th>Digital ID</th>
										<th>wEWE</th>
										<th>mAZ</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<strong>Bestand</strong>
										</td>
										<td>
											<span className={`circle color-error`}>&#11044;</span> 0 - 59% <br />
											<span className={`circle color-warning`}>&#11044;</span> 60 - 84% <br />
											<span className={`circle color-success`}>&#11044;</span> &gt; 85% <br />
										</td>
										<td>
											<span className={`circle color-error`}>&#11044;</span> 0 - 34% <br />
											<span className={`circle color-warning`}>&#11044;</span> 35 - 59% <br />
											<span className={`circle color-success`}>&#11044;</span> &gt; 60% <br />
										</td>
										<td>
											<span className={`circle color-error`}>&#11044;</span> 0 - 29% <br />
											<span className={`circle color-warning`}>&#11044;</span> 30 - 59% <br />
											<span className={`circle color-success`}>&#11044;</span> &gt; 60% <br />
										</td>
									</tr>
									<tr>
										<td>
											<strong>Neugeschäft</strong>
										</td>
										<td>
											<span className={`circle color-error`}>&#11044;</span> 0 - 89% <br />
											<span className={`circle color-warning`}>&#11044;</span> 90 - 94% <br />
											<span className={`circle color-success`}>&#11044;</span> &gt; 95% <br />
										</td>
										<td>
											<span className={`circle color-error`}>&#11044;</span> 0 - 39% <br />
											<span className={`circle color-warning`}>&#11044;</span> 40 - 69% <br />
											<span className={`circle color-success`}>&#11044;</span> &gt; 70% <br />
										</td>
										<td>
											<span className={`circle color-error`}>&#11044;</span> 0 - 49% <br />
											<span className={`circle color-warning`}>&#11044;</span> 50 - 74% <br />
											<span className={`circle color-success`}>&#11044;</span> &gt; 75% <br />
										</td>
									</tr>
								</tbody>
							</table>

							<div className="grid is-col-auto pad-top-1rem pad-bottom-1rem">
								<span className={`circle bg-color-error`} />
								<span>
									Ihr Kundenzugang ist noch nicht zukunftsfähig aufgestellt: Sie befinden sich im internen Vergleich unter den Agenturen mit der geringsten Durchdringung im Bestand/
									Neugeschäft. Hier besteht höchster Handlungsdruck, um Ihre Kunden und Kundinnen auch zukünftig persönlich und digital erreichen zu können.
								</span>
							</div>

							<div className="grid is-col-auto pad-bottom-1rem">
								<span className={`circle bg-color-warning`} />
								<span>
									Ihr Kundenzugang ist nur teilweise zukunftsfähig aufgestellt: Die Durchdringung ist im internen Vergleich mit anderen Agenturen leicht überdurchschnittlich. Sie
									erreichen allerdings nur Teile Ihres Bestands persönlich und digital. Bauen Sie die Digitalisierung Ihres Bestands weiter aus, um von den Vorteilen digitaler
									Kundenbeziehungen zu profitieren
								</span>
							</div>

							<div className="grid is-col-auto">
								<span className={`circle bg-color-success`} />
								<span>
									Ihr Kundenzugang ist in weiten Teilen bereits gut für die Zukunft aufgestellt: Im internen Agenturvergleich gehören Sie zu den digitalen Top-Performern. Sie
									erreichen oder übertreffen unser Ambitionsniveau, das sich auch aus externen Benchmarks ableitet. Kundenbeziehungen zu profitieren
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
});

import { SessionStore } from './session.store';
import _ from 'lodash';
import { AgtPositionen2024 } from 'app/models/gp/2024/agt.positionen.def';
import { AgtPositionen2025 } from 'app/models/gp/2025/agt.positionen.def';
import { GPMAXYEAR } from './ui/berichte.ui.store';
import { AgtGpPosModel } from 'app/models/gp/agt.gp.pos.model';
import { computed } from 'mobx';

export class AgtGpStore {
	session: SessionStore;
	constructor(session: SessionStore) {
		this.session = session;
	}

	applyRoleFilter(positionen: AgtGpPosModel[]) {
		if (!this.session.currentUser) {
			return [];
		}
		if (this.session.currentUser!.isMulti) {
			return positionen.filter((x) => x.group.id === 'kunde');
		}
		return positionen;
	}

	get agtPositionen() {
		return this.applyRoleFilter(AgtPositionen2025);
	}

	agtPositionenByYear(year: number) {
		if (year === GPMAXYEAR) {
			return this.agtPositionen;
		}
		return this.agtPositionenVorjahr;
	}

	get agtPositionenVorjahr() {
		return this.applyRoleFilter(AgtPositionen2024);
	}

	get agtPro3PositionenVorjahr() {
		return this.applyRoleFilter(AgtPositionen2024);
	}

	@computed
	get allPosIds() {
		let ids: number[] = [];
		this.agtPositionen.forEach((p) => {
			ids = ids.concat(p.posIds);
		});
		this.agtPositionenVorjahr.forEach((p) => {
			ids = ids.concat(p.posIds);
		});
		this.agtPro3PositionenVorjahr.forEach((p) => {
			ids = ids.concat(p.posIds);
		});
		ids = ids.concat([90144101, 90144102, 90144103]); // fahrstuhl
		ids = ids.concat([2255852, 2255862, 2255853, 1119040]); // produktivität
		ids = ids.concat([7301010, 7301020, 7301030]); // SA DIGI

		ids = _.uniq(ids);

		return ids;
	}

	findAgtPositionByPlanKey(plankey: string, year: number) {
		const positionen = this.agtPositionenByYear(year);
		const x = positionen.find((p) => p.planKey === plankey);
		if (x) {
			return x;
		}
		const pro3Positionen = this.agtPositionenByYear(year);
		return pro3Positionen.find((p) => p.planKey === plankey);
	}

	// filterByGroups(group: string) {
	// 	return this.agtPositionen.find((p) => p._group === group);
	// }

	getAgtPositionenByGroup(year: number) {
		const positionen = this.agtPositionenByYear(year);

		return _.chain(positionen)
			.groupBy('group.id')
			.map((value, key) => {
				return value;
			})
			.value();
	}
}

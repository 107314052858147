import { Button } from 'app/components/common/Button';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';

interface IFitnessPlanPdfDownloadButton {
	agtId: number;
	modifier?: string;
}

export const FitnessPlanPdfDownloadButton = observer((props: IFitnessPlanPdfDownloadButton) => {
	const { documentUiStore, uiStore, session } = useStore();

	const onClick = async () => {
		try {
			const blob = await documentUiStore.bnrUploadStore.downloadFitnessPlanPdf(props.agtId, true);
			const url = URL.createObjectURL(blob!);
			uiStore.setShowPdf(url);
			return;
		} catch (ex) {
			alert('PDF wurde nicht gefunden.');
			session.decApiCallCount();
		}
	};

	return (
		<Button className={`button is-primary is-small ${props.modifier || ''}`} onClick={onClick}>
			Fitness Plan PDF
		</Button>
	);
});

import { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import _, { debounce } from 'lodash';
import { init, EChartsOption, ECharts } from 'echarts';
import { IAkumaPlanerValues } from 'app/stores/kv.agentur.year.akumaplaner.store';
import { AkumaPlanerArtSchema } from 'app/stores/ui/agt.akumaplaner.ui.store';
import { Button } from 'app/components/common/Button';
import { formatKw } from 'app/utils';
import { useStore } from 'app/context';

interface IAkumaPlanerWochenChart {
	data: IAkumaPlanerValues[];
}

export const AkumaPlanerWochenChart = observer((props: IAkumaPlanerWochenChart) => {
	const { uiStore } = useStore();
	const { data } = props;
	// remove 'gpWochen', 'gpNoWochen', 'gesamt' from schema because they break the graph
	let schema = AkumaPlanerArtSchema.filter((s) => !['gpWochen', 'gpNoWochen', 'gesamt', 'zma', 'zagt'].includes(s.path));
	// graph needs 'nb' at the end
	schema.find((s) => s.path === 'nb')!.sort = 99;
	schema = schema.sort((a, b) => a.sort - b.sort);

	const chartHeight = 75 + (data.length + 2) * 30;

	const [showNichtBearbeitet, setNichtBearbeitet] = useState(false);
	const toggleNichtBearbeitete = () => {
		setNichtBearbeitet(!showNichtBearbeitet);
	};

	const [showPercent, setShowPercent] = useState(true);
	const togglePercent = () => {
		setShowPercent(!showPercent);
	};

	// echarts stuff
	const chartRef = useRef<HTMLDivElement>(null);
	useEffect(() => {
		let _schema = schema;
		if (!showNichtBearbeitet) {
			_schema = schema.filter((s) => s.path !== 'nb');
		}
		const fontSize = uiStore.printMode ? 12 : 14;
		const dim = ['name', ..._schema.sort((a, b) => a.sort - b.sort).map((s) => s.path)];
		const legendData = _schema.map((s) => ({ name: s.label }));

		const seriesOptions: echarts.SeriesOption = {
			type: 'bar',
			stack: 'a',
			label: {
				show: true,
				formatter: (params) => {
					// total = params.data without the first element
					// @ts-ignore
					const total = params.value.slice(1).reduce((acc, val) => acc + val, 0);
					// @ts-ignore
					const perc = Math.round((params.value[params.encode.x[0]] / total) * 100);
					// @ts-ignore
					const val = params.value[params.encode.x[0]];
					const ret = showPercent ? `${perc}%` : val;
					return val !== 0 ? ret : '';
				},
			},
			emphasis: {
				focus: 'series',
			},
		};

		let seriesData = _schema.map((s) => {
			return {
				...seriesOptions,
				name: s.label,
				color: s.color,
			};
		});

		const dataSetSource = data.map((kwData) => {
			const name = kwData.bm.toString();
			const vals = _schema.map((s) => _.get(kwData, s.path));

			return [name, ...vals];
		});

		let option: EChartsOption = {
			tooltip: {
				trigger: 'axis',
				valueFormatter: (value) => `${value}`,
			},
			yAxis: {
				type: 'category',
				axisLabel: {
					fontSize: fontSize,
					padding: [0, 5, 0, 0],
					formatter: (value: string) => {
						return formatKw(parseInt(value));
					},
				},
			},
			xAxis: {
				axisLabel: {
					fontSize: fontSize,
					formatter: '{value}',
				},
			},

			dataset: {
				dimensions: dim,
				source: dataSetSource,
			},
			legend: {
				bottom: 10,
				data: legendData,
			},
			grid: {
				containLabel: true,
			},
			series: seriesData,
			animation: !uiStore.printMode,
		};
		let chart: ECharts | null = null;
		if (chartRef.current !== null) {
			chart = init(chartRef.current, null, { renderer: 'svg' });
			chart.setOption(option);
		}
		const handleResize = debounce(() => {
			chart?.resize();
		}, 300);
		window.addEventListener('resize', handleResize);

		return () => {
			chart?.dispose();
			window.removeEventListener('resize', handleResize.cancel);
		};
	}, [chartRef, data, schema, showNichtBearbeitet, showPercent]);

	return (
		<>
			<div style={{ width: '100%', height: chartHeight, background: '#ffffff' }} ref={chartRef}></div>
			<div className="pad-1rem">
				<Button
					className="button is-primary is-small is-inverted"
					onClick={() => {
						toggleNichtBearbeitete();
					}}
				>
					{showNichtBearbeitet ? 'Nicht bearbeitete Kunden ausblenden' : 'Nicht bearbeitete Kunden einblenden'}
				</Button>
				<Button
					className="button is-info is-small is-inverted"
					onClick={() => {
						togglePercent();
					}}
				>
					{showPercent ? 'Zahlen' : 'Prozent'}
				</Button>
			</div>
		</>
	);
});

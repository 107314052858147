import { TreeMenuModel } from 'app/models/core/tree.node.model';
import { computed, makeObservable, reaction } from 'mobx';
import { AgenturStore } from '../agentur.store';
import { SessionStore } from '../session.store';
import { AgtBaseTreeStore } from '../core/base.tree.store';
import routes from 'routes';
import { BranchenplanungPosDef, FilterBranchenPosDefBySteart } from 'app/models/branchenplanung/bp.pos.def';
import { AgtBpStore } from '../agt.bp.store';
import { AnwendungeTreeNodes } from 'app/models/anwendungen.tree.nodes';
import { SchwerpunktList } from 'app/components/anwendungen/schwerpunkt/SchwerpunktList';
import { SegmentList } from 'app/components/anwendungen/segment/SegmentList';
import { BranchenplanungList } from 'app/components/anwendungen/branchenplanung/BranchenplanungList';
import { BranchenplanungOverviewList } from 'app/components/anwendungen/branchenplanung/BranchenplanungOverviewList';
import { AgtSegmentUiStore } from './agt.segment.ui.store';
import { BranchenplanungZielerreichung } from 'app/components/anwendungen/branchenplanung/BranchenplanungZielerreichung';
import { AgtUserUiStore } from './agt.user.ui.store';

export class AnwendungenUiStore extends AgtBaseTreeStore {
	agtBpStore: AgtBpStore;
	agtSegmentUiStore: AgtSegmentUiStore;
	constructor(session: SessionStore, agenturStore: AgenturStore, agtBpStore: AgtBpStore, agtSegmentUiStore: AgtSegmentUiStore, agtUserUiStore: AgtUserUiStore) {
		super(session, agenturStore, agtUserUiStore);
		makeObservable(this);
		this.agtBpStore = agtBpStore;
		this.agtSegmentUiStore = agtSegmentUiStore;
		this.printPath = 'printanwendung';

		reaction(
			() => this.session.currentUser,
			() => this.fillNodesFromUser(),
			{ fireImmediately: true },
		);
	}

	onLogout() {}

	fillNodesFromUser() {
		if (!this.session.currentUser) {
			return;
		}

		this.AGENTURROUTE = routes.AGENTUR;
		this.OUTEROUTE = routes.ANWENDUNGEN;

		// bp
		const bpRoot = this.menu.findNodeByPath('bp');
		const visiblePosDefs = FilterBranchenPosDefBySteart(this.session.currentUser!.steart);

		if (bpRoot) {
			bpRoot.nodes = [];
			for (const y of this.agtBpStore.years) {
				const bpYear = bpRoot.addNode({
					name: y.toString(),
					path: y.toString(),
					icon: '',
				});
				bpYear.addNode({
					name: 'Übersicht',
					path: 'overview',
					component: <BranchenplanungOverviewList />,
					icon: '',
				});
				bpYear.addNode({
					name: 'Zielerreichung',
					path: 'zielerreichung',
					hideFromAgt: true,
					component: <BranchenplanungZielerreichung />,
					icon: '',
				});

				visiblePosDefs.forEach((bpPos) => {
					const einheit = bpPos.isConvertible ? 'Bew' : bpPos.einheit.shortText;
					bpYear.addNode({
						hideFromAgt: true,
						name: bpPos.posText + ' [' + einheit + ']',
						path: bpPos.planKey,
						component: <BranchenplanungList />,
						icon: '',
					});
				});
			}
		}

		// schwerpunkt
		const schwerpunktRoot = this.menu.findNodeByPath('agt', 'schwerpunkt');
		if (schwerpunktRoot) {
			const schwerpunktArr = this.agtBpStore.years;
			// reduce array legnth to last 3 years
			schwerpunktArr.length = Math.min(schwerpunktArr.length, 3);

			for (const y of this.agtBpStore.years) {
				schwerpunktRoot.addNode({
					name: 'Schwerpunkt ' + y.toString(),
					path: y.toString(),
					component: <SchwerpunktList />,
					icon: '',
				});
			}
		}

		// segmentierung
		const segmentRoot = this.menu.findNodeByPath('agt', 'segement');
		if (segmentRoot) {
			for (const y of this.agtSegmentUiStore.years) {
				segmentRoot.addNode({
					name: 'Segmentierung ' + y.toString(),
					path: y.toString(),
					component: <SegmentList />,
					icon: '',
				});
			}
		}
	}

	menu: TreeMenuModel = new TreeMenuModel(AnwendungeTreeNodes);

	@computed
	get currentBpPlanKey() {
		const s = this.menu.current!.path.split('/');
		const planKey = s[s.length - 1];
		return planKey;
	}

	@computed
	get currentBpYear() {
		const s = this.menu.current!.path.split('/');
		const y = s[s.length - 2];
		const year = parseInt(y);
		return year;
	}

	@computed
	get currentSchwerpunktYear() {
		const s = this.menu.current!.path.split('/');
		const y = s[s.length - 1];
		const year = parseInt(y);
		return year;
	}

	@computed
	get currentBpPos() {
		return BranchenplanungPosDef.find((x) => x.planKey === this.currentBpPlanKey);
	}
}

import { AgenturListItems } from 'app/components/agt/AgenturList';
import Widget from 'app/components/common/Widget';
import { Table } from 'app/components/table/table';
import { AggregrationType, ITableColumn, TableModel, TableRow } from 'app/components/table/table.model';
import { AgtQueryStringSetter } from 'app/containers/BerichteContainer';
import { useStore } from 'app/context';
import { AgenturExtendedVM } from 'app/stores/ui/agt.list.ui.store';
import { observer } from 'mobx-react';
import { useEffect, useMemo } from 'react';

export const AdminUsageFokusthemenOverview = observer(() => {
	const { agenturListUiStore } = useStore();

	useEffect(() => {
		agenturListUiStore.load();
	}, [agenturListUiStore]);

	return (
		<>
			<AdminFokusThemenByOrga />
			<AgtQueryStringSetter />
			<AgenturListItems onSelectAgt={(x: any) => { }} />
		</>
	);
});


export const AdminFokusThemenByOrga = observer(() => {
	const { agenturListUiStore, agenturFilterUiStore } = useStore();


	const sumAvg = () => {
		return [
			{
				aggType: AggregrationType.sum,
			},
			{
				aggType: AggregrationType.avg,
			},
		]
	}


	const tm = useMemo(() => {
		const tm = new TableModel<AgenturExtendedVM, number>();
		let cols: ITableColumn<AgenturExtendedVM, number>[] = [
		];


		if (agenturFilterUiStore.hasVdFilter) {
			cols.push({
				label: 'gsId',
				path: 'gsId',
			});

		} else {
			cols.push({
				label: 'vdId',
				path: 'vdId',
			});

		}

		cols.push({
			label: 'Agts mit min. einem Fokusthema',
			path: 'minOneFocusThema',
			agg: [{
				aggType: AggregrationType.sum,
			}]
		});
		cols.push({
			label: 'Durschn. Anzahl Fokusthemen pro Agentur',
			path: 'focusThemen.length',
			agg: [{
				aggType: AggregrationType.avg,
			}]
		});

		cols.push({
			label: 'Summe  der vergebene Fokusthemen',
			path: 'ft.length',
			agg: sumAvg()
		});

		cols.push({
			label: 'Anz Agt Gesamt',
			path: 'agtId',
			agg: [
				{
					aggType: AggregrationType.countDistinct
				},
				{
					aggType: AggregrationType.sum,
				}]
		});
		tm.setCols(cols);
		tm.sortBy = 'minOneFocusThema';
		tm.idProperty = 'id';
		tm.idType = 'string';
		return tm;
	}, [agenturListUiStore.filterHasChanged, agenturFilterUiStore.hasVdFilter, agenturListUiStore.filtered,]);

	useEffect(() => {
		console.log('agenturListUiStore.filtered', agenturListUiStore.filteredItems.length)
		if (agenturFilterUiStore.hasVdFilter) {
			tm.setGroupByData(agenturListUiStore.filteredItems, (x) => x.gsId);
		} else {
			tm.setGroupByData(agenturListUiStore.filteredItems, (x) => x.vdId);
		}
	}, [tm, agenturFilterUiStore.hasVdFilter, agenturListUiStore.filteredItems]);

	return (
		<>
			<div className="pad-1rem">
				<Widget title="Fokusthemen " modifier="is-paddingless" action={<span className="tag is-green">DVS Datenbank</span>}>
					<Table tm={tm} />

				</Widget>
			</div>
		</>
	);
});

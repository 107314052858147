import { SessionStore } from '../session.store';
import { AgenturStore } from '../agentur.store';
import { AgtZielModel, AgtZielStore } from '../agt.ziel.store';
import { AgtProdPosVM } from 'app/models/berichte/agt.prod.pos.vm';
import { AgtGpStore } from '../agt.gp.store';
import { KvAgtEigenePlanung, KvAgtEigenePlanungStore } from '../kv.agt.eigene.planung.store';
import { AgtGpPosModel } from 'app/models/gp/agt.gp.pos.model';
import { AgenturModel, AgtType } from 'app/models/agentur.model';
import { ZweitverkaueferZielStore } from '../zweitverkaeufer.ziel.store';
import { formatCurrency, formatNum } from 'app/utils';

class Pro3Fahrstuhl {
	ap2: AgtZielModel[] = [];
	ap3: AgtZielModel[] = [];
	ap4: AgtZielModel[] = [];

	getForAgt(agt: AgenturModel, gpPos: AgtGpPosModel, eigeneValues: KvAgtEigenePlanung[]) {
		const agtId = agt.agtId;
		const ev = eigeneValues.find((ev) => ev.agtId === agtId);
		let fs = 'AP4';
		let v = this.ap4.find((v) => v.agtId === agtId);
		if (!v) {
			fs = 'AP3';
			v = this.ap3.find((v) => v.agtId === agtId);
			if (!v) {
				fs = 'AP2';
				v = this.ap2.find((v) => v.agtId === agtId);
			}
		}
		if (v) {
			const p = new AgtProdPosVM({
				agt: agt,
				posDef: gpPos,
				posVal: v,
				eigenerVal: ev,
			});
			p.fahrstuhl = fs;
			return p;
		}
	}

	getForAgtProduktivtaet(agt: AgenturModel, gpPos: AgtGpPosModel, eigeneValues: KvAgtEigenePlanung[]) {
		const agtId = agt.agtId;
		const ev = eigeneValues.find((ev) => ev.agtId === agtId);

		let fs = 'AP3';
		let v = this.ap3.find((v) => v.agtId === agtId);

		if (v) {
			const p = new AgtProdPosVM({
				agt: agt,
				posDef: gpPos,
				posVal: v,
				eigenerVal: ev,
			});
			p.fahrstuhl = fs;
			return p;
		}
	}

}

class CacheItem {
	constructor(planKey: string, year: number, items: AgtProdPosVM[]) {
		this.planKey = planKey;
		this.items = items;
		this.year = year;
		this.loadingPromise = new Promise((resolve) => {
			this._resolver = resolve;
		});
	}
	markAsLoaded = () => {
		if (this._resolver) {
			this._resolver();
		}
	};
	_resolver: any;
	planKey: string;
	year: number;
	items: AgtProdPosVM[];
	loadingPromise: Promise<any>;
}

export class AgtProdUiStore {
	session: SessionStore;
	agenturStore: AgenturStore;
	posAgtZielStore: AgtZielStore;
	kvAgtEigenePlanungStore: KvAgtEigenePlanungStore;
	gpStore: AgtGpStore;
	zweitverkaueferZielStore: ZweitverkaueferZielStore;
	constructor(
		session: SessionStore,
		agenturStore: AgenturStore,
		posAgtZielStore: AgtZielStore,
		kvAgtEigenePlanungStore: KvAgtEigenePlanungStore,
		gpStore: AgtGpStore,
		zweitverkaueferZielStore: ZweitverkaueferZielStore,
	) {
		this.session = session;
		this.agenturStore = agenturStore;
		this.posAgtZielStore = posAgtZielStore;
		this.kvAgtEigenePlanungStore = kvAgtEigenePlanungStore;
		this.gpStore = gpStore;
		this.zweitverkaueferZielStore = zweitverkaueferZielStore;
	}
	onLogout() {
		this.agenturStore.onLogout();
		this.posAgtZielStore.onLogout();
		// this.kvAgtEigenePlanungStore.session
	}

	cache: CacheItem[] = [];

	async filterdByAgtId(agt: AgenturModel, year: number) {
		await this.kvAgtEigenePlanungStore.findAll();
		let posDefs = this.gpStore.agtPositionenByYear(year);
		const res = [];
		for (const def of posDefs) {
			let sub = await this.findByPlanKeyAndAgtId(def.planKey, agt.agtId, year);
			if (sub) {
				if (agt.GP && def.visibleForGps.includes(agt.GP)) {
					// hide position without zielwert for non-Admins
					if ((sub.posVal && sub.posVal.ziel) || this.session.isSuperAdmin) {
						res.push(sub);
					}
				}
			}
		}
		return res;
	}

	async findByPlanKeyAndAgtId(planKey: string, agtId: number, year: number) {
		const items = await this.findByPlanKey(planKey, year);
		return items.find((i) => i.agtId === agtId);
	}

	getNumberFormatByPlanKeyAndYear(planKey: string, year: number) {
		const gpModel = this.gpStore.findAgtPositionByPlanKey(planKey, year);
		if (!gpModel || gpModel.decimals === 0) {
			return formatNum
		}
		return (val: number) => {
			return formatCurrency(val, gpModel.decimals, undefined);
		}
	}

	async findByPlanKey(planKey: string, year: number): Promise<AgtProdPosVM[]> {
		let cacheItem = this.cache.find((c) => c.planKey === planKey && c.year === year);
		if (cacheItem) {
			await cacheItem.loadingPromise;
			return cacheItem.items;
		}
		// console.time('agt.prod.ui.store.findByPlanKey' + planKey + year);
		const res = await this._findByPlanKey(planKey, year);
		// console.timeEnd('agt.prod.ui.store.findByPlanKey' + planKey + year);
		return res;

	}


	async _findByPlanKey(planKey: string, year: number): Promise<AgtProdPosVM[]> {
		let cacheItem = new CacheItem(planKey, year, []);
		this.cache.push(cacheItem);
		const agts = (await this.agenturStore.findAll()).filter((x) => x.agtType === AgtType.agt);

		const values = await this.posAgtZielStore.findByPlanKey(planKey, year);
		let fahrstuhl = new Pro3Fahrstuhl();
		if (planKey === 'gesamtbewertung') {
			fahrstuhl = await this.loadFahrstuhl(year);
		}

		// let pro3Produktivitaten: AgtZielModel[] = [];
		// if (planKey === 'produktivitaet') {
		// 	fahrstuhl = await this.loadProduktivitaet(year);
		// }

		const eigeneValues = await this.kvAgtEigenePlanungStore.findByPlanKey(planKey, year);
		const gpPos = this.gpStore.findAgtPositionByPlanKey(planKey, year);
		if (!gpPos) {
			console.warn('Could not found gpPos for plankey ' + planKey);
			cacheItem.items = [];
			cacheItem.markAsLoaded();
			return [];
		}

		const res: AgtProdPosVM[] = [];
		for (const agt of agts) {
			// agts.forEach((agt) => {
			if (agt.vtnrGpincId === 1130 && planKey === 'gesamtbewertung') {
				// PRO3 Fahrstuhl
				const p = fahrstuhl.getForAgt(agt, gpPos, eigeneValues);
				if (p) {
					p.posDef = p.posDef.cloneAsPro3();
					res.push(p);
				}
			} else if ([1130, 1190].includes(agt.vtnrGpincId) && planKey === 'produktivitaet') {
				// if (agt.agtId === 300413300) {
				// 	// debugger;
				// }
				const prod = await this.loadProduktivitaet(agt.agtId, year);
				if (prod) {
					const p = new AgtProdPosVM({
						agt: agt,
						posDef: gpPos,
						posVal: prod,
						eigenerVal: eigeneValues.find(x => x.agtId === agt.agtId),
					});
					p.posDef = p.posDef.cloneAsPro3();
					res.push(p);
				}
			} else {
				res.push(this.handleBoniStufeForAgt(agt, gpPos, values, eigeneValues));
			}
		};

		cacheItem.items = res;
		cacheItem.markAsLoaded();

		return res;
	}

	handleBoniStufeForAgt(agt: AgenturModel, gpPos: AgtGpPosModel, values: AgtZielModel[], eigeneValues: KvAgtEigenePlanung[]) {
		const agtId = agt.agtId;
		const ev = eigeneValues.find((ev) => ev.agtId === agtId);
		values = values.filter((v) => v.agtId === agt.agtId);
		values = values.sort((a, b) => a.ziel - b.ziel);
		const v = values.find((v) => v.agtId === agt.agtId);
		const erg = v ? v.ergebnis : 0;
		let currStufe = 0;
		let boniStufenZiele: number[] = [];
		if (gpPos.boniStufen > 1) {
			values.forEach((bv) => {
				boniStufenZiele.push(bv.ziel);
				if (erg > bv.ziel) {
					currStufe += 1;
				}
			});
			// v = values[currStufe - 1];
		}
		const p = new AgtProdPosVM({
			agt: agt,
			posDef: gpPos,
			posVal: v,
			eigenerVal: ev,
			boniStufe: currStufe,
			boniStufenZiele,
		});

		return p;
	}

	async loadFahrstuhl(year: number) {
		const posIdAP2 = 90144101;
		const posIdAP3 = 90144102;
		const posIdAP4 = 90144103;

		let res = await Promise.all([
			this.posAgtZielStore.findByPosId(posIdAP2, year),
			this.posAgtZielStore.findByPosId(posIdAP3, year),
			this.posAgtZielStore.findByPosId(posIdAP4, year),
		]);

		const fs = new Pro3Fahrstuhl();
		fs.ap2 = res[0];
		fs.ap3 = res[1];
		fs.ap4 = res[2];
		return fs;
	}


	async loadProduktivitaet(agtId: number, year: number) {
		// if (agtId === 979211150) {
		// 	debugger;
		// }
		const posIdProduktivitaet1 = 2255852;
		const posIdProduktivitaet2 = 2255853;
		// const posIdProduktivitaet3 = 2255852;

		let res = await this.posAgtZielStore.findByPosId(posIdProduktivitaet1, year);
		let x = res.find(x => x.agtId === agtId);
		if (x) {
			return x;
		}
		res = await this.posAgtZielStore.findByPosId(posIdProduktivitaet2, year);
		return x = res.find(x => x.agtId === agtId);

	}

	async saveMany(data: AgtProdPosVM[], planKey: string, year: number) {
		for (const d of data) {
			await this.saveOne(d, planKey, year)
		}
		// remove cached planKey
		this.cache = this.cache.filter((c) => c.planKey !== planKey);
	}
	async saveOne(d: AgtProdPosVM, planKey: string, year: number) {
		if (d.eigenerVal) {
			d.eigenerVal.agtId = d.agtId;
			d.eigenerVal.bm = year;
			d.eigenerVal.bnrId = this.session.currentUser!.bnrId;
			d.eigenerVal.key = d.eigenerVal.bnrId.toString();
			d.eigenerVal.key2 = planKey;
			d.eigenerVal.group = 'eigene_planung';
			await this.kvAgtEigenePlanungStore.save(d.eigenerVal);
			this.kvAgtEigenePlanungStore.clearCache();
		}
		// remove cached planKey
		this.cache = this.cache.filter((c) => c.planKey !== planKey);
	}
}

import { AgenturNameCell } from 'app/components/shared/AgenturName.cell';
import { Table } from 'app/components/table/table';
import { ITableColumn, TableModel } from 'app/components/table/table.model';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import { AgtFitnessVm } from 'app/stores/ui/agt.fitness.ui.store';
import { formatNumTwoDig, formatPercent } from 'app/utils';
import { simpleFitCell } from './FitnessTrackerListe';
import { useHistory, useLocation } from 'react-router';
import routes from 'routes';
import { FitnessTrackerGraphics } from './FitnessTrackerGraphics';

export const FitnessTrackerAgtPrint = observer(() => {
	const { berichteUiiStore, agtFitnessUiStore, agenturListUiStore } = useStore();
	const [hasItems, setHasItems] = useState(false);

	const [selectedAgtId, setSelectedAgtId] = useState<number>(702130995);
	const history = useHistory();
	const location = useLocation();

	useEffect(() => {
		agtFitnessUiStore.load().then(() => {
			let items = agtFitnessUiStore.items;
			const agtId = selectedAgtId;
			items = items.filter((i) => i.agt.agtId === agtId);
			setHasItems(items.length > 0);
		});
	}, [berichteUiiStore.selectedAgt, agenturListUiStore.filterHasChanged, agenturListUiStore, agtFitnessUiStore.items, agtFitnessUiStore]);

	const onClose = () => {
		const p = routes.BERICHTE.getPath('bestand', 'fit', 'gup');
		history.push(p);
	};

	return (
		<>
			{selectedAgtId && (
				<>
					<div className="page-break-after">
						<FitnessTrackerGraphics agtId={selectedAgtId} homeAlone={true} view="overview" onClose={onClose} />
					</div>
					<div className="page-break-after">
						<FitnessTrackerGraphics agtId={selectedAgtId} homeAlone={true} view="gup" onClose={onClose} />
					</div>
					<div className="page-break-after">
						<FitnessTrackerGraphics agtId={selectedAgtId} homeAlone={true} view="amis" onClose={onClose} />
					</div>
					<div className="page-break-after">
						<FitnessTrackerGraphics agtId={selectedAgtId} homeAlone={true} view="akuma" onClose={onClose} />
					</div>
					<div className="page-break-after">
						<FitnessTrackerGraphics agtId={selectedAgtId} homeAlone={true} view="maz" onClose={onClose} />
					</div>
				</>
			)}
		</>
	);
});

import { useEffect, useState } from 'react';
import BaseContainer from 'app/containers/BaseContainer';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { useStore } from 'app/context';
import { DocumentVM } from 'app/stores/ui/document.ui.store';
import { DocumentViewer } from 'app/components/documents/DocumentViewer';
import { runInAction } from 'mobx';
import { UserName } from 'app/components/common/UserName';
import { formatDate } from 'app/utils';
import { TaskVM } from 'app/stores/ui/task.ui.store';
import { TaskViewer } from 'app/components/tasks/TaskViewer';
import { AllianzLogo } from './PrintDocumentContainer';

export interface IPrinTaskParms {
	ids: string;
}

const PrintTaskContainer = observer(() => {
	const { uiStore, taskUiStore } = useStore();
	let { ids } = useParams<IPrinTaskParms>();

	const [tasks, setTasks] = useState<TaskVM[]>([]);

	useEffect(() => {
		runInAction(() => {
			// only necessary for dev
			uiStore.printMode = true;
		});
		taskUiStore.findAll().then(() => {
			const taskIds = ids.split(',');
			const res = taskUiStore.items.filter((x) => taskIds.includes(x.id));
			setTasks(res);
		});
	}, [ids]);

	return (
		<BaseContainer>
			<>
				{tasks.map((task) => {
					return (
						<div className="print-page-break" key={task.id}>
							<PrintHeader task={task}></PrintHeader>
							<TaskViewer task={task} showAgt={true} showPlan={true} printMode={true}></TaskViewer>
						</div>
					);
				})}
			</>
		</BaseContainer>
	);
});

export default PrintTaskContainer;

interface IPrintHeader {
	task: TaskVM;
}

const PrintHeader = observer((props: IPrintHeader) => {
	const { task } = props;

	return (
		<>
			<div className="print-header">
				<div className="grid is-col-auto align-center">
					<div className="left pad-1rem title is-2">{task.task.text}</div>
					<div className="text-right pad-1rem">
						<AllianzLogo />
					</div>
				</div>

				<div className="pad-bottom-1rem">
					<table className="table">
						<thead>
							<tr>
								<th>Erstellt durch</th>
								<th>Agentur</th>
								<th>Planung</th>
								<th>Stand</th>
								{!task.task.noDueDate && <th>Wiedervorlage</th>}
								<th>Status</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									<UserName bnrId={task.task.bnrId} />
								</td>
								<td>{task.agt?.name}</td>
								<td>{task.planYear}</td>
								<td>{formatDate(task.task.modified)}</td>
								{!task.task.noDueDate && task.task.dueDate && <td>{formatDate(task.task.dueDate)}</td>}
								<td>{task.statusText}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</>
	);
});

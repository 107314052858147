import { Steart } from 'app/models/core/steart.model';
import { SessionStore } from './session.store';

import { LpvPositionen } from 'app/models/gp/2025/lpv.positionen.def';
import { VblPositionen } from 'app/models/gp/2025/vbl.positionen.def';
import { VasPositionen } from 'app/models/gp/2025/vas.positionen.def';
import { BsgPositionen } from 'app/models/gp/2025/bsg.positionen.def';
import { BbsPositionen } from 'app/models/gp/2025/bbs.positionen.def';
import { KmePositionen } from 'app/models/gp/2025/kme.positionen.def';

import { KvsPositionen } from 'app/models/gp/2023/kvs.positionen.def';
import { LsvPositionen } from 'app/models/gp/2023/lsv.positionen.def';
import { UbPositionen } from 'app/models/gp/2023/ub.positionen.def';

import { LpvPositionen as LpvPositionenVorjahr } from 'app/models/gp/2024/lpv.positionen.def';
import { VblPositionen as VblPositionenVorjahr } from 'app/models/gp/2024/vbl.positionen.def';
import { VasPositionen as VasPositionenVorjahr } from 'app/models/gp/2024/vas.positionen.def';
import { BsgPositionen as BsgPositionenVorjahr } from 'app/models/gp/2024/bsg.positionen.def';
import { BbsPositionen as BbsPositionenVorjahr } from 'app/models/gp/2024/bbs.positionen.def';
import { KmePositionen as KmePositionenVorjahr } from 'app/models/gp/2024/kme.positionen.def';

import { KvsPositionen as KvsPositionenVorjahr } from 'app/models/gp/2022/kvs.positionen.def';
import { LsvPositionen as LsvPositionenVorjahr } from 'app/models/gp/2022/lsv.positionen.def';

import { VblZeWidgets } from 'app/models/gp/zewidgets/vbl.ze.widgets';
import { VasZeWidgets } from 'app/models/gp/zewidgets/vas.ze.widgets';
import { BbsZeWidgets } from 'app/models/gp/zewidgets/bbs.ze.widget';
import { KmeZeWidgets } from 'app/models/gp/zewidgets/kme.ze.widgets';
import { KvsZeWidgets } from 'app/models/gp/zewidgets/kvs.ze.widgets';
import { LpvZeWidgets } from 'app/models/gp/zewidgets/lpv.ze.widgets';
import { LsvZeWidgets } from 'app/models/gp/zewidgets/lsv.ze.widgets';
import { BsgZeWidgets } from 'app/models/gp/zewidgets/bsg.ze.widgets';

import { EdpPositionen } from 'app/models/gp/2025/edp.positionen.def';
import { EdtPositionen } from 'app/models/gp/2025/edt.positionen.det';
import { LdpPositionen } from 'app/models/gp/2025/ldp.positionen.def';

import { EdpPositionen as EdpPositionenVorjahr } from 'app/models/gp/2024/edp.positionen.def';
import { EdtPositionen as EdtPositionenVorjahr } from 'app/models/gp/2024/edt.positionen.det';
import { LdpPositionen as LdpPositionenVorjahr } from 'app/models/gp/2024/ldp.positionen.def';

import { LDPZeWidgets, EDTZeWidgets, EDPZeWidgets } from 'app/models/gp/zewidgets/multis.ze.widget';

export class BnrGpStore {
	session: SessionStore;
	constructor(session: SessionStore) {
		this.session = session;
	}

	getZeWidgetBySteart(role: Steart) {
		switch (role) {
			case Steart.VBL:
				return VblZeWidgets;
			case Steart.LPV:
				return LpvZeWidgets;
			case Steart.LSV:
				return LsvZeWidgets;
			case Steart.BSG:
				return BsgZeWidgets;
			case Steart.BBS:
				return BbsZeWidgets;
			case Steart.KME:
				return KmeZeWidgets;
			case Steart.KVS:
				return KvsZeWidgets;
			case Steart.VAS:
				return VasZeWidgets;
			case Steart.LDP:
				return LDPZeWidgets;
			case Steart.EDP:
				return EDPZeWidgets;
			case Steart.EDT:
				return EDTZeWidgets;
			default:
				console.warn('keine zewidgets für steart', role, ' definiert');
				return [];
		}
	}

	get zeWidgets() {
		const role = this.session.currentUser!.steart.steart;
		return this.getZeWidgetBySteart(role);
	}

	getPositionVorjahrBySteart(role: Steart) {
		switch (role) {
			case Steart.VBL:
				return VblPositionenVorjahr;
			case Steart.LPV:
				return LpvPositionenVorjahr;
			case Steart.LSV:
				return LsvPositionenVorjahr;
			case Steart.BBS:
				return BbsPositionenVorjahr;
			case Steart.KME:
				return KmePositionenVorjahr;
			case Steart.KVS:
				return KvsPositionenVorjahr;
			case Steart.VAS:
				return VasPositionenVorjahr;
			case Steart.BSG:
				return BsgPositionenVorjahr;
			case Steart.LDP:
				return LdpPositionenVorjahr;
			case Steart.EDP:
				return EdpPositionenVorjahr;
			case Steart.EDT:
				return EdtPositionenVorjahr;
			default:
				console.warn('keine vorjahr positionen für steart', role, ' definiert');
				return [];
		}
	}

	get positionenVorjahr() {
		const role = this.session.currentUser!.steart.steart;
		return this.getPositionVorjahrBySteart(role);
	}

	getPositionBySteart(role: Steart) {
		switch (role) {
			case Steart.VBL:
				return VblPositionen;
			case Steart.LPV:
				return LpvPositionen;
			case Steart.LSV:
				return LsvPositionen;
			case Steart.BBS:
				return BbsPositionen;
			case Steart.KME:
				return KmePositionen;
			case Steart.KVS:
				return KvsPositionen;
			case Steart.VAS:
				return VasPositionen;
			case Steart.UB:
				return UbPositionen;
			case Steart.BSG:
				return BsgPositionen;
			case Steart.EDP:
				return EdpPositionen;
			case Steart.EDT:
				return EdtPositionen;
			case Steart.LDP:
				return LdpPositionen;
			default:
				console.warn('keine positionen für steart', role, ' definiert');
				return [];
		}
	}

	get positionen() {
		const role = this.session.currentUser!.steart.steart;
		return this.getPositionBySteart(role);
	}
}

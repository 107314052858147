import { AgenturNameCell } from 'app/components/shared/AgenturName.cell';
import { Table } from 'app/components/table/table';
import { AggregrationType, ITableColumn, TableModel, TableRow } from 'app/components/table/table.model';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
// import { simpleFitCell } from './FitnessTrackerListe';
import { AgtSocialMediaVM } from 'app/stores/ui/agt.socialmedia.ui.store';
import { Icon } from 'app/components/common/Icon';
import { formatNum } from 'app/utils';

export const InstagramListe = observer(() => {
	const { berichteUiiStore, agtSocialMediaUiStore, agenturListUiStore } = useStore();
	const [hasItems, setHasItems] = useState(false);
	const tm = new TableModel<AgtSocialMediaVM, number>();
	const cols: ITableColumn<AgtSocialMediaVM, number>[] = [];

	const currentYear = agtSocialMediaUiStore.currentYear;

	cols.push({
		label: 'Agentur',
		path: 'agt.name',
		sortBy: 'agt.name',
		render: AgenturNameCell,
	});

	cols.push({
		label: 'Link',
		path: 'currInsta.link',
		sortBy: 'currInsta.link',
		render: (row: TableRow<AgtSocialMediaVM>) => {
			return row.data.currInsta && row.data.currInsta.url ? (
				<a href={row.data.currInsta?.url} className="color-primary" target="_blank" rel="noreferrer">
					<Icon iconClass="external-link" />
				</a>
			) : (
				<></>
			);
		},
	});

	cols.push({
		label: 'Status',
		path: `currInsta.status`,
		sortBy: `currInsta.status`,
		render: (row: TableRow<AgtSocialMediaVM>) => <>{row.data.currInsta && row.data.currInsta.status === 1 ? <Icon iconClass="check" /> : ''}</>,
	});

	cols.push({
		label: 'Follower',
		path: `currInsta.follow`,
		sortBy: `currInsta.follow`,
		agg: [
			{
				path: `currInsta.follow`,
				aggType: AggregrationType.count,
				aggIf: (val: any) => {
					return val >= 250;
				},
			},
		],
	});

	cols.push({
		label: 'Follower VJ',
		path: `prevInsta.follow`,
		sortBy: `prevInsta.follow`,
		agg: [
			{
				path: `prevInsta.follow`,
				aggType: AggregrationType.count,
				aggIf: (val: any) => {
					return val >= 250;
				},
			},
		],
	});

	cols.push({
		label: `Beiträge ${currentYear}`,
		path: `instaPostsYtd`,
		sortBy: `instaPostsYtd`,
		format: formatNum,
	});

	cols.push({
		label: `Eigene Beiträge ${currentYear}`,
		path: `instaOwnPostsYtd`,
		sortBy: `instaOwnPostsYtd`,
		format: formatNum,
	});

	cols.push({
		label: `Ansichten ${currentYear}`,
		path: `instaViewsYtd`,
		sortBy: `instaViewsYtd`,
		format: formatNum,
	});

	cols.push({
		label: 'Abo PR',
		path: `currInsta.aboPr`,
		sortBy: `currInsta.aboPr`,
		render: (row: TableRow<AgtSocialMediaVM>) => <>{row.data.currInsta && row.data.currInsta.aboPr === 1 ? <Icon iconClass="check" /> : ''}</>,
	});

	tm.sortBy = 'agt.name';
	tm.setCols(cols);

	tm.idProperty = 'agtId';
	tm.idType = 'number';

	useEffect(() => {
		agtSocialMediaUiStore.load().then(() => {
			let items = agtSocialMediaUiStore.items.filter((x) => x.instas.length > 0);
			if (berichteUiiStore.selectedAgt) {
				const agtId = berichteUiiStore.selectedAgt.agtId;
				items = items.filter((i) => i.agt.agtId === agtId);
			} else {
				items = agenturListUiStore.applyFilterOnAgt(items) as AgtSocialMediaVM[];
			}
			setHasItems(items.length > 0);
			tm.setRowData(items);
			tm.sort();
		});
	}, [tm, berichteUiiStore.selectedAgt, agenturListUiStore.filterHasChanged, agenturListUiStore, agtSocialMediaUiStore.items, agtSocialMediaUiStore]);

	return (
		<>
			{!hasItems ? (
				<div className="pad-1rem">Keine Daten gefunden</div>
			) : (
				<>
					<Table stickyHeader={true} tm={tm}></Table>
				</>
			)}
		</>
	);
});

import { BoniStufenCell } from 'app/components/shared/Bonistufe.cell';
import { Table } from 'app/components/table/table';
import { TableModel, TableRow } from 'app/components/table/table.model';
import { useStore } from 'app/context';
import { AgenturModel } from 'app/models/agentur.model';
import { AgtProdPosVM } from 'app/models/berichte/agt.prod.pos.vm';
import { formatNum, formatPercent } from 'app/utils';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import { IGPYear } from './ProdZwischenstand';

export const AgtProdGpUebersicht = observer((props: IGPYear) => {
	const { berichteUiiStore } = useStore();
	const agt = berichteUiiStore.selectedAgt;

	return (
		<>
			{agt && (
				<>
					<AgtProdTable agt={agt} {...props} />
				</>
			)}
		</>
	);
});

interface IAgtProdGpGroupTable extends IGPYear {
	agt: AgenturModel;
}

export const AgtProdTable = observer((props: IAgtProdGpGroupTable) => {
	const { agtProdUiStore } = useStore();

	const [loaded, setLoadad] = useState<boolean>(false);

	const formatPerc = (val: number) => {
		return `${formatPercent(val, 2)}%`;
	};

	const tm = useMemo(() => {
		const tm = new TableModel<AgtProdPosVM, number>();

		const cols = [
			{
				label: '',
				path: ['posDef.posText'],
				sortBy: 'posDef.group.name',
			},
			{
				label: 'BoniStufe',
				path: 'boniStufe',
				render: BoniStufenCell,
			},
			{
				label: 'Zielwert',
				path: 'posVal.ziel',
				render: (row: TableRow<AgtProdPosVM>) => {
					const numFormat = agtProdUiStore.getNumberFormatByPlanKeyAndYear(row.data.posDef.planKey, props.year)

					if (!row.data.posVal) {
						return <></>;
					}
					return <> {numFormat(row.data.posVal?.ziel)}</>;
				},
			},
			{
				label: 'Eigener Zielwert',
				path: 'eigenerVal.valueNum',
				format: formatNum,
				editable: true,

			},
			{
				label: 'Ergebnis',
				path: 'posVal.ergebnis',
				render: (row: TableRow<AgtProdPosVM>) => {
					const numFormat = agtProdUiStore.getNumberFormatByPlanKeyAndYear(row.data.posDef.planKey, props.year)

					if (!row.data.posVal) {
						return <></>;
					}
					return <> {numFormat(row.data.posVal?.ergebnis)}</>;
				},
			},
			{
				label: 'ZE',
				path: 'posVal.ze',
				format: formatPerc,
			},
			{
				label: 'ZE (Zeit)',
				path: 'posVal.zeZeit',
				format: formatPerc,
			},
			// {
			// 	label: '',
			// 	path: 'data.posDef.planKey',
			// 	render: (row: TableRow<AgtProdPosVM>) => {
			// 		if (row.data.posDef.pro3Only) {
			// 			return <></>;
			// 		}
			// 		return <> {row.data.posDef.planKey && <Icon iconClass={'list-alt'} />}</>;
			// 	},
			// },
		];
		// tm.onRowClick = (row: TableRow<AgtProdPosVM>) => {
		// 	if (tm.editMode || row.data.posDef.pro3Only) {
		// 		return;
		// 	}
		// 	if (row.data.posDef) {
		// 		history.push(routes.AGENTUR.getPath(row.data.agtId.toString(), 'berichte', 'production', 'agtpos', row.data.posDef.group.id, row.data.posDef.planKey));
		// 	}
		// };
		tm.groupHeaderRow = {
			label: 'posDef.group.name',
			path: 'posDef.group.name',
		};
		tm.setCols(cols);
		tm.sortDisabled = true;
		tm.idProperty = 'agtId';
		tm.idType = 'number';
		return tm;
	}, []);

	useEffect(() => {
		agtProdUiStore.filterdByAgtId(props.agt, props.year).then((res) => {
			tm.setRowData(res);
			setLoadad(true);
		});
	}, [tm, setLoadad, agtProdUiStore, props.agt, props.year]);


	const submit = async () => {
		for (const d of tm.data) {
			const planKey = d.posDef.planKey;
			console.log('saving', planKey, d);
			await agtProdUiStore.saveOne(d, planKey, props.year);
		}

	}

	return <>{loaded && <Table stickyFooter={true} tm={tm} onSubmit={submit} />}</>;
});

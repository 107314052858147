import { KvAgtModel } from 'app/models/kv.agt.model';
import { action, computed, makeObservable, observable } from 'mobx';
import { BaseCachedEntityStore } from './core/base.cached.entity.store';
import { AgenturModel } from 'app/models/agentur.model';
import { SessionStore } from './session.store';
export class CustomExpertiseModel extends KvAgtModel {
	constructor(opts: any) {
		super(opts);
		this.group = 'expertise';
	}
	@computed
	get text() {
		return this.valueString;
	}

	@computed
	get ablauf() {
		if (this.valueNum) {
			return new Date(this.valueNum * 1000);
		}
		return null;
	}
}

export class KvAgtCustomExpertiseStore extends BaseCachedEntityStore<CustomExpertiseModel> {
	constructor(session: SessionStore) {
		super(session);
		makeObservable(this);
	}

	apiPath = '/api/kv_agt/expertise';
	idProp: string = 'id';
	disableFindForStearts = [123];

	@observable
	customExpertisenTempText?: string;

	@action
	setCustomExpertisenText(text?: string) {
		this.customExpertisenTempText = text;
	}

	@observable
	currentExpertisenId?: string;

	@action
	setExpertisenId(id?: string) {
		this.currentExpertisenId = id;
	}

	async findByAgtId(agtId: number) {
		const items = await this.findAll();
		return items.filter((i) => i.agtId === agtId);
	}


	createInstances(opts: any): CustomExpertiseModel {
		return new CustomExpertiseModel(opts);
	}
}

import { AgenturNameCell } from 'app/components/shared/AgenturName.cell';
import { Table } from 'app/components/table/table';
import { AggregrationType, ITableColumn, TableModel } from 'app/components/table/table.model';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { AgtFitnessVm } from 'app/stores/ui/agt.fitness.ui.store';

export const LeadNowList = observer(() => {
	const { berichteUiiStore, agtFitnessUiStore, agenturListUiStore } = useStore();
	const [hasItems, setHasItems] = useState(false);

	const tm = new TableModel<AgtFitnessVm, number>();
	const cols: ITableColumn<AgtFitnessVm, number>[] = [];

	cols.push({
		label: 'Agentur',
		path: 'agt.name',
		sortBy: 'agt.name',
		render: AgenturNameCell,
	});
	cols.push({
		label: 'leadnowMultiGpCount',
		path: `leadnowMultiGpCount`,
		sortBy: `leadnowMultiGpCount`,
		cellModifier: 'border-left-green bg-green',

		agg: [
			{
				aggType: AggregrationType.sum,
			},
		],
	});
	cols.push({
		label: 'isCurrentLeadNowUser',
		path: `isCurrentLeadNowUser`,
		sortBy: `isCurrentLeadNowUser`,
		// agg: [{
		// 	aggType: AggregrationType.sum,
		// }]
	});
	cols.push({
		label: 'maxLeadnowKw',
		path: `maxLeadnowKw`,
		sortBy: `maxLeadnowKw`,
	});

	tm.sortBy = 'agt.name';
	tm.setCols(cols);

	tm.idProperty = 'agtId';
	tm.idType = 'number';

	useEffect(() => {
		agtFitnessUiStore.load().then(() => {
			let items = agtFitnessUiStore.items;
			if (berichteUiiStore.selectedAgt) {
				const agtId = berichteUiiStore.selectedAgt.agtId;
				items = items.filter((i) => i.agt.agtId === agtId);
			} else {
				items = agenturListUiStore.applyFilterOnAgt(items) as AgtFitnessVm[];
			}
			setHasItems(items.length > 0);
			tm.setRowData(items);
			tm.sort();
		});
	}, [tm, berichteUiiStore.selectedAgt, agenturListUiStore.filterHasChanged, agenturListUiStore, agtFitnessUiStore.items, agtFitnessUiStore]);

	return (
		<>
			{!hasItems ? (
				<div className="pad-1rem">Keine Daten gefunden</div>
			) : (
				<>
					<Table stickyHeader={true} stickyFooter={true} tm={tm}></Table>
				</>
			)}
		</>
	);
});

import { AgenturModel } from 'app/models/agentur.model';
import { AgenturStore } from '../agentur.store';
import { KvAgtLeadNowStore } from '../kv.agt.leadnow.store';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import { ISelectOpt } from 'app/components/common/Select';
import { formatKw } from 'app/utils';
import _ from 'lodash';
import { BaseUiStore } from './base.ui.store';
import { KvAgtMsLiteModel } from 'app/models/kv.agt.ms.lite.model';

interface ILeadNowAbos {
	path: string;
	label: string;
	val?: number;
}

export enum LeadNowType {
	brutto = 'brutto',
	netto = 'netto',
}

export const LeadNowAbos: ILeadNowAbos[] = [
	{ path: 'av', label: 'Altersvorsorge', val: 0 },
	{ path: 'baufi', label: 'Baufinanzierung', val: 0 },
	{ path: 'bausp', label: 'Bausparen', val: 0 },
	{ path: 'bu', label: 'Berufsunfähigkeit', val: 0 },
	{ path: 'fsach', label: 'Firmen Sach', val: 0 },
	{ path: 'kfz', label: 'Kfz und Motorrad', val: 0 },
	{ path: 'kv', label: 'Krankenvoll', val: 0 },
	{ path: 'kzus', label: 'Krankenzusatz und Pflege', val: 0 },
	{ path: 'psach', label: 'Privat Sach', val: 0 },
	{ path: 'reise', label: 'Reise', val: 0 },
	{ path: 'tier', label: 'Tier', val: 0 },
	{ path: 'berat', label: 'Übergreifende Beratung', val: 0 },
	{ path: 'verm', label: 'Vermögensaufbau', val: 0 },
];

export class LeadNowKw {
	kw: number;
	status: string;
	abo: string;
	leadCount: number;

	constructor(kw: number, abo: string, status: string, leadCount: number) {
		this.kw = kw;
		this.leadCount = leadCount;
		this.abo = abo;
		this.status = status;
	}
}

export class LeadNowVM {
	agt: AgenturModel;
	@observable
	items: LeadNowKw[] = [];

	// make it easier to get the computeds
	[key: string]: any;

	constructor(agt: AgenturModel, kvs: KvAgtMsLiteModel[]) {
		makeObservable(this);
		this.agt = agt;

		for (const kv of kvs) {
			// if (!kv.valueString) {
			// 	continue;
			// }
			const all = kv.j ? kv.j : [];
			all.forEach((j: any) => {
				const kw = new LeadNowKw(kv.bm, j.a, j.s, j.c);
				this.items.push(kw);
			});
		}
	}

	_sumByAbo(abo: string) {
		// Bei Datensätzen mit dem Lead Status „Gewährt“ handelt es sich um keinen Lead sondern eine Gutschrift. Diese dürfen also nicht gezählt werden.
		let f = this.items.filter((i) => i.abo === abo && i.status !== 'g'); // status g == gewährt
		if (f) {
			const brutto = _.sumBy(f, 'leadCount');
			f = f.filter((i) => i.status !== 's'); // ohne storno => netto
			// Wenn wir alle Leads unabhängig vom Status zählen, erhalten wir die Bruttoleads. Ziehen wir davon die Leads mit dem Status „storniert“ ab, erhalten wir die „Nettoleads“.
			const netto = _.sumBy(f, 'leadCount');
			return {
				brutto,
				netto,
			};
		}
		return;
	}

	@computed
	get maxKw() {
		return _.maxBy(this.items, 'kw')?.kw;
	}

	@computed
	get brutto() {
		const f = this.items.filter((i) => i.status !== 'g');
		const brutto = _.sumBy(f, 'leadCount');
		return brutto;
	}

	@computed
	get netto() {
		const f = this.items.filter((i) => i.status !== 'g' && i.status !== 's');
		const netto = _.sumBy(f, 'leadCount');
		return netto;
	}

	@computed
	get storno() {
		const f = this.items.filter((i) => i.status !== 'g' && i.status === 's');
		const storno = _.sumBy(f, 'leadCount');
		return storno;
	}

	@computed
	get av() {
		return this._sumByAbo('av');
	}
	@computed
	get baufi() {
		return this._sumByAbo('baufi');
	}
	@computed
	get bausp() {
		return this._sumByAbo('bausp');
	}
	@computed
	get bu() {
		return this._sumByAbo('bu');
	}
	@computed
	get fsach() {
		return this._sumByAbo('fsach');
	}
	@computed
	get kfz() {
		return this._sumByAbo('kfz');
	}
	@computed
	get kv() {
		return this._sumByAbo('kv');
	}
	@computed
	get kzus() {
		return this._sumByAbo('kzus');
	}
	@computed
	get psach() {
		return this._sumByAbo('psach');
	}
	@computed
	get reise() {
		return this._sumByAbo('reise');
	}
	@computed
	get tier() {
		return this._sumByAbo('tier');
	}
	@computed
	get berat() {
		return this._sumByAbo('berat');
	}
	@computed
	get verm() {
		return this._sumByAbo('verm');
	}
}

export class AgtLeadNowUiStore extends BaseUiStore<LeadNowVM> {
	name = 'AgtLeadNowUiStore';
	kvAgtLeadNowStore: KvAgtLeadNowStore;
	agenturStore: AgenturStore;

	constructor(agenturStore: AgenturStore, kvAgtLeadNowStore: KvAgtLeadNowStore) {
		super();
		makeObservable(this);
		this.kvAgtLeadNowStore = kvAgtLeadNowStore;
		this.agenturStore = agenturStore;
	}
	@observable
	kws: number[] = [];

	@computed
	get kwsOpts(): ISelectOpt[] {
		return this.kws.map((kw) => {
			return {
				value: kw,
				label: formatKw(kw),
			};
		});
	}

	@computed
	get minKw(): number {
		if (this.minKwCurrent) {
			return this.minKwCurrent;
		}

		return 0;
	}

	@computed
	get maxKw(): number {
		if (this.maxKwCurrent) {
			return this.maxKwCurrent;
		}

		return 0;
	}

	@observable
	minKwCurrent?: number;

	@observable
	maxKwCurrent?: number;

	@computed
	get currentKWItems() {
		const kvVmItems: LeadNowVM[] = [];
		this.items.forEach((vm) => {
			const f = vm.items.filter((i) => {
				if (this.minKw && i.kw >= this.minKw) {
					if (this.maxKw && i.kw <= this.maxKw) {
						return true;
					}
				}
				return false;
			});
			const r = new LeadNowVM(vm.agt, []);
			runInAction(() => {
				r.items = f;
			});
			kvVmItems.push(r);
		});

		return kvVmItems;
	}

	async _baseLoad() {
		const data = await this.kvAgtLeadNowStore.findAll();
		const agts = await this.agenturStore.findAll();
		const res: LeadNowVM[] = [];
		for (const agt of agts) {
			const kvs = data.filter((d) => d.agtId === agt.agtId);
			if (kvs.length > 0) {
				const vm = new LeadNowVM(agt, kvs);
				res.push(vm);
			}
		}
		runInAction(() => {
			this.kws = _.uniq(_.map(data, 'bm')).sort();
			this.minKwCurrent = this.kws[0];
			this.maxKwCurrent = this.kws[this.kws.length - 1];
			this.items = res;
		});
	}
}

import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'app/context';
import { AgtFitnessVm, FitModel } from 'app/stores/ui/agt.fitness.ui.store';
import ProgressRing from 'app/components/common/ProgressRing';
import colors from 'assets/scss/colors.module.scss';
import _ from 'lodash';
import Widget from 'app/components/common/Widget';
import Panel from 'app/components/Panel';
import { FitnessTrackerAmisChart } from './FitnessTrackerAmisNow';
import { FitnessTrackerLeitfadenButton, FitnessTrackerPdfDownloadButton } from './FitnessTrackerPdfDownloadButton';
import { useHistory } from 'react-router';
import routes from 'routes';
import { formatNumTwoDig } from 'app/utils';
import { FitnessPlanPdfDownloadButton } from '../fitnessplan/FitnessPlanPdfDownloadButton';

interface IFitnessTrackerGraphics {
	view: string;
	agtId: number;
	onClose: () => void;
	homeAlone?: boolean;
}
export const FitnessTrackerGraphics = observer((props: IFitnessTrackerGraphics) => {
	const { agtFitnessUiStore } = useStore();
	const { view, agtId, onClose } = props;

	const [fitVm, setFitVm] = React.useState<AgtFitnessVm | null>(null);
	useEffect(() => {
		agtFitnessUiStore.load().then(() => {
			let items = agtFitnessUiStore.items;
			if (items.length > 0) {
				items = items.filter((i) => i.agt.agtId === agtId);
			}
			setFitVm(items[0]);
		});
	}, [agtFitnessUiStore, agtId, fitVm]);

	if (!fitVm) return null;

	return (
		<>
			{props.homeAlone ? (
				<FitnessTrackerPanelContent view={view} fitVm={fitVm} />
			) : (
				<Panel classModifier="berichte-panel" title={fitVm.agt.name} header={<></>} onClose={onClose}>
					<FitnessDetailNav view={view} agtId={fitVm.agt.agtId} />
					<FitnessTrackerPanelContent view={view} fitVm={fitVm} />
				</Panel>
			)}
		</>
	);
});

const FitnessTrackerPanelContent = observer((props: { view: string; fitVm: AgtFitnessVm }) => {
	const { view, fitVm } = props;
	return (
		<>
			{view === 'overview' && (
				<div className="grid is-col-half align-stretch pad-1rem">
					<FitnessGraphicsOverview fitVm={fitVm} />
				</div>
			)}
			{view === 'gup' && (
				<div className="grid align-stretch pad-1rem">
					<FitnessTrackerProgressRing path="sichtbarSein" fitVm={fitVm} />
				</div>
			)}
			{view === 'akuma' && (
				<div className="grid align-stretch pad-1rem">
					<FitnessTrackerProgressRing path="akumaPrime" fitVm={fitVm} />
					<FitnessTrackerProgressRing path="akumaPlaner" fitVm={fitVm} />
				</div>
			)}
			{view === 'akumaPrime' && (
				<div className="grid align-stretch pad-1rem">
					<FitnessTrackerProgressRing path="akumaPrime" fitVm={fitVm} />
				</div>
			)}
			{view === 'akumaPlaner' && (
				<div className="grid align-stretch pad-1rem">
					<FitnessTrackerProgressRing path="akumaPlaner" fitVm={fitVm} />
				</div>
			)}
			{view === 'maz' && (
				<div className="grid align-stretch pad-1rem">
					<FitnessTrackerProgressRing path="immerDaSein" fitVm={fitVm} />
				</div>
			)}
			{view === 'amis' && (
				<div className="grid align-stretch pad-1rem">
					<FitnessTrackerProgressRing path="jedenKontaktNutzen" fitVm={fitVm} />
					<FitnessTrackerAmisChart fitVm={fitVm} />
				</div>
			)}
		</>
	);
});

interface IAgtFitnessDetail {
	view: string;
}
export const AgtFitnessDetail = observer((props: IAgtFitnessDetail) => {
	const { agenturUiStore, agtFitnessUiStore } = useStore();
	const { view } = props;

	const agtId = agenturUiStore.currentAgtId;
	if (!agtId) return null;

	const [fitVm, setFitVm] = React.useState<AgtFitnessVm | null>(null);
	useEffect(() => {
		agtFitnessUiStore.load().then(() => {
			let items = agtFitnessUiStore.items;
			if (items.length > 0) {
				items = items.filter((i) => i.agt.agtId === agtId);
			}
			setFitVm(items[0]);
		});
	}, [agtFitnessUiStore, agtId, fitVm]);

	if (!fitVm) return null;
	return (
		<>
			<FitnessDetailNav view={view} agtId={fitVm.agt.agtId} />
			<div className="table-container pad-bottom-3rem">
				<FitnessTrackerPanelContent view={view} fitVm={fitVm} />
			</div>
		</>
	);
});

interface IFitnessDetailNav {
	view: string;
	agtId: number;
}
export const FitnessDetailNav = (props: IFitnessDetailNav) => {
	const { agenturUiStore } = useStore();
	const { view, agtId } = props;
	const history = useHistory();

	const navToFitness = (tgt: string) => {
		const currAgt = agenturUiStore.currentAgtId;
		let p = routes.BERICHTE.getPath('bestand', 'fit', tgt, agtId.toString());
		if (currAgt) {
			p = routes.AGENTUR.getPath(currAgt.toString(), 'berichte', 'bestand', 'fit', tgt, currAgt.toString());
		}
		history.push(p);
	};

	return (
		<div className="fitness-detail-nav">
			<div className="buttons">
				<button className={`button is-small is-primary ${view === 'overview' ? '' : 'is-inverted'}`} onClick={() => navToFitness('overview')}>
					Übersicht
				</button>
				<button className={`button is-small is-primary ${view === 'gup' ? '' : 'is-inverted'}`} onClick={() => navToFitness('gup')}>
					GUP
				</button>
				<button className={`button is-small is-primary ${view === 'amis' ? '' : 'is-inverted'}`} onClick={() => navToFitness('amisnowaufgaben')}>
					AMIS.NOW
				</button>
				<button className={`button is-small is-primary ${view === 'akuma' ? '' : 'is-inverted'}`} onClick={() => navToFitness('akuma')}>
					AKUMA
				</button>
				<button className={`button is-small is-primary ${view === 'maz' ? '' : 'is-inverted'}`} onClick={() => navToFitness('maz')}>
					mAZ
				</button>
			</div>
			<div className="buttons">
				<FitnessTrackerLeitfadenButton />
				<FitnessTrackerPdfDownloadButton agtId={agtId} />
				<FitnessPlanPdfDownloadButton agtId={agtId} />
			</div>
		</div>
	);
};
interface IFitnessGraphicsOverview {
	fitVm: AgtFitnessVm;
}
export const FitnessGraphicsOverview = observer((props: IFitnessGraphicsOverview) => {
	const { fitVm } = props;
	return (
		<>
			<FitnessTrackerProgressRing path="sichtbarSein" isOverview={true} fitVm={fitVm} />
			<FitnessTrackerProgressRing path="jedenKontaktNutzen" isOverview={true} fitVm={fitVm} />
			{fitVm.isAkumaPrimeUser === 0 ? (
				<FitnessTrackerProgressRing path="akumaPlaner" isOverview={true} fitVm={fitVm} />
			) : (
				<FitnessTrackerProgressRing path="akumaPrime" isOverview={true} fitVm={fitVm} />
			)}
			<FitnessTrackerProgressRing path="immerDaSein" isOverview={true} fitVm={fitVm} />
		</>
	);
});

interface IFitnessTrackerProgressRing {
	path: string;
	fitVm: AgtFitnessVm;
	isOverview?: boolean;
}
const FitnessTrackerProgressRing = observer((props: IFitnessTrackerProgressRing) => {
	const { path, fitVm, isOverview } = props;
	const schema = FitnessSchema.find((s) => s.path === path);
	if (!schema) return null;

	const nodes = isOverview ? schema.nodes.slice(0, 1) : schema.nodes.slice(1, schema.nodes.length);
	const action = <span className={`tag is-${schema.color}`}>{schema.tag}</span>;

	return (
		<Widget title={schema.section} disableCollapse={true} action={action} modifier="is-paddingless">
			{path === 'sichtbarSein' && !isOverview && (
				<div className="fitness-google-rank">
					<div className="label">Aktuelle Sichtbarkeit bei Google</div>
					<div className="rank">{formatNumTwoDig(fitVm.gup?.rank || 0)}</div>
				</div>
			)}
			<div className={`fitness-graphics-grid ${path}`}>
				{nodes.map((node) => {
					const fm: FitModel = _.get(fitVm, node.path);
					if (!fm) return null;
					return (
						<div className="fitness-progress-ring" key={node.path}>
							<div className="title">{node.title}</div>
							{['planerAnzahlWochenNutzung', 'primeEinordnungsquote'].includes(path) && (
								<div className="akuma-tag">
									AKUMA Prime Nutzer <span className="tag is-yellow">{fitVm.isPrime ? 'JA' : 'NEIN'}</span>
								</div>
							)}
							<ProgressRing value={fm.ergebnis || 0} total={fm.ziel || 100} text={`${Math.floor(fm.ze)}%`} color={colors[schema.color]} />
							<span className="subtitle">{node.subtitle(fm.ziel, fm.ergebnis)}</span>
						</div>
					);
				})}
			</div>
		</Widget>
	);
});

interface IFitnessSchema {
	section: string;
	sectionTitle: string;
	path: string;
	color: string;
	tag: string;
	nodes: IFitnessSchemaNode[];
}
interface IFitnessSchemaNode {
	path: string;
	title: string;
	subtitle: (soll?: number, ist?: number) => React.ReactNode;
}
export const FitnessSchema: IFitnessSchema[] = [
	{
		section: 'Sichtbar sein DigitalPersönlich',
		sectionTitle: 'Google Unternehmensprofil',
		path: 'sichtbarSein',
		color: 'green',
		tag: 'GUP',
		nodes: [
			{
				path: 'gupAverage',
				title: 'Google Unternehmensprofil',
				subtitle: (soll, ist) => (
					<>
						<strong>Ø-Wert der einzelnen Kennzahlen</strong> Ihres Google Unternehmensprofils
					</>
				),
			},
			{
				path: 'anzBew',
				title: 'Bewertungen / Rezensionen',
				subtitle: (soll, ist) => (
					<>
						<strong>{ist}</strong> von {soll} <strong>erhaltenen Rezensionen/Bewertungen</strong>
					</>
				),
			},
			{
				path: 'bewAvg',
				title: 'Durchschnittliche Sternebewertung',
				subtitle: (soll, ist) => (
					<>
						<strong>{ist}</strong> von {soll} <strong>Ø-Sternebewertung</strong>
					</>
				),
			},
			{
				path: 'imgCount',
				title: 'Bilder / Videos',
				subtitle: (soll, ist) => (
					<>
						<strong>{ist}</strong> von {soll} <strong>hochgeladenen Bildern/Videos</strong>
					</>
				),
			},
			{
				path: 'qCount',
				title: 'Fragen & Antworten',
				subtitle: (soll, ist) => (
					<>
						<strong>{ist}</strong> von {soll} <strong>erhaltenen Fragen und Antworten</strong>
					</>
				),
			},
		],
	},
	{
		section: 'Jeden Kontakt nutzen',
		sectionTitle: 'AMIS.NOW Aufgaben',
		path: 'jedenKontaktNutzen',
		color: 'turquoise',
		tag: 'AMIS.NOW',
		nodes: [
			{
				path: 'amisNowQuoteRead',
				title: 'AMIS.NOW Aufgaben',
				subtitle: (soll, ist) => (
					<>
						<strong>{ist}</strong> von {soll} <strong>gesichtete Aufgaben</strong>
					</>
				),
			},
			{
				path: 'amisNowQuoteRead',
				title: 'Gesichtete Aufgaben',
				subtitle: (soll, ist) => (
					<>
						<strong>{ist}</strong> von {soll} <strong>gesichtete Aufgaben</strong>
					</>
				),
			},
		],
	},
	{
		section: 'Jeden Kunden kontaktieren',
		sectionTitle: 'AKUMA Planer',
		path: 'akumaPlaner',
		color: 'yellow',
		tag: 'AKUMA Planer',
		nodes: [
			{
				path: 'planerAnzahlWochenNutzung',
				title: 'AKUMA Planer',
				subtitle: (soll, ist) => (
					<>
						<strong>{ist}</strong> von {soll} <strong>Wochen,</strong> in denen die <strong>Übertragungsquote</strong> von bearbeiteten Kunden im AKUMA Planer zu AMIS.NOW Aufgaben{' '}
						<strong>größer 50% beträgt</strong>
					</>
				),
			},
			{
				path: 'planerAnzahlWochenNutzung',
				title: 'Wochen der Nutzung',
				subtitle: (soll, ist) => (
					<>
						<strong>{ist}</strong> von {soll} <strong>Wochen,</strong> in denen die <strong>Übertragungsquote</strong> von bearbeiteten Kunden im AKUMA Planer zu AMIS.NOW Aufgaben{' '}
						<strong>größer 50% beträgt</strong>
					</>
				),
			},
			{
				path: 'planerUebertragungquoteAmisNow',
				title: 'Übertragungsquote',
				subtitle: (soll, ist) => (
					<>
						<strong>{ist}</strong> von {soll} <strong>Kunden,</strong> die im AKUMA Planer bearbeitet und an AMIS.NOW Aufgaben übertragen wurden.
					</>
				),
			},
		],
	},
	{
		section: 'Jeden Kunden kontaktieren',
		sectionTitle: 'AKUMA Prime',
		path: 'akumaPrime',
		color: 'yellow',
		tag: 'AKUMA Prime',
		nodes: [
			{
				path: 'primeEinordnungsquote',
				title: 'AKUMA Prime',
				subtitle: (soll, ist) => (
					<>
						<strong>{ist}</strong> von {soll} <strong>Kunden,</strong> die in <strong>AKUMA Prime</strong> eingeordnet und damit bearbeitet wurden
					</>
				),
			},
			{
				path: 'primeEinordnungsquote',
				title: 'Einordnungsquote',
				subtitle: (soll, ist) => (
					<>
						<strong>{ist}</strong> von {soll} <strong>Kunden,</strong> die in <strong>AKUMA Prime</strong> eingeordnet und damit bearbeitet wurden
					</>
				),
			},
			{
				path: 'primeAbwahlquote',
				title: 'Abwahlquote',
				subtitle: (soll, ist) => (
					<>
						<strong>{ist}</strong> von {soll} <strong>Kunden, die abgewählt wurden</strong>
					</>
				),
			},
			{
				path: 'primeTerminierungExtern',
				title: 'Externe Terminierungsquote',
				subtitle: (soll, ist) => (
					<>
						<strong>{ist}</strong> von {soll} <strong>Kunden,</strong> die zur externen Bearbeitung bzw. Terminierung eingeordnet wurden
					</>
				),
			},
		],
	},
	{
		section: 'Immer da sein',
		sectionTitle: 'Meine Allianz',
		path: 'immerDaSein',
		color: 'primary',
		tag: 'mAZ',
		nodes: [
			{
				path: 'mazBestandDurchdringungsquote',
				title: 'Meine Allianz',
				subtitle: (soll, ist) => (
					<>
						<strong>{ist}</strong> von {soll} <strong>Bestandskunden</strong> mit MeineAllianz
					</>
				),
			},
			{
				path: 'mazBestandDurchdringungsquote',
				title: 'Durchdringung Bestandskunden',
				subtitle: (soll, ist) => (
					<>
						<strong>{ist}</strong> von {soll} <strong>Bestandskunden</strong> mit MeineAllianz
					</>
				),
			},
			{
				path: 'mazNeuDurchdringungsquote',
				title: 'Durchdringung Neukunden',
				subtitle: (soll, ist) => (
					<>
						<strong>{ist}</strong> von {soll} <strong>Neukunden</strong> mit MeineAllianz
					</>
				),
			},
		],
	},
];

import { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import _, { debounce } from 'lodash';
import { AgtAkumaPrimeKwVM, IAkumaPrimeDef, AkumaPrimeType } from 'app/stores/ui/agt.akumaprime.ui.store';
import { init, EChartsOption, ECharts } from 'echarts';
import { calculatePercentages, countArray, formatPercentTwoDig, roundPercentages } from 'app/utils';
import { useStore } from 'app/context';

interface IAkumaPrimeHorizontalBarChart {
	schema: IAkumaPrimeDef[];
	primeType: AkumaPrimeType;
	data: AgtAkumaPrimeKwVM[];
}

export const AkumaPrimeHorizontalBarChart = observer((props: IAkumaPrimeHorizontalBarChart) => {
	const { primeType } = props;
	const { uiStore } = useStore();

	// excludes zuKontaktierendeKunden
	const cleanedSchema = props.schema.filter((i) => !['ZK'].includes(i.key) && i.type === primeType);
	const schema = _.sortBy(cleanedSchema, 'sort');

	const data = _.sortBy(props.data, (d) => d.agt.name).reverse();
	const chartHeight = 75 + (data.length + 2) * 30;

	// echarts stuff
	const chartRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const dim = ['name', ...schema.map((s) => s.path)];
		const legendData = schema.map((s) => ({ name: s.label }));

		const seriesOptions: echarts.SeriesOption = {
			type: 'bar',
			stack: 'a',
			label: {
				show: true,
				formatter: (params) => {
					// @ts-ignore
					const val = params.value[params.encode.x[0]];
					return val !== 0 ? `${val}%` : '';
				},
			},
			emphasis: {
				focus: 'series',
			},
			animation: !uiStore.printMode,
		};

		const seriesData = schema.map((s) => {
			return {
				...seriesOptions,
				name: s.label,
				color: s.color,
			};
		});

		const dataSetSource = data.map((agtData: AgtAkumaPrimeKwVM) => {
			const name = agtData.agt.name;
			const vals = schema.map((s) => {
				const val = _.get(agtData, s.path);
				return val || 0;
			});

			const valsArr = calculatePercentages(vals.map((v) => v));
			return [name, ...valsArr];
		});

		let option: EChartsOption = {
			tooltip: {
				trigger: 'axis',
				valueFormatter: (value) => `${value}%`,
			},
			xAxis: {
				axisLabel: {
					fontSize: 14,
					formatter: '{value}%',
				},
			},
			yAxis: {
				type: 'category',
				axisLabel: {
					fontSize: 14,
					padding: [0, 5, 0, 0],
				},
			},
			dataset: {
				dimensions: dim,
				source: dataSetSource,
			},
			legend: {
				bottom: 10,
				data: legendData,
			},
			grid: {
				containLabel: true,
			},
			series: seriesData,
		};
		let chart: ECharts | null = null;
		if (chartRef.current !== null) {
			chart = init(chartRef.current, null, { renderer: 'svg' });
			chart.setOption(option);
		}
		const handleResize = debounce(() => {
			chart?.resize();
		}, 300);
		window.addEventListener('resize', handleResize);

		return () => {
			chart?.dispose();
			window.removeEventListener('resize', handleResize.cancel);
		};
	}, [chartRef, data, primeType, schema]);

	return (
		<>
			<div className="page-break-avoid" style={{ width: '100%', height: chartHeight, background: '#ffffff' }} ref={chartRef}></div>
		</>
	);
});
